// Colors
export const orange = "#00b7ff";
export const white = "#FFFFFF";
export const lightorange = "#FFEFE9";
export const green = "#10CF5C";
export const brightgreen = "#24EE75";
export const lightgreen = "#61D6C1";
export const blue = "#2F6BB2";
export const red = "#E81414";
export const grey = "#5B5B5B";
export const darkgrey = "#191919";
export const brightgrey = "#E9E9E9";
export const lightgrey = "#999999";
export const black = "#000000";
export const lighgreyblue = "#EBEBEB";
