import React from 'react';
import { OptionTypeBase } from 'react-select';
import { Container, BoldText, ValidationStatus, CircleStatus, ValidationText } from './styles';
import ErrorMessage from '../ErrorMessage';

interface SelectProps extends OptionTypeBase {
  hasEightCharacters: boolean,
  hasCapitalLowerLetters: boolean,
  hasSpecialCharacters: boolean,
}

const PasswordRequirements: React.FC<SelectProps> = ({
  hasEightCharacters,
  hasCapitalLowerLetters,
  hasSpecialCharacters
}) => {
  return (
    <Container>
      <BoldText>Sua senha deve conter:</BoldText>

      <ValidationStatus>
        <CircleStatus goodPassword={hasEightCharacters} />
        <ValidationText goodPassword={hasEightCharacters}>
          No mínimo 8 caracteres
        </ValidationText>
      </ValidationStatus>

      <ValidationStatus>
        <CircleStatus goodPassword={hasCapitalLowerLetters} />
        <ValidationText goodPassword={hasCapitalLowerLetters}>
          Letras maiúsculas e minúsculas
        </ValidationText>
      </ValidationStatus>
      
      <ValidationStatus>
        <CircleStatus goodPassword={hasSpecialCharacters} />
        <ValidationText goodPassword={hasSpecialCharacters}>
          Números e caracteres especiais
        </ValidationText>
      </ValidationStatus>
            
  </Container>
  );
};

export default PasswordRequirements;
