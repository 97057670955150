import styled from "styled-components";
import * as color from "../../styles/colors";

export const Container = styled.form`
    font-family: "Inter", sans-serif;
`;

export const Row = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
`;

export const Button = styled.button`
    background-color: ${color.orange};
    border-radius: 4px;
    border: ${color.orange};
    padding: 12px 30px;
    height: 45px;
    line-height: 1;
    color: #fff;
    font-weight: bold;

    :hover {
        background-color: #060607;
        border-color: #060607;
        transition: all ease 0.3s;
    }

    @media (max-width: 546px) {
        width: 100%;
    }
`;

export const RemoveButton = styled.button`
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid ${color.orange};
    padding: 12px 30px;
    height: 45px;
    line-height: 1;
    color: ${color.orange};
    font-weight: bold;
    margin-right: 20px;

    :hover {
        color: #060607;
        border-color: #060607;
        transition: all ease 0.3s;
    }

    @media (max-width: 546px) {
        width: 100%;
    }
`;

export const CancelButton = styled.button`
    background-color: transparent;
    border-radius: 50px;
    border: none;
    color: ${color.darkgrey};
    padding: 10px;
    margin-right: 20px;
    font-weight: bold;

    :hover {
        color: #757e98;
        transition: all ease 0.3s;
    }

    @media (max-width: 546px) {
        display: none;
    }
`;
