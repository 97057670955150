import styled, { keyframes } from 'styled-components';
import * as color from '../../styles/colors';

interface FinifhProps {
  status?: string,
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const Container = styled.div<FinifhProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  opacity: 0;

  min-width: 250px;
  min-height: 420px;
  max-width: 360px;
  
  svg {
    margin-bottom: 24px;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #1B1B1B;
    text-align: center;
    margin-bottom: 24px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 115%;
    color: #464646;
    text-align: center;
    margin-bottom: 10px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;

    background: ${ ({status}) => ( status === 'success' ? '#59BF75' : color.orange) };
    border-radius: 4px;

    font-weight: 500;
    font-size: 14px;
    line-height: 115%;

    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
  }

  animation: 1s ${fadeIn} ease-out forwards;
`;
