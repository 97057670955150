import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Container, ContainerFluid } from './styles';
import InTopbar from '../InTopbar';
import WarningBar from '../WarningBar';
import api from '../../services/api';
import { AccountsContext as Context } from '../../context/AccountsContext';

const InPage: React.FC = ({ children }) => {
  const { user } = useContext(Context);
  const [showWarningBar, setShowWarningBar] = useState<string>('none');
  const [companyId, setCompanyId] = useState<string>('');
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isCompanyDocumentRefused, setIsCompanyDocumentRefused] =
    useState<boolean>(false);
  const [isUserDocumentRefused, setIsUserDocumentRefused] =
    useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const firstPath = location.pathname.split('/')[1];

  useEffect(() => {
    if (user?.is_account_owner) {
      (async () => {
        try {
          await api.get('/profile/verify-documents');
        } catch (error) {
          //@ts-ignore
          if (error.response.data.errors?.company_is_refused) {
            const { is_pending = false, companies = [] } =
              //@ts-ignore
              error.response.data.errors?.company_is_refused;
            //@ts-ignore
            const userStatus = error.response.data.errors?.user_is_refused;

            var refusedCompany;

            if (companies && companies.length > 0) {
              const companiesArray = Object.entries(companies);
              refusedCompany = companiesArray.find((c: any) => {
                return c[1].is_refused === true;
              });
            }

            is_pending ? setIsPending(true) : setIsPending(false);

            userStatus
              ? setIsUserDocumentRefused(true)
              : setIsUserDocumentRefused(false);

            if (refusedCompany) {
              setCompanyId(refusedCompany[0]);
              setIsCompanyDocumentRefused(true);
            } else {
              setIsCompanyDocumentRefused(false);
            }

            if (isCompanyDocumentRefused || userStatus || is_pending) {
              firstPath === 'personal-info' || firstPath === 'companies'
                ? setShowWarningBar('none')
                : setShowWarningBar('block');
            }
          }
        }
      })();
    }
  }, [firstPath, user, isCompanyDocumentRefused]);

  const handleRedirect = useCallback(() => {
    if (isUserDocumentRefused) {
      history.push('/personal-info#documents');
    } else if (isCompanyDocumentRefused) {
      history.push(`/companies/company-detail/${companyId}`);
    }
  }, [companyId, history, isCompanyDocumentRefused, isUserDocumentRefused]);

  return (
    <ContainerFluid className="internal-pages">
      <Container>
        {children}
        <InTopbar />

        {(isCompanyDocumentRefused || isUserDocumentRefused) && (
          <WarningBar
            type="danger"
            message="Um de seus documentos foi recusado"
            buttonName="Corrigir documento"
            style={{ display: showWarningBar }}
            closeBarOnClick={() => setShowWarningBar('none')}
            buttonClick={handleRedirect}
          />
        )}
        {/* {!isCompanyDocumentRefused && !isUserDocumentRefused && isPending && (
          <WarningBar
            type="warning"
            message="Seu acesso ainda é restrito pois seu cadastro está em análise"
            style={{ display: showWarningBar }}
            closeBarOnClick={() => setShowWarningBar("none")}
          />
        )} */}
      </Container>
    </ContainerFluid>
  );
};

export default InPage;
