import styled from 'styled-components';
import * as color from '../../styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  min-width: 250px;
  max-width: 360px;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #1B1B1B;
    margin-bottom: 16px;
  }


  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #464646;
    margin-bottom: 24px;
  }

  .btn-signin {
    margin: 39px  0 32px 0;
  }

  .btn-forgot-password {
    background: transparent;
    border: none;
    color: #bcbcbc;
    width: auto;
    height: auto;
    padding: 5px 10px;
  }

  .signup-link {
    text-decoration: none;
    color: ${color.orange};
    margin-top: 18px;
  }

  .has-account {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30px;
    text-align: center;

    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #1B1B1B;

    .link {
      font-weight: 600;
      color: ${color.orange};
      margin-left: 3px;
      cursor: pointer;
      text-decoration: none;
    }
  }


  @media (min-width: 576px) {
    width: 60%;
    padding: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;
