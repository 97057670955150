import styled from 'styled-components';
import Modal from 'react-modal';
import * as color from '../../styles/colors';

interface ModalAdminProps {
  smallWidth?: boolean;
}

export const Container = styled(Modal)<ModalAdminProps>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  min-width: 300px;
  min-height: 300px;
  
  max-width: ${({ smallWidth }) => (smallWidth ? '500px' : '842px')};
  background-color: white;
  border-radius: 10px;
  
  font-family: 'Inter', sans-serif;

  .icon {
    width: 64px;
    height: 64px;
    margin: 24px;
  }
  
  @media (max-width: 576px) {
    min-width:300px;
  }
`;

export const ModalContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  outline: 0;
  padding: 24px;
  color: ${color.darkgrey};

  .title {
    color: ${color.darkgrey};
    font-size: 14px;
    font-weight: 700;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 20px;
  height: 24px;
  background: transparent;
  border: none;
  img {
    height: 13px;
  }
`;
