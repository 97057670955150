import styled from "styled-components";
import * as color from "../../styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .verification-code-input {
    width: 100% !important;
    margin: 20px 0;

    > div {
      display: flex;
      justify-content: center;

      input {
        border: none;
        border-bottom: 3px solid #9ea8c5;
        border-bottom-right-radius: none;
        font-size: 40px;
        font-family: 'Inter', sans-serif;
        margin: 0 5px;
        color: ${color.darkgrey};

        &:first-child {
          border-bottom-left-radius: 0;
        }

        &:last-child {
          border-bottom-right-radius: 0;
        }
      }
    }
  }
`;

export const Text = styled.p`
  text-align: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  color: #999999;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #3d4456;
`;
