import React, { useCallback, useState } from 'react';
import { FiXCircle, FiCheckCircle, FiInfo } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Container } from './styles';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';

interface BankAccountDeleteProps {
    closeModal?: any;
    bankAccount?: any;    
}

const ModalBankAccountStatusChange: React.FC<BankAccountDeleteProps> = ({
    closeModal,
    bankAccount
}) => {
    const [loading, setLoading] = useState(0);

    const handleChangeStatusFlag = useCallback(async () => {
        try {
            setLoading(1);

            await api.put(`/bank-account/${bankAccount.id}/change-status`)
            .then((response)=>{
                setLoading(0);
                toast.success('Atualizado com sucesso!');
            })
            .catch((error) => {    
                setLoading(0);                
                toast.error('Ops! Para continuar você precisa ter outra conta aprovada.');
            });

            closeModal('flag');            
        } catch (error) {
            setLoading(0);
        }
    }, [closeModal, bankAccount.id]);

    return (
        <Container>
            <Loader loading={loading} />
            {bankAccount.is_default ? <FiXCircle size={50} color="#ff4c52" /> : <FiInfo size={50} color="#56607e" ></FiInfo>}
                
            <p className="question">Você tem certeza?</p>
            <p className="phrase">
                {bankAccount.transfer_type=='PIX' ? `Deseja ${bankAccount.is_default?'desativar':'ativar'} sua chave pix?` : `Deseja ${bankAccount.is_default?'desativar':'ativar'} sua conta de recebimento?`}
            </p>
            <div className="buttons-container">
                <Button className="cancel-button" onClick={() => closeModal('flag')}>
                    Cancelar
                </Button>
                <Button className="delete-button" onClick={handleChangeStatusFlag}>
                    Confirmar
                </Button>
            </div>
        </Container>
    );
};

export default ModalBankAccountStatusChange;
