import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import PageTopbar from '../../../../components/PageTopbar';
import GobackButton from '../../../../components/GoBackButton';
import Card from '../../../../components/Card';
import Form from '../../../../components/Form';
import Input from '../../../../components/Input';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

const CompanyCreateCNPJ: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<number>(0);
  const [companyName, setCompanyName] = useState<string>('');
  const [companyData, setCompanyData] = useState<any>();
  const [errorMessageCnpj, setErrorMessageCnpj] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { register, setValue, handleSubmit, errors, getValues } = useForm();
  const [isDisabled, setIsDisabled] = useState(true);

  const createPessoaJuridica = useCallback(
    async data => {
      setLoading(1);
      try {
        api
          .post('/companies', {
            ...data,
            ...companyData,
            fantasy_name: companyData.nomeEmpresarial,
            company_type: '2',
            country: 'brasil',
          })
          .then(response => {
            const company = response.data.data;
            setLoading(0);
            toast.success('Empresa cadastrada com sucesso');
            history.push(`/companies/company-detail/${company.id_code}`);
          })
          .catch(err => {
            toast.error(err.response.data.message);
            setLoading(0);
          });
      } catch (error) {
        //
        console.log(error);
      }
    },
    [companyData, history]
  );

  const formatCNPJ = useCallback(() => {
    const currentCNPJ = getValues('document');
    const formatedCNPJ = currentCNPJ
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

    setValue('document', formatedCNPJ);
  }, [getValues, setValue]);

  const handleChange = useCallback(
    async e => {
      const cnpj = e.target.value;
      const cnpjFormat = cnpj.replace(/\D/g, '');
      setIsDisabled(true);

      if (cnpjFormat.length === 14) {
        setErrorMessageCnpj('');
        setLoading(1);
        const formData = new FormData();
        formData.append('cnpj', cnpjFormat);
        try {
          const response = await api.post('/companies/consult-cnpj', formData);
          
          if (response.data.message) {
            setErrorMessageCnpj(response.data.message);
            setLoading(0);
            return;
          }

          setValue('fantasy_name', response.data.nomeEmpresarial);
          setValue('company_data', response.data);
          setCompanyName(response.data.nomeEmpresarial);
          setCompanyData(response.data);

          setLoading(0);
        } catch (error) {
          setIsDisabled(false);

          setLoading(0);
          if (error.response.data.errors) {
            setErrorMessageCnpj(error.response.data.errors.cnpj);
          } else {
            setErrorMessageCnpj(error.response.data.message);
          }
        }
      }
    },
    [setValue]
  );

  return (
    <Container>
      <PageTopbar
        title="Criar empresa pessoa jurídica"
        subTitle="Gerencie as empresas relacionadas com seus produtos Azcend"
      />
      <GobackButton
        onClick={() => history.goBack()}
        className="goback-button"
      />
      <Card cardTitle="Criar empresa" loading={loading}>
        <Form submitButton onSubmit={handleSubmit(createPessoaJuridica)}>
          {/* <Row>
            <Select label="País da empresa" className="col-md-5">
              <option>Brasil</option>
            </Select>
          </Row> */}
          <div className="row">
            <Input
              label="CNPJ"
              id="cnpj"
              name="document"
              mask="99.999.999/9999-99"
              type="cnpj"
              placeholder="CNPJ"
              className="col-md-4 col-xs-12"
              autoComplete="off"
              onKeyPress={formatCNPJ}
              // onBlur={formatCNPJ}
              register={register({
                required: { value: true, message: 'Campo obrigatório' },
                minLength: { value: 14, message: 'Cnpj da empresa inválido' },
                maxLength: { value: 18, message: 'Cnpj da empresa inválido' },
              })}
              maxLength={18}
              errorMessage={errors.document?.message ?? errorMessageCnpj}
              onChange={handleChange}
            />
            <Input
              label="Nome da empresa"
              id="company-name"
              name="fantasy_name"
              mask=""
              placeholder="Nome da empresa"
              className="col-md-8 col-xs-12"
              setValue={setValue}
              onChange={e => setCompanyName(e.target.value)}
              register={register({
                required: {
                  value: true,
                  message: 'Nome da empresa é obrigatório',
                },
              })}
              maxLength={100}
              errorMessage={errors.fantasy_name?.message ?? errorMessage}
              disabled={isDisabled}
            />
          </div>
        </Form>
      </Card>
    </Container>
  );
};

export default CompanyCreateCNPJ;
