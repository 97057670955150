import React from 'react';
import { Container } from './styles';

const UsePrivacyTermsText: React.FC = () => {
  return (
    <Container>
      <p>
        <strong>1. INTRODU&Ccedil;&Atilde;O</strong>
      </p>

      <p>
        Pelo presente instrumento particular, de um lado, na qualidade de
        contratante, o CLIENTE, identificado e qualificado no cadastro pleno de
        identifica&ccedil;&atilde;o; e, de outro lado, na qualidade de
        contratada, AZCEND LTDA, inscrita no CNPJ sob o n&ordm;
        45.701.649/0001-78, com sede na AV PAULISTA, n&ordm; 1636, CONJ 4
        PAVMTO15, BELA VISTA, SAO PAULO/SP, CEP.: 01.310-200; t&ecirc;m entre si
        justo e acordado o quanto segue
      </p>

      <p>
        Como condi&ccedil;&atilde;o para acesso e uso dos servi&ccedil;os
        oferecidos pela AZCEND, consistente em emiss&atilde;o de
        cobran&ccedil;as e gest&atilde;o de receb&iacute;veis, o CONTRATANTE
        deve declarar que fez a leitura completa e atenta das regras deste
        documento, estando plenamente ciente e de acordo com elas.
      </p>

      <p>AZCEND</p>

      <p>
        <strong>2. OBJETO</strong>
      </p>

      <p>
        O objeto deste contrato &eacute; a presta&ccedil;&atilde;o de
        servi&ccedil;os em tecnologia e disponibiliza&ccedil;&atilde;o de
        ferramentas, pela AZCEND ao CONTRATANTE, de gest&atilde;o de pagamentos,
        por conta e ordem do CONTRATANTE, intermedia&ccedil;&atilde;o de
        transa&ccedil;&otilde;es, emiss&atilde;o de cobran&ccedil;as e
        transfer&ecirc;ncia de recursos.
      </p>

      <p>
        2.1 O CONTRATANTE em quest&atilde;o declara-se ciente de que os
        servi&ccedil;os t&ecirc;m por finalidade facilitar a gest&atilde;o de
        pagamentos e n&atilde;o devem ser confundidos com servi&ccedil;os
        financeiros, n&atilde;o sendo poss&iacute;vel investimento de recursos
        ou capta&ccedil;&atilde;o de empr&eacute;stimo ou quaisquer outros
        servi&ccedil;os disponibilizados exclusivamente por entidades
        banc&aacute;rias.
      </p>

      <p>
        2.2 Os servi&ccedil;os s&atilde;o prestados mediante a licen&ccedil;a de
        uso sobre as ferramentas de tecnologia AZCEND, de propriedade da AZCEND,
        dispon&iacute;vel no endere&ccedil;o eletr&ocirc;nico
        ​www.azcend.com.br.
      </p>

      <p>
        2.3 O CONTRATANTE est&aacute; ciente sobre a outorga realizada, neste
        ato de contrato, mandato &agrave; AZCEND, que constitui procurador, para
        fim de executar as ordens de pagamentos, transfer&ecirc;ncia e resgates
        de recursos, assim como qualquer outra pr&aacute;tica definida como
        necess&aacute;ria para a presta&ccedil;&atilde;o do servi&ccedil;o em
        quest&atilde;o.
      </p>

      <p>
        <strong>3. SERVI&Ccedil;OS INCLUSOS</strong>
      </p>

      <p>
        Os servi&ccedil;os oferecidos
        referem-se&nbsp;a&nbsp;intermedia&ccedil;&atilde;o de pagamento na
        modalidade de boleto banc&aacute;rio, cart&atilde;o de cr&eacute;dito,
        cart&atilde;o de d&eacute;bito, PIX e e-wallet, e na
        disponibiliza&ccedil;&atilde;o de ferramentas de tecnologia para que o
        CONTRATANTE possa receber pagamentos via internet, emitidos em sua
        p&aacute;gina virtual, sistema de gest&atilde;o ou atrav&eacute;s da
        gera&ccedil;&atilde;o manual de cobran&ccedil;as
      </p>

      <p>
        3.1 O servi&ccedil;o de intermedia&ccedil;&atilde;o inclui:
        <br />
        ● (i) a captura das transa&ccedil;&otilde;es comerciais;
        <br />
        ● (ii) o controle dos pedidos gerados e valores recebidos;
        <br />● (iii) controle e fornecimento de extratos sobre as
        movimenta&ccedil;&otilde;es financeiras.
      </p>

      <p>
        3.2 O valor m&iacute;nimo para emiss&atilde;o de um boleto e captura de
        cart&atilde;o ser&aacute; de R$5,00 (cinco reais).
      </p>

      <p>
        3.3 O valor m&aacute;ximo para emiss&atilde;o de um boleto ou captura de
        cart&atilde;o ser&aacute; determinado pela AZCEND e poder&aacute; variar
        de acordo com a an&aacute;lise de risco realizada pela pr&oacute;pria
        AZCEND.
      </p>

      <p>
        <strong>4. HABILITA&Ccedil;&Atilde;O</strong>
      </p>

      <p>
        O uso dos servi&ccedil;os da AZCEND e a disponibiliza&ccedil;&atilde;o
        integral de suas ferramentas, est&atilde;o sujeitas ao registro e
        habilita&ccedil;&atilde;o do CONTRATANTE junto &agrave; AZCEND.
      </p>

      <p>
        4.1 Para se habilitar na AZCEND, o CONTRATANTE dever&aacute; realizar
        primeiramente o seu registro no endere&ccedil;o eletr&ocirc;nico
        https://accounts.AZCEND.net/, e selecionar o tipo &ldquo;pessoa
        jur&iacute;dica&rdquo;. Os servi&ccedil;os prestados pela AZCEND,
        deixaram de ser ofertados a novos CLIENTES do tipo &ldquo;pessoa
        f&iacute;sica&rdquo;, a partir de 06/10/2022.
      </p>

      <p>
        4.2 Ap&oacute;s realizar o registro, atendendo as diretrizes de
        seguran&ccedil;a e compliance, poder&aacute; o CONTRATANTE realizar
        agendamento de videoconfer&ecirc;ncia para alinhamento de objetivos
        entre CONTRATANTE e CONTRATADA. O CONTRATANTE est&aacute; ciente de que
        a videoconfer&ecirc;ncia ser&aacute; gravada e feita coleta de
        informa&ccedil;&otilde;es pessoais, autorizando e anuindo com a
        grava&ccedil;&atilde;o, que ser&aacute; concedida a t&iacute;tulo
        gratuito, por tempo indeterminado.
      </p>

      <p>
        4.3 Ap&oacute;s o CONTRATANTE realizar o registro dever&aacute; submeter
        os documentos pessoais e de sua empresa de forma eletr&ocirc;nica, por
        meio de anexo, atrav&eacute;s do portal AZCEND, em formato de arquivo
        JPEG ou PDF leg&iacute;veis, com resolu&ccedil;&atilde;o m&iacute;nima
        300dpis, com tamanho m&aacute;ximo de 2MB, os quais passar&atilde;o por
        an&aacute;lise de autenticidade junto &agrave; AZCEND, num prazo de
        at&eacute; 7 (sete) dias &uacute;teis, a contar do envio. Em caso de
        reprova&ccedil;&atilde;o dos documentos remetidos, a AZCEND
        encaminhar&aacute; o motivo para o e-mail do CONTRATANTE, que
        poder&aacute; reenviar a documenta&ccedil;&atilde;o por at&eacute; 3
        (tr&ecirc;s) vezes para nova an&aacute;lise, observadas as
        orienta&ccedil;&otilde;es recebidas.
      </p>

      <p>
        4.4 Ap&oacute;s o cadastro do CONTRATANTE ser aprovado, poder&aacute;
        acessar, com seu usu&aacute;rio e senha, a &aacute;rea onde ser&aacute;
        feita a gest&atilde;o das suas cobran&ccedil;as e uso das ferramentas da
        AZCEND.
      </p>

      <p>
        4.5 Caber&aacute; somente &agrave; AZCEND os crit&eacute;rios de
        avalia&ccedil;&atilde;o dos dados comerciais fornecidos pelo CONTRATANTE
        para sua aprova&ccedil;&atilde;o, reprova&ccedil;&atilde;o ou
        atualiza&ccedil;&atilde;o.
      </p>

      <p>
        4.6 O CONTRATANTE se compromete em N&Atilde;O registrar dados comerciais
        que n&atilde;o tenham liga&ccedil;&atilde;o ao seu pr&oacute;prio
        com&eacute;rcio, ficando sujeito &agrave; responsabilidade mencionada no
        item 9 (nove) deste documento caso o CONTRATANTE fa&ccedil;a uso
        indevido de informa&ccedil;&otilde;es comerciais, mesmo que, por
        desconhecimento ou quaisquer outros motivos, venha ser aprovado pela
        AZCEND.
      </p>

      <p>
        4.7 Os documentos necess&aacute;rios devem estar v&aacute;lidos e
        atualizados, a saber:
      </p>

      <ul>
        <li>
          ● (i) Documento de identifica&ccedil;&atilde;o pessoal, tais como:
          Registro geral (RG), Carteira Nacional de Habilita&ccedil;&atilde;o
          (CNH) ou Carteira de Registro Nacional Migrat&oacute;rio (CRNM);
        </li>
        <li>
          ● (ii) Cadastro de Pessoa F&iacute;sica (CPF) e Cadastro Nacional de
          Pessoa Jur&iacute;dica (CNPJ);
        </li>
        <li>● (iii) Comprovante de resid&ecirc;ncia pessoal e da empresa</li>
        <li>● (iv) Comprovante banc&aacute;rio;</li>
        <li>
          ● (v) Atos Constitutivos da Empresa. Ex. Contrato social com todas as
          suas altera&ccedil;&otilde;es (contrato social consolidado) ou
          Certificado da Condi&ccedil;&atilde;o de Microempreendedor Individual
          (CCMEI).
        </li>
      </ul>

      <p>
        4.8 Os documentos pessoais citados no item anterior s&atilde;o
        obrigat&oacute;rios e insubstitu&iacute;veis. Em caso de perda das vias
        originais, poder&aacute; ser encaminhado &agrave; AZCEND documento
        equivalente expedido por institui&ccedil;&otilde;es oficiais, anexado ao
        boletim de ocorr&ecirc;ncia policial emitido em raz&atilde;o da
        perda/extravio dos documentos.
      </p>

      <p>
        4.9 O envio dos atos constitutivos e suas altera&ccedil;&otilde;es
        s&atilde;o obrigat&oacute;rios para registro de contas AZCEND do tipo
        &ldquo;pessoa jur&iacute;dica&rdquo; ou caso o CONTRATANTE use
        informa&ccedil;&otilde;es empresariais nas suas rela&ccedil;&otilde;es
        comerciais. Nos atos constitutivos enviados pelo CONTRATANTE
        dever&aacute; constar obrigatoriamente como s&oacute;cio ou
        administrador, o titular da conta AZCEND, bem como deve estar
        devidamente registrado nos &oacute;rg&atilde;os competentes.
      </p>

      <p>
        4.10 Caso ocorra altera&ccedil;&atilde;o dos dados cadastrais do
        CONTRATANTE, os documentos pessoais e empresariais dever&atilde;o ser
        reenviados e submetidos &agrave; an&aacute;lise da AZCEND, sob pena de
        ser suspensa a conta do CONTRATANTE at&eacute; sua
        atualiza&ccedil;&atilde;o.
      </p>

      <p>
        4.11 A AZCEND poder&aacute; realizar procedimentos adicionais para
        comprova&ccedil;&atilde;o de habilita&ccedil;&atilde;o para o uso das
        ferramentas, a qualquer tempo e a seu crit&eacute;rio, caso julgue
        necess&aacute;rio para manter a legitimidade das
        informa&ccedil;&otilde;es. Os procedimentos adicionais poder&atilde;o
        ser os que seguem, mas n&atilde;o se limitam somente a estes:
      </p>

      <ul>
        <li>
          ● (i) Confirma&ccedil;&atilde;o do endere&ccedil;o via
          correspond&ecirc;ncia f&iacute;sica e retorno de aviso de recebimento;
        </li>
        <li>
          ● (ii) Envio de documentos pessoais de todos os s&oacute;cios da
          empresa;
        </li>
        <li>
          ● (iii) Exig&ecirc;ncia de assinatura com reconhecimento de firma por
          autenticidade em cart&oacute;rio e envio a AZCEND.
        </li>
      </ul>

      <p>
        4.12 O CONTRATANTE poder&aacute; emitir suas cobran&ccedil;as somente
        ap&oacute;s seus dados comerciais serem aprovados.
      </p>

      <p>
        4.13 Caso haja recursos dispon&iacute;veis e seu cadastro estiver
        pendente de alguma informa&ccedil;&atilde;o ou de documentos, a
        solicita&ccedil;&atilde;o de retirada de recurso para conta
        banc&aacute;ria poder&aacute; ser realizada somente ap&oacute;s todos os
        documentos pessoais e empresariais serem aprovados pela AZCEND
      </p>

      <p>
        4.14 Caso o CONTRATANTE ou seu representante legal n&atilde;o
        fa&ccedil;am o envio da documenta&ccedil;&atilde;o solicitada,
        independentemente do motivo, o saldo de valores recebidos poder&aacute;
        ficar bloqueado at&eacute; que a situa&ccedil;&atilde;o seja
        regularizada.
      </p>

      <p>
        4.15 Os eventuais custos que envolvem a habilita&ccedil;&atilde;o do
        CONTRATANTE, dever&atilde;o ser custeados integralmente pelo
        CONTRATANTE, n&atilde;o sendo passivo de reembolso.
      </p>

      <p>
        4.16 Os prazos estabelecidos nos t&oacute;picos anteriores, referentes
        ao registro/habilita&ccedil;&atilde;o do CONTRATANTE na AZCEND,
        poder&atilde;o sofrer dilata&ccedil;&atilde;o, caso haja necessidade de
        confirma&ccedil;&otilde;es adicionais ou caso a ju&iacute;zo da AZCEND,
        haja algum risco associado &agrave; opera&ccedil;&atilde;o.
      </p>

      <p>
        4.17 A AZCEND se reserva ao direito de exigir que eventuais documentos
        solicitados do CONTRATANTE sejam autenticados por autenticidade por
        &oacute;rg&atilde;o com f&eacute; p&uacute;blica.
      </p>

      <p>
        <strong>
          5. TARIFA PARA PRESTA&Ccedil;&Atilde;O DE SERVI&Ccedil;O
        </strong>
      </p>

      <p>
        Em contrapartida &agrave; presta&ccedil;&atilde;o dos servi&ccedil;os e
        a licen&ccedil;a de uso das ferramentas AZCEND, o CONTRATANTE
        pagar&aacute; &agrave; AZCEND uma tarifa por cada
        transa&ccedil;&atilde;o que for aprovada. O valor cobrado ser&aacute; de
        acordo com a negocia&ccedil;&atilde;o estabelecida entre a AZCEND e o
        CONTRATANTE. Caso nenhuma negocia&ccedil;&atilde;o direta seja firmada,
        ser&aacute; adotado a tabela de tarifas da AZCEND dispon&iacute;vel no
        endere&ccedil;o eletr&ocirc;nico www.AZCEND.net
      </p>

      <p>
        5.1 A tarifa ser&aacute; paga &agrave; AZCEND, no ato da
        aprova&ccedil;&atilde;o da transa&ccedil;&atilde;o, n&atilde;o
        pass&iacute;vel de devolu&ccedil;&atilde;o sob nenhuma hip&oacute;tese.
      </p>

      <p>
        5.2 A AZCEND poder&aacute; ALTERAR as tarifas sobre os servi&ccedil;os
        prestados, informando previamente o CONTRATANTE atrav&eacute;s das
        ferramentas dispon&iacute;veis no portal online www.AZCEND.net e/ou
        atrav&eacute;s do e-mail fornecido pelo CONTRATANTE, num prazo de
        at&eacute; 10 (dez) dias de anteced&ecirc;ncia do reajuste. Em caso de
        N&Atilde;O concord&acirc;ncia com as novas condi&ccedil;&otilde;es de
        tarifa, o CONTRATANTE poder&aacute; encerrar sua opera&ccedil;&atilde;o,
        n&atilde;o aderindo &agrave;s novas condi&ccedil;&otilde;es, devendo
        arcar com os d&eacute;bitos pendentes em seu cadastro AZCEND, caso haja
        algum
      </p>

      <p>
        5.3 O CONTRATANTE est&aacute; ciente e concorda que, caso as tarifas
        cobradas pelo banco emissor e/ou adquirente venham a sofrer quaisquer
        reajustes, os referidos reajustes dever&atilde;o ser refletidos nas
        tarifas cobradas pela AZCEND, de forma imediata, sem aviso
        pr&eacute;vio, assegurando o equil&iacute;brio
        econ&ocirc;mico-financeiro da presta&ccedil;&atilde;o de
        servi&ccedil;os, objeto do presente contrato.
      </p>

      <p>
        <strong>6.​ ​TRANSFER&Ecirc;NCIAS E RECURSOS</strong>
      </p>

      <p>
        O prazo de liquida&ccedil;&atilde;o do boleto ocorrer&aacute; de acordo
        com as regras do banco emissor. Os valores l&iacute;quidos referentes
        aos pagamentos aprovados, ou seja, j&aacute; com as tarifas descontadas,
        constar&atilde;o na conta AZCEND do CONTRATANTE e ficar&atilde;o
        dispon&iacute;veis para solicita&ccedil;&atilde;o de saque no prazo
        referente ao plano selecionado, atendido o disposto na cl&aacute;usula
        6.13 e 6.151. Todo o controle dos recursos a receber e recursos
        dispon&iacute;veis, assim como solicita&ccedil;&atilde;o de saque
        poder&atilde;o ser feitos atrav&eacute;s do painel do CONTRATANTE
        disponibilizado pela AZCEND.
      </p>

      <p>
        6.1 A AZCEND manter&aacute; os recursos constantes na conta AZCEND do
        CONTRATANTE sob sua cust&oacute;dia, em conta banc&aacute;ria de
        titularidade da AZCEND e em institui&ccedil;&atilde;o financeira de
        escolha deste &uacute;ltimo.
      </p>

      <p>
        6.2 Os cr&eacute;ditos existentes na conta do CONTRATANTE n&atilde;o
        sofrer&atilde;o nenhuma corre&ccedil;&atilde;o monet&aacute;ria,
        acr&eacute;scimo de juros ou qualquer tipo de atualiza&ccedil;&atilde;o
        financeira, permanecendo inalterados por todo o prazo em que forem
        mantidos na conta. A AZCEND n&atilde;o ter&aacute; nenhuma
        responsabilidade pela desvaloriza&ccedil;&atilde;o ou
        desatualiza&ccedil;&atilde;o monet&aacute;ria do valor dos
        cr&eacute;ditos mantidos pelo CONTRATANTE em sua conta. A AZCEND
        poder&aacute; receber juros sobre os valores que mantiver em seu nome. O
        CONTRATANTE concorda em transferir para a AZCEND seus direitos sobre
        quaisquer juros relativos a seus recursos.
      </p>

      <p>
        6.3 A AZCEND pode utilizar os recursos existentes na conta AZCEND do
        CONTRATANTE, bem como destinar eventuais cr&eacute;ditos do CONTRATANTE
        ao pagamento de condena&ccedil;&otilde;es judiciais ou administrativas
        e/ou &agrave; presta&ccedil;&atilde;o de garantias de responsabilidade
        do CONTRATANTE.
      </p>

      <p>
        6.3.1 O CONTRATANTE autoriza e aceita que a AZCEND desconte de sua Conta
        AZCEND, todo e qualquer valor decorrente de condena&ccedil;&otilde;es
        e/ou acordos em a&ccedil;&otilde;es judiciais e/ou administrativas,
        incluindo honor&aacute;rios advocat&iacute;cios, honor&aacute;rios de
        sucumb&ecirc;ncia, custas processuais, pagas pela AZCEND, ocasionados
        pela comercializa&ccedil;&atilde;o dos produtos e/ou servi&ccedil;os do
        CONTRATANTE, em que esta for demandada.
      </p>

      <p>
        6.3.2 Da cobran&ccedil;a de valores por honor&aacute;rios
        advocat&iacute;cios: O CONTRATANTE autoriza a AZCEND a descontar, do
        dinheiro dispon&iacute;vel e informado em sua Conta AZCEND, a quantia de
        R$1.000,00 (hum mil reais), a t&iacute;tulo de cobran&ccedil;a devida
        pelo CONTRATANTE em favor da AZCEND, sempre que esta ocupar o polo
        passivo em demanda judicial, e no caso de reclama&ccedil;&atilde;o
        extrajudicial autoriza o desconto da quantia de R$ 500,00 (quinhentos
        reais), por produto/servi&ccedil;o comercializado atrav&eacute;s da
        plataforma, em que a AZCEND for isoladamente ou conjuntamente com o
        CONTRATANTE acionado, para defesa &uacute;nica e exclusiva da AZCEND.
        N&atilde;o sendo de responsabilidade da AZCEND a defesa do CONTRATANTE
        que ter&aacute; que se fazer representar por profissional habilitado. O
        CONTRANTE tamb&eacute;m autoriza desde j&aacute;, o bloqueio em conta
        dos valores referente aos custos para a defesa da AZCEND dentro da
        plataforma. Em caso de falta de valores em conta, o CONTRATANTE
        dever&aacute; promover o pagamento de imediato destes valores &agrave;
        CONTRATADA.
      </p>

      <p>
        6.3.3 Os pedidos de&nbsp;Chargebacks&nbsp;podem ser feitos em at&eacute;
        365 dias, ap&oacute;s o dia da compra. Assim, ap&oacute;s o levantamento
        do saldo e/ou encerramento da Conta do Usu&aacute;rio, podem surgir
        d&eacute;bitos decorrentes de&nbsp;Chargebacks&nbsp;e/ou cancelamentos,
        dos quais uma vez cientificado pela AZCEND dever&aacute; depositar o
        valor, em conta banc&aacute;ria em favor da mesma, no prazo de
        at&eacute; 5 (cinco) dias corridos.
      </p>

      <p>
        6.4 Para resgatar os recursos dispon&iacute;veis em sua conta AZCEND, o
        CONTRATANTE dever&aacute; operar no sistema banc&aacute;rio nacional e
        possuir uma conta em um dos bancos disponibilizados no sistema da
        AZCEND.
      </p>

      <p>
        6.5 Para solicita&ccedil;&atilde;o de saques o CONTRATANTE dever&aacute;
        cadastrar uma conta banc&aacute;ria, corrente. Se o cadastro do
        CONTRATANTE na AZCEND for do tipo pessoa f&iacute;sica, a conta
        banc&aacute;ria fornecida dever&aacute; estar registrada em seu CPF/MF.
        Caso o registro do CONTRATANTE na AZCEND seja do tipo empresarial, a
        conta banc&aacute;ria fornecida dever&aacute; estar registrada em seu
        CNPJ.
      </p>

      <p>
        6.6 N&atilde;o ser&atilde;o aceitos pedidos de saque para conta
        banc&aacute;ria de titular diferente ao registrado na AZCEND.
        Tamb&eacute;m n&atilde;o ser&aacute; permitido pedido de saque para
        conta banc&aacute;ria na modalidade compartilhada ou conjunta.
      </p>

      <p>
        6.7 O valor do saque solicitado n&atilde;o poder&aacute; ser
        inferior&nbsp;&agrave;&nbsp;R$500,00 (quinhentos reais), caso
        contr&aacute;rio, ser&aacute; cobrada uma taxa de R$10,00 (dez reais)
        por pedido de saque.
      </p>

      <p>
        6.8 O CONTRATANTE poder&aacute; solicitar a quantidade de 1 (um) pedido
        de saque por dia.
      </p>

      <p>
        6.9 Os pedidos de saques poder&atilde;o passar por
        confirma&ccedil;&otilde;es diversas, antes de transferir os recursos.
        Tais confirma&ccedil;&otilde;es ocorrer&atilde;o via telefone e/ou em
        outro canal de atendimento. A AZCEND poder&aacute; adotar medidas
        complementares, tais como: transferir parcialmente o montante para o
        CONTRATANTE e solicitar informa&ccedil;&otilde;es sobre o valor
        depositado. O processo de confirma&ccedil;&atilde;o poder&aacute; se
        repetir sempre que a AZCEND julgar necess&aacute;rio para garantir a
        seguran&ccedil;a nas opera&ccedil;&otilde;es.
      </p>

      <p>
        6.9.1 A qualquer tempo a AZCEND poder&aacute; criar limites de retirada
        di&aacute;ria e prazos para a libera&ccedil;&atilde;o dos
        cr&eacute;ditos do CONTRATANTE.
      </p>

      <p>
        6.10 O prazo para que a AZCEND efetive o dep&oacute;sito do saque
        solicitado pelo CONTRATANTE ser&aacute; de at&eacute; 1 (um) dia
        &uacute;til, ap&oacute;s a confirma&ccedil;&atilde;o da possibilidade do
        saque pela AZCEND.
      </p>

      <p>
        6.10.1 A confirma&ccedil;&atilde;o de possibilidade de saque a pedido da
        AZCEND, junto &agrave; operadora, pode levar at&eacute; 07 (sete) dias
        &uacute;teis para compensar/disponibilizar o valor &agrave; AZCEND para
        que ent&atilde;o possa ser cumprida a solicita&ccedil;&atilde;o de saque
        do CONTRATANTE.
      </p>

      <p>
        6.10.2 A AZCEND far&aacute; a reten&ccedil;&atilde;o do percentual de
        10% (dez por cento) das vendas mensais realizadas pelo CONTRATANTE, a
        t&iacute;tulo de margem de seguran&ccedil;a para compensar
        eventuais&nbsp;chargebacks, contesta&ccedil;&otilde;es, atendimentos e
        media&ccedil;&otilde;es que constem como pendentes/em aberto, n&atilde;o
        sendo poss&iacute;vel o pedido de saque at&eacute; o m&ecirc;s
        subsequente ou at&eacute; que a sua solu&ccedil;&atilde;o.
      </p>

      <p>
        6.10.2.1 A margem de seguran&ccedil;a prevista para
        reten&ccedil;&atilde;o de percentual de 10% (dez por cento), pode ter
        seu percentual fixado em par&acirc;metro menor de acordo com o risco da
        atividade exercida pelo CONTRATANTE, sendo fixada num percentual
        m&iacute;nimo entre 5% (cinco por cento) at&eacute; 10% (dez por cento),
        de acordo com an&aacute;lise de risco mercadol&oacute;gico, a
        crit&eacute;rio exclusivo da AZCEND.
      </p>

      <p>
        6.10.3 No caso de encerramento da conta do CONTRATANTE junto &agrave;
        AZCEND ou na sua inoperabilidade, permanecer&aacute; retido o percentual
        de 10% (dez por cento) a t&iacute;tulo de margem de seguran&ccedil;a
        conforme previsto na cl&aacute;usula 6.10.2, pelo prazo de 365 dias que
        responder&aacute; pelo&nbsp;chargeback&nbsp;que poder&aacute; ocorrer em
        at&eacute; 365 dias, &acute;conforme disposto na cl&aacute;usula 6.3.3,
        sendo liberado o valor para saque somente ap&oacute;s o decurso do
        prazo.
      </p>

      <p>
        6.11 Os prazos estabelecidos para liquida&ccedil;&atilde;o do boleto e
        cart&atilde;o, disponibilidade do recurso e dep&oacute;sito do saque,
        poder&atilde;o sofrer dilata&ccedil;&atilde;o em caso de necessidade de
        confirma&ccedil;&otilde;es adicionais e/ou em casos que, a ju&iacute;zo
        da AZCEND, houver algum risco associado &agrave; opera&ccedil;&atilde;o
      </p>

      <p>
        6.12 Dependendo do n&iacute;vel de verifica&ccedil;&atilde;o da conta do
        CONTRATANTE, a AZCEND poder&aacute; limitar, retardar ou reter por prazo
        indeterminado sua possibilidade de retirar recursos, at&eacute; que o
        CONTRATANTE atenda aos pedidos de informa&ccedil;&otilde;es feitos pela
        AZCEND e enquanto realiza a an&aacute;lise de riscos.
      </p>

      <p>
        6.12.1 Fica ciente o CONTRATANTE que a AZCEND poder&aacute; realizar
        estorno de valores para os clientes pagadores/compradores caso seja
        constatada qualquer irregularidade de entrega dos produtos ou
        servi&ccedil;os em decorr&ecirc;ncia da falta de
        comprova&ccedil;&atilde;o da licitude da transa&ccedil;&atilde;o
        comercial.
      </p>

      <p>
        6.13 O valor l&iacute;quido do CONTRATANTE n&atilde;o ser&aacute;
        disponibilizado em casos de:
      </p>

      <ul>
        <li>
          ● (i) infra&ccedil;&atilde;o do CONTRATANTE aos termos deste contrato,
          quando esta puder ocasionar perdas para terceiros ou para a AZCEND;
        </li>
        <li>
          ● (ii) ind&iacute;cios ou provas de que o CONTRATANTE possa estar
          envolvido em atividades e/ou transa&ccedil;&otilde;es potencialmente
          fraudulentas ou suspeitas de serem pr&aacute;ticas criminosas mediante
          o uso dos sistemas da AZCEND
        </li>
        <li>
          ● (iii) n&atilde;o postagem do produto e fornecimento do c&oacute;digo
          de rastreio v&aacute;lido a fim de se possibilitar a
          confirma&ccedil;&atilde;o de sua entrega ao consumidor/comprador.
        </li>
      </ul>

      <p>
        6.14 &Eacute; de inteira responsabilidade do CONTRATANTE zelar pela
        regularidade da conta banc&aacute;ria fornecida, bem como pela
        corre&ccedil;&atilde;o das informa&ccedil;&otilde;es prestadas &agrave;
        AZCEND. A AZCEND n&atilde;o se responsabiliza e n&atilde;o far&aacute; a
        devolu&ccedil;&atilde;o de qualquer valor ou quantia, caso o CONTRATANTE
        tenha fornecido alguma informa&ccedil;&atilde;o errada e/ou incorreta
        para transfer&ecirc;ncia de valores.
      </p>

      <p>
        6.15 O CONTRATANTE poder&aacute; solicitar o saque, em regra,
        ap&oacute;s a postagem do produto e fornecimento do c&oacute;digo de
        rastreio &agrave; AZCEND, de acordo com o prazo selecionado previamente
        na plataforma, ou seja, o fornecimento de c&oacute;digo de rastreio
        v&aacute;lido e em andamento do produto &eacute; condi&ccedil;&atilde;o
        essencial para&nbsp;o libera&ccedil;&atilde;o&nbsp;do valor como
        dispon&iacute;vel no extrato, contudo, o pedido de saque ser&aacute;
        atendido/pago ao CONTRATANTE, ap&oacute;s a confirma&ccedil;&atilde;o de
        entrega do produto.
      </p>

      <p>
        6.15.1 Quando do pedido de levantamento do saldo dispon&iacute;vel na
        conta AZCEND pelo CONTRATANTE, ser&aacute; verificado se todos os
        c&oacute;digos de rastreio dos produtos s&atilde;o v&aacute;lidos e se a
        entrega do produto ao comprador/consumidor foi conclu&iacute;da, para
        efetivo levantamento/transfer&ecirc;ncia dos valores dispon&iacute;veis
        na conta AZCEND, sob pena de a AZCEND poder manter bloqueados os valores
        referentes as vendas ainda n&atilde;o conclu&iacute;das.
      </p>

      <p>
        6.16 Caso haja suspeitas de fraude ou risco &agrave;
        opera&ccedil;&atilde;o, a AZCEND poder&aacute; reter parte ou todo o
        valor da Conta Virtual para arcar com os
        poss&iacute;veis&nbsp;Chargebacks&nbsp;ou Reembolsos, ou
        preju&iacute;zos e indeniza&ccedil;&otilde;es suportadas pela AZCEND por
        culpa do CONTRATANTE ou terceiros que utilizem sua Conta Virtual.
      </p>

      <p>
        6.17 A AZCEND se compromete a dar todo o suporte necess&aacute;rio para
        o CONTRATANTE, como explicar o motivo do bloqueio e fornecer uma
        previs&atilde;o de libera&ccedil;&atilde;o de valores.
      </p>

      <p>
        6.18 O percentual liberado para antecipa&ccedil;&atilde;o de cada
        CONTRATANTE ser&aacute; vari&aacute;vel de acordo com crit&eacute;rios
        de an&aacute;lise exclusivos da AZCEND.
      </p>

      <p>
        6.19 A equipe de risco poder&aacute; solicitar informa&ccedil;&otilde;es
        ou documentos do CONTRATANTE, al&eacute;m dos c&oacute;digos de rastreio
        das vendas de produtos f&iacute;sicos e comprovantes de envio dos
        produtos digitais, para an&aacute;lise da viabilidade de qualquer
        antecipa&ccedil;&atilde;o.
      </p>

      <p>
        6.20 O CONTRATANTE declara-se ciente de que, uma vez transferidos os
        valores da Conta Virtual &agrave; Conta Banc&aacute;ria, de acordo com
        as instru&ccedil;&otilde;es dadas pelo CONTRATANTE, tais pagamentos
        s&atilde;o definitivos e irrevers&iacute;veis.
      </p>

      <p>
        6.21 Nas hip&oacute;teses de o CONTRATANTE solicitar o encerramento da
        conta junto &agrave; AZCEND, ser&aacute; observado para
        libera&ccedil;&atilde;o e levantamento total de saldo:
      </p>

      <ul>
        <li>
          -&nbsp;margem&nbsp;de seguran&ccedil;a de 10% (dez por cento) das
          vendas a fim de garantir&nbsp;chargebacks&nbsp;que podem vir a ocorrer
          nos pr&oacute;ximos 365 dias;
        </li>
        <li>
          -&nbsp;contesta&ccedil;&otilde;es&nbsp;em an&aacute;lise/aberto;
        </li>
        <li>
          -&nbsp;pend&ecirc;ncias&nbsp;nos c&oacute;digos informados:
          c&oacute;digos que constem com problema na entrega, c&oacute;digos
          postados (sem movimenta&ccedil;&atilde;o), c&oacute;digos duplicados,
          c&oacute;digos anteriores &agrave; venda;
        </li>
        <li>
          -&nbsp;media&ccedil;&otilde;es&nbsp;com mais de 07 (sete) dias sem
          solu&ccedil;&atilde;o.
        </li>
      </ul>

      <p>
        <strong>
          7. SUPORTE AO CONSUMIDOR, DA MEDIA&Ccedil;&Atilde;O, CHARGEBACKS E
          REEMBOLSOS
        </strong>
      </p>

      <p>
        7.1 A AZCEND n&atilde;o &eacute; respons&aacute;vel pelo Suporte ao
        Consumidor sobre qualquer quest&atilde;o referente ao Produto. As
        reclama&ccedil;&otilde;es feitas perante o Suporte da AZCEND referentes
        a v&iacute;cios, defeitos ou qualquer outro problema do produto
        ser&atilde;o encaminhadas ao CONTRATANTE respons&aacute;vel. Em
        rela&ccedil;&atilde;o aos chamados de Consumidores encaminhados ao
        CONTRATANTE, este dever&aacute; manter a AZCEND informada sobre a
        resolu&ccedil;&atilde;o da quest&atilde;o, sendo reservado o direito da
        AZCEND de acompanhar o suporte a ser feito pelo CONTRATANTE.
      </p>

      <p>
        7.1.1 A resposta do CONTRATANTE sobre o suporte prestado ao Consumidor
        dever&aacute; ser feita, exclusivamente, por e-mail, via chat
        ou&nbsp;Whatsapp&nbsp;presente na Plataforma.
      </p>

      <p>
        7.2 O CONTRATANTE dever&aacute; responder, em at&eacute; 7 (sete) dias
        &uacute;teis, as solicita&ccedil;&otilde;es feitas por qualquer
        Consumidor ao seu servi&ccedil;o de suporte. Caso n&atilde;o haja
        resposta, a AZCEND poder&aacute;, a seu exclusivo crit&eacute;rio e sem
        a necessidade de notificar o CONTRATANTE previamente, cancelar a
        transa&ccedil;&atilde;o e realizar o reembolso ao consumidor,
        independente do produto j&aacute; ter sido enviado ou entregue.
      </p>

      <p>
        7.3 Caso o Consumidor apresente uma solicita&ccedil;&atilde;o contra o
        CONTRATANTE no site &ldquo;Reclame Aqui&rdquo;, este ter&aacute; o prazo
        de 1 (um) dia &uacute;til para prestar o devido suporte ao Consumidor,
        devendo informar a AZCEND sobre a resposta ao caso. Se, no prazo
        estipulado, o CONTRATANTE n&atilde;o apresentar sua resposta ao
        Consumidor, a AZCEND poder&aacute;, a seu exclusivo crit&eacute;rio e
        sem a necessidade de notificar o CONTRATANTE previamente, cancelar
        imediatamente a Transa&ccedil;&atilde;o, efetuando o Reembolso ao
        Consumidor, independente do produto j&aacute; ter sido enviado ou
        entregue.
      </p>

      <p>
        7.3.1 Reclama&ccedil;&otilde;es formalizadas em face da AZCEND no
        &ldquo;Reclame Aqui&rdquo; que forem do CONTRATANTE ser&atilde;o
        redirecionadas a pedido da AZCEND para o &ldquo;Reclame Aqui&rdquo; do
        contratante.
      </p>

      <p>
        7.3.2 O CONTRATANTE permanecer&aacute; respons&aacute;vel em responder
        &agrave;s solicita&ccedil;&otilde;es dos consumidores no &ldquo;Reclame
        Aqui&rdquo;, mesmo ap&oacute;s o encerramento da conta junto &agrave;
        AZCEND.
      </p>

      <p>
        7.4. A AZCEND disponibiliza ferramenta/p&aacute;gina de servi&ccedil;o
        de atendimento ao consumidor para o CONTRATANTE, por onde oferece seu
        suporte ao consumidor final. Feita reclama&ccedil;&atilde;o pelo
        consumidor, dever&aacute; o CONTRATANTE responder, em at&eacute; 7
        (sete) dias corridos. Caso o consumidor e o CONTRATANTE n&atilde;o
        cheguem a uma solu&ccedil;&atilde;o, poder&aacute;, o consumidor,
        solicitar a media&ccedil;&atilde;o da AZCEND para solu&ccedil;&atilde;o
        da quest&atilde;o, com base no presente termos de uso e na
        boa-f&eacute;, devendo a decis&atilde;o da AZCEND ser acatada de modo
        integral e irrecorr&iacute;vel pelo CONTARTANTE e consumidor.
      </p>

      <p>
        7.4.1 A AZCEND n&atilde;o &eacute; respons&aacute;vel por qualquer
        quest&atilde;o referente ao produto. As reclama&ccedil;&otilde;es feitas
        por meio da ferramenta da AZCEND referentes a v&iacute;cios, defeitos ou
        qualquer outro problema do produto, se n&atilde;o respondidas no prazo
        de 7 (sete) dias pelo CONTRATANTE ser&atilde;o
        respondidas&nbsp;pela&nbsp;AZCEND que poder&aacute;, a seu exclusivo
        crit&eacute;rio e sem a necessidade de notificar o CONTRATANTE analisar
        o pedido do consumidor, e acatar pedido de cancelamento, reembolso ou
        estorno independente do produto j&aacute; ter sido enviado ou entregue.
      </p>

      <p>
        7.4.2 A media&ccedil;&atilde;o &eacute; um processo volunt&aacute;rio
        que oferece &agrave;queles que est&atilde;o vivenciando uma
        situa&ccedil;&atilde;o de conflito a oportunidade e o espa&ccedil;o
        adequados para conseguir buscar uma solu&ccedil;&atilde;o que atenda a
        todos os envolvidos.
      </p>

      <p>
        7.5 Caso ocorra qualquer&nbsp;Chargeback&nbsp;ou Reembolso referente a
        qualquer Transa&ccedil;&atilde;o, o valor equivalente &agrave;s
        contesta&ccedil;&otilde;es de recebimento efetuadas pelos Consumidores
        ou ao Reembolso informado pelo CONTRATANTE na Conta Virtual ser&aacute;
        debitado automaticamente de qualquer valor que o CONTRATANTE tenha
        recebido ou venha a receber por meio da Plataforma AZCEND.
      </p>

      <p>
        7.6 O CONTRATANTE reconhece que a AZCEND poder&aacute; efetivar o
        Reembolso quando forem levantadas suspeitas sobre a n&atilde;o
        observ&acirc;ncia dos direitos dos Consumidores ou sobre a
        opera&ccedil;&atilde;o do CONTRATANTE, a exemplo do:
      </p>

      <ul>
        <li>
          ● n&atilde;o envio de comprova&ccedil;&atilde;o v&aacute;lida de
          entrega ou n&atilde;o envio do c&oacute;digo de rastreamento dentro de
          2 (dois) dias &uacute;teis ap&oacute;s solicita&ccedil;&atilde;o pela
          AZCEND;
        </li>
        <li>
          ● mediante solicita&ccedil;&atilde;o da AZCEND, envio de um aviso de
          recebimento sem assinatura, ou envio de c&oacute;digos de rastreamento
          cujo status (nos Correios ou transportadora relevante) n&atilde;o
          demonstre que o produto foi entregue com sucesso ao
          destinat&aacute;rio;
        </li>
        <li>
          ● transa&ccedil;&atilde;o aprovada sem c&oacute;digo de rastreio
          v&aacute;lido por mais de 10 (dez) dias corridos.
        </li>
        <li>
          ● envio de c&oacute;digos de rastreamento que n&atilde;o s&atilde;o
          v&aacute;lidos;
        </li>
        <li>
          ● entrega de produto em endere&ccedil;o divergente do cadastrado pelo
          Consumidor na Plataforma AZCEND;
        </li>
        <li>
          ● realizar entrega em m&atilde;os sem recibo assinado e sem
          c&oacute;pia de documento (RG e/ou documento v&aacute;lido em
          territ&oacute;rio nacional) que contenha assinatura id&ecirc;ntica ao
          recibo;
        </li>
        <li>
          ● cadastro de produto f&iacute;sico como digital, ou vice-versa;
        </li>
        <li>
          ● o cadastro na AZCEND for em nome do Consumidor e o pagamento vier a
          ser contestado pelo portador do cart&atilde;o;
        </li>
        <li>
          ● envio de produtos que n&atilde;o estejam de acordo com as
          informa&ccedil;&otilde;es espec&iacute;ficas da oferta;
        </li>
        <li>
          ● nos casos de dois ou mais pagamentos id&ecirc;nticos, n&atilde;o
          envio de comprova&ccedil;&atilde;o v&aacute;lida da entrega de dois ou
          mais produtos e/ou servi&ccedil;os;
        </li>
        <li>
          ● n&atilde;o cancelamento da Transa&ccedil;&atilde;o quando solicitado
          pela AZCEND devido &agrave; identifica&ccedil;&atilde;o de qualquer
          irregularidade;
        </li>
        <li>
          ● as Pol&iacute;ticas ou Termos e Condi&ccedil;&otilde;es do site do
          CONTRATANTE n&atilde;o estiverem claros ou oneram o Consumidor;
        </li>
        <li>
          ● o Consumidor comprovar que efetuou a solicita&ccedil;&atilde;o do
          cancelamento da compra no prazo de at&eacute; 7 (sete) dias a contar
          de sua assinatura (data da compra) ou do ato de recebimento do produto
          ou servi&ccedil;o, como garante o art. 49 do C&oacute;digo de Defesa
          do Consumidor, e o CONTRATANTE n&atilde;o cancelar a
          Transa&ccedil;&atilde;o;
        </li>
        <li>
          ● o CONTRATANTE entregar produtos com v&iacute;cios de qualidade que
          os tornem impr&oacute;prios ou inadequados ao consumo a que se
          destinam;
        </li>
        <li>
          ● o Consumidor comprovar, por meio de c&oacute;digo de rastreamento,
          que devolveu o produto ao CONTRATANTE;
        </li>
        <li>
          ● o Consumidor comprovar que tentou solucionar o problema com o
          CONTRATANTE, mas n&atilde;o obteve resposta;
        </li>
        <li>● o CONTRATANTE recusar o recebimento do produto devolvido;</li>
        <li>
          ● o CONTRATANTE n&atilde;o divulgar apropriadamente poss&iacute;veis
          atrasos na entrega do produto e/ou servi&ccedil;os;
        </li>
        <li>
          ● o CONTRATANTE comercializar produtos/servi&ccedil;os diferentes da
          categoria informada em sua Conta Virtual na AZCEND;
        </li>
        <li>
          ● o CONTRATANTE desmembrar o valor do produto/servi&ccedil;o em mais
          de uma Transa&ccedil;&atilde;o e isto for identificado pelo Consumidor
          como duplicidade de pagamento;
        </li>
        <li>
          ● realizar o envio do produto ou a presta&ccedil;&atilde;o de
          servi&ccedil;o somente em data igual ou posterior a data do
          recebimento da contesta&ccedil;&atilde;o; entre outros.
        </li>
      </ul>

      <p>&nbsp;</p>

      <p>
        7.7 Caso o CONTRATANTE possua &iacute;ndice de&nbsp;Chargeback&nbsp;em
        volume considerado elevado, segundo crit&eacute;rios definidos
        exclusivamente pela AZCEND, e/ou pelas bandeiras de cart&otilde;es de
        cr&eacute;dito e/ou d&eacute;bito e/ou credenciadoras, a AZCEND e/ou a
        respectiva bandeira poder&aacute; aplicar penalidade de multa ao
        CONTRATANTE, sem preju&iacute;zo da possibilidade de bloqueio da Conta
        Virtual, rescis&atilde;o imediata do Contrato pela AZCEND,
        altera&ccedil;&atilde;o da Remunera&ccedil;&atilde;o e da
        obriga&ccedil;&atilde;o de indeniza&ccedil;&atilde;o por quaisquer
        perdas ocasionadas &agrave; CONTRATADA.
      </p>

      <p>
        7.7.1 A AZCEND reserva-se ao direito de bloquear a
        realiza&ccedil;&atilde;o de qualquer Transa&ccedil;&atilde;o por meio da
        Plataforma AZCEND do CONTRATANTE, caso o &iacute;ndice
        de&nbsp;Chargeback&nbsp;no cart&atilde;o de cr&eacute;dito ultrapasse o
        equivalente a 3% (tr&ecirc;s por cento) do total de
        Transa&ccedil;&otilde;es j&aacute; realizadas pelo CONTRATANTE por meio
        da Plataforma AZCEND. &Iacute;ndices acima de 1,5% sofrer&atilde;o
        penalidades determinadas pela AZCEND.
      </p>

      <p>
        7.7.1.1 A CONTRATADA possui tecnologia de redu&ccedil;&atilde;o
        de&nbsp;Chargeback, atrav&eacute;s de conv&ecirc;nio entre algumas
        operadoras de cart&atilde;o de cr&eacute;dito, que envia aviso a cada
        pedido de&nbsp;chargeback, possibilitando que a AZCEND consiga fazer o
        estorno e n&atilde;o seja contabilizado para a bandeira do cart&atilde;o
        como contesta&ccedil;&atilde;o realizada pelo consumidor final.
      </p>

      <p>
        7.7.2 A cada&nbsp;chargeback&nbsp;recebido pela loja do CONTRATANTE,
        ser&aacute; cobrado uma porcentagem (mediante
        avalia&ccedil;&atilde;o&nbsp;da mesma) do saldo do CONTRATANTE para
        gastos operacionais de revers&atilde;o de&nbsp;chargeback.
      </p>

      <p>
        7.8 A AZCEND n&atilde;o reembolsar&aacute; o CONTRATANTE por qualquer
        tarifa cobr&aacute;vel sob este Termo, se a Transa&ccedil;&atilde;o for
        reembolsada ou sujeita a um&nbsp;Chargeback.
      </p>

      <p>
        7.9 Caso o saldo da Conta Virtual do CONTRATANTE n&atilde;o seja
        suficiente para cobrir o valor integral de
        qualquer&nbsp;Chargeback&nbsp;e/ou Reembolso e a Conta Virtual
        permane&ccedil;a com saldo negativo por mais de 30 (trinta) dias
        consecutivos, o CONTRATANTE receber&aacute; uma
        notifica&ccedil;&atilde;o extrajudicial indicando o d&eacute;bito em
        aberto, emitida pela AZCEND, devendo o CONTRATANTE efetuar seu pagamento
        integral dentro de, no m&aacute;ximo, 10 (dez) dias contados do
        recebimento da notifica&ccedil;&atilde;o, sob pena da AZCEND tomar as
        medidas judiciais cab&iacute;veis.
      </p>

      <p>
        7.9.1 Ap&oacute;s o prazo de 30 (trinta) dias, com o saldo devedor
        negativo, aplicar-se-&aacute; a incid&ecirc;ncia de multa contratual de
        2% (dois por cento), juros de mora de 1% (um por cento) ao m&ecirc;s e
        atualizado monetariamente pelo &iacute;ndice do INPC.
      </p>

      <p>
        7.10 O CONTRATANTE compromete-se a envidar seus melhores esfor&ccedil;os
        para reduzir ao m&aacute;ximo a quantidade de&nbsp;Chargebacks,
        incluindo, sem limita&ccedil;&atilde;o, por meio de um Suporte
        eficiente.
      </p>

      <p>
        <strong>
          8. OBRIGA&Ccedil;&Otilde;ES E RESPONSABILIDADES DO USU&Aacute;RIO
        </strong>
      </p>

      <p>
        8.1 N&atilde;o obstante as demais obriga&ccedil;&otilde;es previstas
        neste Contrato, o CONTRATANTE se responsabiliza a fornecer &agrave;
        AZCEND, imediatamente, todas as informa&ccedil;&otilde;es que lhe sejam
        solicitadas para fins de execu&ccedil;&atilde;o do Contrato, incluindo,
        mas n&atilde;o se limitando a, dados cadastrais, societ&aacute;rios e/ou
        banc&aacute;rios.
      </p>

      <p>
        8.1.1 O CONTRATANTE deve fornecer tais informa&ccedil;&otilde;es
        &agrave; AZCEND no prazo de 2 (dois) dias &uacute;teis ap&oacute;s
        solicita&ccedil;&atilde;o, sob pena de o CONTRATANTE responder, nos
        termos da lei, pela veracidade, certeza, sufici&ecirc;ncia e
        consist&ecirc;ncia das informa&ccedil;&otilde;es prestadas &agrave;
        AZCEND, bem como por eventual diverg&ecirc;ncia entre os dados
        informados e os dados reais e/ou oficiais, incluindo quaisquer perdas
        relacionadas e incorridas pela AZCEND.
      </p>

      <p>
        8.2 O CONTRATANTE compromete-se a manter em absoluto sigilo e
        confidencialidade todas as informa&ccedil;&otilde;es que lhe s&atilde;o
        disponibilizadas nos termos deste Contrato e utiliz&aacute;-las
        exclusivamente para as finalidades e servi&ccedil;os contratados,
        ficando vedada qualquer altera&ccedil;&atilde;o de sua forma ou
        subst&acirc;ncia.
      </p>

      <p>
        8.2.1 No caso de sua eventual viola&ccedil;&atilde;o ou
        divulga&ccedil;&atilde;o de informa&ccedil;&otilde;es confidenciais,
        inclusive por atos de seus funcion&aacute;rios ou terceiros, o
        CONTRATANTE ser&aacute; respons&aacute;vel pelo ressarcimento das perdas
        ocasionadas &agrave; AZCEND e a terceiros, incluindo danos emergentes,
        lucros cessantes, custas judiciais e honor&aacute;rios
        advocat&iacute;cios.
      </p>

      <p>
        8.3 O CONTRATANTE compromete-se a disponibilizar no rodap&eacute; do seu
        site ou link de venda do produto o seu CNPJ ou CPF/MF, dados de contato
        vis&iacute;veis (e-mail, telefone), prazo de entrega e pol&iacute;tica
        de troca e devolu&ccedil;&atilde;o, uma vez que estes s&atilde;o
        requisitos exigidos pelo Decreto n&ordm; 7.962/2013 (lei do e-commerce).
        A AZCEND se reserva ao direito de rescindir o Contrato caso o
        CONTRATANTE n&atilde;o esteja adequado a esses par&acirc;metros.
      </p>

      <p>
        8.4 O CONTRATANTE &eacute; o &uacute;nico e exclusivo respons&aacute;vel
        pela entrega, qualidade e correspond&ecirc;ncia dos produtos vendidos
        por ele, bem como adequa&ccedil;&atilde;o a todas as normas do
        C&oacute;digo de Defesa do Consumidor.
      </p>

      <p>
        8.4.1 Assim, a AZCEND n&atilde;o se responsabiliza quanto aos riscos,
        nocividade, periculosidade, defeitos, v&iacute;cios de qualidade,
        v&iacute;cios de quantidade, insufici&ecirc;ncia,
        inadequa&ccedil;&atilde;o de informa&ccedil;&otilde;es, publicidade
        enganosa e/ou abusiva, do produto e/ou servi&ccedil;o adquirido.
      </p>

      <p>
        8.4.2 Caso seja verificado que o conte&uacute;do do produto ou de sua
        p&aacute;gina de vendas viola direitos autorais, direitos de imagem,
        voz, intimidade ou de propriedade industrial (marca, patente, desenhos
        industriais), a AZCEND poder&aacute; suspender, bloquear e remover a
        Conta do CONTRATANTE.
      </p>

      <p>
        8.5 O CONTRATANTE &eacute; o &uacute;nico e exclusivo respons&aacute;vel
        pelo envio dos dados de acesso (login e senha) de produtos digitais
        (tais como v&iacute;deo aulas, cursos) aos Consumidores.
      </p>

      <p>
        8.6 O CONTRATANTE se obriga a ressarcir integralmente a AZCEND
        caso&nbsp;esta&nbsp;venha a arcar com qualquer tipo de pagamento
        efetuado em decorr&ecirc;ncia de condena&ccedil;&atilde;o judicial,
        administrativa ou acordo extrajudicial cujo objeto esteja relacionado
        com atividades do CONTRATANTE na Plataforma AZCEND, incluindo, sem
        limita&ccedil;&atilde;o, qualquer valor que venha a ser pago pela AZCEND
        a t&iacute;tulo de reembolso, indeniza&ccedil;&atilde;o, multa, juros e
        honor&aacute;rios advocat&iacute;cios.
      </p>

      <p>
        8.7 &Eacute; de exclusiva obriga&ccedil;&atilde;o do CONTRATANTE o
        recolhimento pontual de todo e qualquer imposto ou tributo devido sobre
        o valor auferido com a venda de produtos anunciados em seu website ou de
        seus estabelecimentos comerciais que utilizar&aacute; qualquer
        p&aacute;gina de pagamento hospedada para a realiza&ccedil;&atilde;o de
        transa&ccedil;&otilde;es, n&atilde;o havendo qualquer responsabilidade
        da AZCEND nesse sentido
      </p>

      <p>
        8.8 O CONTRATANTE dever&aacute; observar, respeitar e cumprir todas e
        quaisquer leis, regulamentos e instru&ccedil;&otilde;es
        aplic&aacute;veis &agrave;s suas atividades, incluindo, mas n&atilde;o
        se limitando a, as regras e exig&ecirc;ncias determinadas pelo Banco
        Central, pelas bandeiras de cart&otilde;es de cr&eacute;dito e/ou
        d&eacute;bito, pelo mercado de meios de pagamento, pelo sistema de
        pagamentos brasileiro e a Lei de Preven&ccedil;&atilde;o &agrave;
        Lavagem de Dinheiro. O CONTRATANTE compromete-se a n&atilde;o realizar
        opera&ccedil;&otilde;es il&iacute;citas, contr&aacute;rias &agrave;
        moral e aos bons costumes, ou que o CONTRATANTE saiba ou deva saber que
        s&atilde;o nulas ou anul&aacute;veis, tais como transa&ccedil;&otilde;es
        que:
      </p>

      <ul>
        <li>
          ● O CONTRATANTE seja impedido de celebrar devido a
          disposi&ccedil;&otilde;es legais, regulamentares, contratuais ou
          estatut&aacute;rias;
        </li>
        <li>
          ● O CONTRATANTE saiba ou deva saber que o Consumidor esteja impedido
          de celebrar devido a disposi&ccedil;&otilde;es legais, regulamentares,
          contratuais ou estatut&aacute;rias;
        </li>
        <li>
          ● Tenham como motivo qualquer objetivo il&iacute;cito ou
          contr&aacute;rio &agrave; moral e aos bons costumes, que violem ou
          tenham a inten&ccedil;&atilde;o de violar este Termo ou a
          Pol&iacute;tica de Privacidade, que tenham como objetivo fraudar
          qualquer lei ou direitos de terceiros, ou, ainda, constituam
          simula&ccedil;&atilde;o;
        </li>
        <li>
          ● O CONTRATANTE saiba ou deva saber serem nulas ou estarem maculadas
          de qualquer v&iacute;cio que as torne anul&aacute;veis.
        </li>
      </ul>

      <p>
        8.9 O CONTRATANTE compromete-se a cumprir todas as
        disposi&ccedil;&otilde;es legais e regulamentares aplic&aacute;veis
        &agrave; sua atividade, incluindo, sem limita&ccedil;&atilde;o, as
        disposi&ccedil;&otilde;es referentes &agrave; prote&ccedil;&atilde;o do
        Consumidor, inclusive em rela&ccedil;&atilde;o &agrave; oferta,
        publicidade e fornecimento de produtos oferecidos, assumindo integral
        responsabilidade pelos riscos, inclusive&nbsp;pela
        potencial&nbsp;nocividade ou periculosidade, defeitos, v&iacute;cios de
        qualidade ou de quantidade dos produtos ou servi&ccedil;os ofertados
        pelo CONTRATANTE. Entre outros atos que infringem as normas de
        prote&ccedil;&atilde;o ao Consumidor que o CONTRATANTE dever&aacute;
        respeitar, o CONTRATANTE expressamente se compromete a n&atilde;o
        praticar os seguintes atos:
      </p>

      <ul>
        <li>
          ● (i) prestar informa&ccedil;&otilde;es referentes aos produtos ou
          servi&ccedil;os ofertados que sejam insuficientes, imprecisas ou
          inadequadas;
        </li>
        <li>
          ● (ii) fazer ofertas cujas indica&ccedil;&otilde;es constantes em
          mensagens publicit&aacute;rias n&atilde;o estejam de acordo com as
          indica&ccedil;&otilde;es constantes dos recipientes ou embalagens dos
          produtos ou servi&ccedil;os ofertados;
        </li>
        <li>
          ● (iii) oferecer produtos ou servi&ccedil;os inadequados aos fins a
          que se destinam;
        </li>
        <li>
          ● (iv) realizar publicidade enganosa ou abusiva relativa aos produtos
          e servi&ccedil;os ofertados;
        </li>
        <li>
          ● (v) descumprir normas legais ou contratuais relativas &agrave;
          garantia dos produtos ou servi&ccedil;os ofertados;
        </li>
        <li>
          ● (vi) oferecer ou fazer propaganda de qualquer produto sem obter
          todas as licen&ccedil;as, autoriza&ccedil;&otilde;es ou
          permiss&otilde;es das autoridades competentes para a sua
          comercializa&ccedil;&atilde;o, incluindo, sem limita&ccedil;&atilde;o,
          qualquer registro ou autoriza&ccedil;&atilde;o necess&aacute;ria de
          autoridades sanit&aacute;rias;
        </li>
        <li>
          ● (vii) oferecer ou fazer propaganda de qualquer produto ou
          servi&ccedil;o il&iacute;cito ou que seja regulado por normas legais
          ou regulamentares que impe&ccedil;am ou restrinjam sua
          comercializa&ccedil;&atilde;o pelo CONTRATANTE, salvo se o CONTRATANTE
          obtiver todas as licen&ccedil;as, autoriza&ccedil;&otilde;es ou
          permiss&otilde;es pr&eacute;vias das autoridades competentes.
        </li>
        <li>
          (vii) oferecer ou fazer propaganda de qualquer produto ou
          servi&ccedil;o il&iacute;cito ou que seja regulado por normas legais
          ou regulamentares que impe&ccedil;am ou restrinjam sua
          comercializa&ccedil;&atilde;o pelo CONTRATANTE, salvo se o CONTRATANTE
          obtiver todas as licen&ccedil;as, autoriza&ccedil;&otilde;es ou
          permiss&otilde;es pr&eacute;vias das autoridades competentes.
        </li>
      </ul>

      <p>
        8.10 O CONTRATANTE se compromete a reparar a AZCEND por eventuais
        preju&iacute;zos decorrentes de multas e/ou penalidades aplicadas pelos
        agentes do mercado de cart&otilde;es de pagamento (credenciadoras,
        bandeiras de cart&otilde;es de cr&eacute;dito e/ou d&eacute;bito e
        emissores) ou por autoridades governamentais em virtude de atos ou fatos
        atribu&iacute;veis ao CONTRATANTE.
      </p>

      <p>
        8.11 O CONTRATANTE se obriga a enviar &agrave; AZCEND em at&eacute; 2
        (dois) dias &uacute;teis todos os c&oacute;digos de rastreio dos
        produtos f&iacute;sicos vendidos por meio da Plataforma AZCEND, quando
        solicitado, para que a AZCEND possa averiguar a legalidade da
        opera&ccedil;&atilde;o, podendo a Conta Virtual ficar bloqueada
        temporariamente durante esta an&aacute;lise.
      </p>

      <p>
        8.12 O CONTRATANTE dever&aacute; efetivar seu cadastro no&nbsp;site
        &rdquo;Reclame&nbsp;Aqui&ldquo; e no site Consumidor.gov.br em
        at&eacute; 48 (quarenta e oito) horas ap&oacute;s
        aprova&ccedil;&atilde;o do seu cadastro pela AZCEND, sob pena de
        bloqueio do Checkout de pagamento at&eacute; sua
        regulariza&ccedil;&atilde;o.
      </p>

      <p>
        <strong>9. REGRAS DE CONDUTA</strong>
      </p>

      <p>
        9.1 O CONTRATANTE se compromete a n&atilde;o utilizar a Plataforma
        AZCEND para a publica&ccedil;&atilde;o ou divulga&ccedil;&atilde;o de
        conte&uacute;do:
      </p>

      <ul>
        <li>
          ● que tenha car&aacute;ter discriminat&oacute;rio, obsceno, ofensivo,
          amea&ccedil;ador, abusivo, vexat&oacute;rio, prejudicial, ou que
          contenha express&otilde;es de &oacute;dio contra pessoas ou grupos,
          contenha insultos ou amea&ccedil;as religiosas ou raciais, ou que
          incentive danos morais e patrimoniais, ou que possa violar qualquer
          direito de terceiros;
        </li>
        <li>
          ● protegido por direitos autorais ou que, por qualquer raz&atilde;o,
          viole direitos de terceiros.
        </li>
      </ul>

      <p>
        9.2 Al&eacute;m disso, ficam expressamente proibidas as seguintes
        a&ccedil;&otilde;es:
      </p>

      <ul>
        <li>
          ● ceder, vender, alugar ou de qualquer forma transferir a Conta
          Virtual a terceiros;
        </li>
        <li>
          ● coletar dados e informa&ccedil;&otilde;es de terceiro
          Usu&aacute;rio, bem como divulgar qualquer informa&ccedil;&atilde;o de
          car&aacute;ter privado ou confidencial;
        </li>
        <li>
          ● usar a marca da AZCEND para publicidade ou credibilidade de projetos
          sem a expressa autoriza&ccedil;&atilde;o e pr&eacute;via
          autoriza&ccedil;&atilde;o da AZCEND, por escrito;
        </li>
        <li>
          ● usar a AZCEND para publicar ou transmitir v&iacute;rus,&nbsp;worm,
          Cavalo de Tr&oacute;ia,&nbsp;easter&nbsp;egg,
          time&nbsp;bomb,&nbsp;spyware&nbsp;ou outro c&oacute;digo, arquivo ou
          programa de computador malicioso que seja prejudicial ou invasivo, ou
          cuja inten&ccedil;&atilde;o seja danificar, tomar controle da
          opera&ccedil;&atilde;o ou monitorar o uso de qualquer hardware,
          software ou equipamento, seja nosso, dos Usu&aacute;rios, ou de
          terceiros, relacionados ou n&atilde;o com a AZCEND;
        </li>
        <li>
          ● fazer qualquer altera&ccedil;&atilde;o na Plataforma AZCEND ou seu
          conte&uacute;do, assim como, e n&atilde;o somente, modificar, adaptar,
          fazer engenharia reversa, abstendo-se de qualquer ato que possa
          prejudicar ou alterar a integridade ou a opera&ccedil;&atilde;o
          regular da Plataforma AZCEND e seus servidores, respondendo civil e
          criminalmente por todo ato ilegal a que der causa;
        </li>
        <li>
          ● criar banco de dados, transferir ou armazenar conte&uacute;do da
          Plataforma AZCEND;
        </li>
        <li>
          ● usar rob&ocirc;,&nbsp;spider&nbsp;ou qualquer tipo de aplicativo ou
          dispositivo, manual ou autom&aacute;tico, para recuperar ou indexar
          conte&uacute;do da Plataforma AZCEND;
        </li>
        <li>
          ● usar a AZCEND para encaminhar mensagens n&atilde;o solicitadas e/ou
          n&atilde;o autorizadas (&ldquo;Spam&rdquo;) aos demais Usu&aacute;rios
          e/ou a terceiros;
        </li>
        <li>
          ● usar a AZCEND para violar quaisquer direitos legais de terceiros, ou
          para obter ou coletar informa&ccedil;&otilde;es de
          identifica&ccedil;&atilde;o de Usu&aacute;rios da AZCEND;
        </li>
        <li>
          ● e utilizar qualquer forma que possa ser considerada fraudulenta para
          obter vantagem, para si ou para outrem.
        </li>
      </ul>

      <p>
        9.3 Sem preju&iacute;zo de qualquer obriga&ccedil;&atilde;o ou regra de
        conduta assumida pelo CONTRATANTE por meio deste Termo, o CONTRATANTE
        compromete-se a, durante toda a vig&ecirc;ncia deste Termo, a cumprir as
        seguintes regras:
      </p>

      <ul>
        <li>
          ● N&atilde;o possuir envolvimento, direta ou indiretamente, em
          atividades enganosas ou fraudulentas de qualquer natureza;
        </li>
        <li>
          ● N&atilde;o possuir envolvimento, direta ou indiretamente, em
          pr&aacute;ticas de marketing potencialmente enganosas ou abusivas;
        </li>
        <li>
          ● N&atilde;o possuir envolvimento em atividades que estejam
          relacionadas a lavagem de dinheiro ou resultem em lavagem de dinheiro;
        </li>
        <li>
          ● N&atilde;o possuir envolvimento em atividades que violem as regras
          das bandeiras de cart&otilde;es de cr&eacute;dito e/ou d&eacute;bito;
          e
        </li>
        <li>● Possuir CNPJ ou CPF/MF ativo.</li>
      </ul>

      <p>
        9.4 O CONTRATANTE n&atilde;o poder&aacute; utilizar a Plataforma da
        AZCEND para desenvolver atividades ou comercializar bens e/ou
        servi&ccedil;os n&atilde;o autorizados por lei ou em desacordo com este
        Termo,&nbsp;independente&nbsp;de sua licitude, entre eles, de forma
        exemplificativa, est&atilde;o:
      </p>

      <ul>
        <li>● Servi&ccedil;os de acompanhantes;</li>
        <li>● Casas de prostitui&ccedil;&atilde;o;</li>
        <li>● Partidos pol&iacute;ticos;</li>
        <li>
          ● Casas de jogos ilegais, incluindo casas online, sal&otilde;es de
          jogos de azar e competi&ccedil;&otilde;es esportivas fantasiosas com
          pr&ecirc;mios ou promessas de pr&ecirc;mios para os vencedores;
        </li>
        <li>● Com&eacute;rcio de pornografia infantil e bestialidades;</li>
        <li>● Farm&aacute;cias e drogarias online;</li>
        <li>
          ● Marketing direto, relacionados a clientes de tele - servi&ccedil;os
          Ativos (liga&ccedil;&otilde;es de sa&iacute;da e de entrada);
        </li>
        <li>● Servi&ccedil;os relacionados a preparativos de viagens;</li>
        <li>
          ● Apostas, incluindo bilhetes de loteria, fichas de cassino, apostas
          de corrida de cavalos dentro e fora das pistas de corridas;
        </li>
        <li>
          ● Oportunidades de neg&oacute;cio &ndash; comerciantes f&iacute;sicos
          ou online que possuam, em suas raz&otilde;es sociais ou nomes
          fantasia, incluindo, mas n&atilde;o se limitando &agrave;s palavras
          &ldquo;Google&rdquo;, &ldquo;vantagem(ns) f&aacute;cil(eis)&rdquo;,
          &ldquo;enriquecimento r&aacute;pido&rdquo;, &ldquo;trabalho a partir
          da resid&ecirc;ncia&rdquo;;
        </li>
        <li>
          ● Comerciantes de produtos contrabandeados ou falsificados, mesmo que
          anunciados como originais;
        </li>
        <li>
          ● Comerciantes, receptadores ou atravessadores de mercadoria roubada
          ou furtada;
        </li>
        <li>
          ● Esquemas de enriquecimento r&aacute;pido, incluindo, mas n&atilde;o
          se limitando aqueles comerciantes que tenham em suas raz&otilde;es
          sociais ou nomes fantasia, as palavras &ldquo;Dinheiro&rdquo;,
          &ldquo;Fortuna&rdquo;, &ldquo;Riquezas&rdquo;,
          &ldquo;Financiamento&rdquo; ou &ldquo;Lucros&rdquo;;
        </li>
        <li>
          ● Hor&oacute;scopo ou cartomantes, em lojas f&iacute;sicas ou online;
        </li>
        <li>
          ● Sal&otilde;es de massagem que n&atilde;o possuam licen&ccedil;a para
          operar;
        </li>
        <li>
          ● Com&eacute;rcio de entorpecentes il&iacute;citos de qualquer tipo,
          origem ou fim;
        </li>
        <li>
          ● Com&eacute;rcio de maconha em todas suas formas, ainda que anunciada
          para fins medicinais;
        </li>
        <li>
          ● Empresas de marketing multin&iacute;vel que se assemelham &agrave;
          estrutura de pir&acirc;mide, bem como quaisquer estruturas n&atilde;o
          permitidas pela legisla&ccedil;&atilde;o em vigor;
        </li>
        <li>
          ● Estabelecimentos Comerciais que se utilizem de trabalho escravo,
          m&atilde;o de obra infantil ou quaisquer outras pr&aacute;ticas de
          trabalho proibidas pela legisla&ccedil;&atilde;o brasileira;
        </li>
        <li>
          ● Estabelecimentos Comerciais que realizam atividades
          relacionadas&nbsp;&agrave;&nbsp;doa&ccedil;&otilde;es e/ou
          &ldquo;vaquinhas&rdquo; que envolvem montantes acima do limite
          determinado pela Contratada;
        </li>
        <li>
          ● Quaisquer outros estabelecimentos comerciais, comerciantes ou
          profissionais que n&atilde;o possuam os registros ou licen&ccedil;as
          necess&aacute;rias para operar em seu ramo de atua&ccedil;&atilde;o,
          incluindo, mas n&atilde;o se limitando a m&eacute;dicos, advogados,
          dentistas e taxistas;
        </li>
        <li>
          ● Estabelecimentos Comerciais que vendem suplementos proibidos pela
          Anvisa;
        </li>
        <li>● Ag&ecirc;ncias de cobran&ccedil;as;</li>
        <li>
          ● Estabelecimentos Comerciais que fornecem ou restituem aos
          Portadores, por qualquer motivo,&nbsp;quantias em dinheiro&nbsp;(moeda
          nacional ou estrangeira, cheques, ordens de pagamento ou
          t&iacute;tulos de cr&eacute;dito), fa&ccedil;am
          Transa&ccedil;&otilde;es simuladas ou capital de giro;
        </li>
        <li>● Tabacarias online;</li>
        <li>● Criptomoedas e moedas virtuais;</li>
        <li>● Venda de seguidores em redes sociais;</li>
        <li>●&nbsp;Cyberlocker;</li>
        <li>● Armas de fogo;</li>
        <li>● Conte&uacute;do adulto.</li>
      </ul>

      <p>
        9.5 A AZCEND poder&aacute;, a qualquer tempo e sem aviso, rescindir o
        presente contrato e bloquear, a seu crit&eacute;rio, a Conta Virtual de
        qualquer CONTRATANTE que venha a infringir a lei e/ou os termos deste
        instrumento, ou, ainda, que possua saldo devedor em sua Conta Virtual,
        impedindo-se, assim, toda e qualquer atividade na Plataforma AZCEND, sem
        qualquer direito a indeniza&ccedil;&atilde;o ou ressarcimento pela
        AZCEND, devendo arcar com todo e qualquer preju&iacute;zo que o bloqueio
        venha a causar a outro Usu&aacute;rio ou a terceiros.
      </p>

      <p>
        9.6 A AZCEND poder&aacute;, ainda, bloquear temporariamente qualquer
        Conta Virtual envolvida em atividades suspeitas ou atividades que
        representem risco para a AZCEND, para averigua&ccedil;&atilde;o e
        an&aacute;lise da legalidade da opera&ccedil;&atilde;o.
      </p>

      <p>9.6.1 Entende-se como atividades suspeitas ou que apresentam risco:</p>

      <ul>
        <li>● Conta Virtual com alto &iacute;ndice de&nbsp;Chargebacks;</li>
        <li>
          ● suspeita de n&atilde;o envio do produto aos Consumidores, ou envio
          de produtos distintos do que foram comprados, que pode se dar por meio
          da an&aacute;lise de reclama&ccedil;&otilde;es dos Consumidores no
          Reclame Aqui ou no canal pr&oacute;prio da AZCEND;
        </li>
        <li>
          ● opera&ccedil;&otilde;es sinalizadas pelo controle antifraude da
          AZCEND;
        </li>
        <li>● informar c&oacute;digo de rastreio falso;</li>
        <li>
          ● cadastro do produto como digital quando na realidade &eacute;
          produto f&iacute;sico; e quaisquer outros ind&iacute;cios apurados
          pela equipe de risco da AZCEND que representem suspeita de ilegalidade
          ou irregularidade na opera&ccedil;&atilde;o do CONTRATANTE.
        </li>
      </ul>

      <p>
        9.6.2 Atividades que representam risco para a AZCEND s&atilde;o
        entendidas como situa&ccedil;&otilde;es em que o saldo do CONTRATANTE
        possa ficar negativo na Conta Virtual ou situa&ccedil;&otilde;es que
        signifiquem deteriora&ccedil;&atilde;o da rela&ccedil;&atilde;o da
        AZCEND com as bandeiras de cart&otilde;es de cr&eacute;dito e/ou
        d&eacute;bito e Credenciadores.
      </p>

      <p>
        9.7 Caso a AZCEND entenda, a seu exclusivo crit&eacute;rio, haver
        qualquer ind&iacute;cio de ilicitude, fraude ou viola&ccedil;&atilde;o
        &agrave; Lei de Preven&ccedil;&atilde;o &agrave; Lavagem de Dinheiro em
        qualquer Transa&ccedil;&atilde;o ou atividade do CONTRATANTE em sua
        Conta Virtual, ou se houver den&uacute;ncia contra o CONTRATANTE, a
        AZCEND poder&aacute;, a seu exclusivo crit&eacute;rio e sem necessidade
        de pr&eacute;vio aviso, at&eacute; que se esclare&ccedil;a a
        situa&ccedil;&atilde;o, bloquear a Conta Virtual do CONTRATANTE.
      </p>

      <p>
        9.8 No caso de rescis&atilde;o deste instrumento e/ou bloqueio da Conta
        Virtual do CONTRATANTE por conduta ilegal ou que viole quaisquer
        disposi&ccedil;&otilde;es aqui estabelecidas, o saldo dispon&iacute;vel
        ao CONTRATANTE por ocasi&atilde;o do bloqueio poder&aacute; ficar retido
        ou ser consignado judicialmente para eventual repara&ccedil;&atilde;o de
        dano ou Reembolso aos Consumidores.
      </p>

      <p>
        <strong>10. ESTORNOS E DEVOLU&Ccedil;&Otilde;ES</strong>
      </p>

      <p>
        Qualquer estorno ou devolu&ccedil;&atilde;o de pagamento ser&aacute;
        debitado na conta AZCEND do CONTRATANTE.
      </p>

      <p>
        10.1 A AZCEND debitar&aacute; na conta AZCEND do CONTRATANTE o montante
        equivalente &agrave;s contesta&ccedil;&otilde;es de recebimento da
        mercadoria ou da presta&ccedil;&atilde;o do servi&ccedil;o contra o
        CONTRATANTE, ou ainda, em caso de desist&ecirc;ncia da compra no prazo
        legal considerando a legisla&ccedil;&atilde;o vigente para vendas no
        com&eacute;rcio eletr&ocirc;nico.
      </p>

      <p>
        10.2 A AZCEND estornar&aacute; ao sacado/pagador os pagamentos
        irregulares, ou seja, que possuem inconsist&ecirc;ncias de
        informa&ccedil;&otilde;es que constam no pedido, como exemplo:
      </p>

      <ul>
        <li>
          ● (i) Pagamento menor do que o valor original &ndash; O pedido
          n&atilde;o ser&aacute; aprovado e o valor pago ser&aacute; estornado
          ao sacado/pagador;
        </li>
        <li>
          ● (ii) Pagamento maior do que o valor original &ndash; O pedido
          ser&aacute; aprovado e o excedente ao valor do pedido ser&aacute;
          estornado ao sacado/pagador;
        </li>
        <li>
          ● (iii) Pagamento duplicado &ndash; O pedido ser&aacute; aprovado e o
          valor pago em duplicidade ser&aacute; estornado ao sacado/pagador;
        </li>
      </ul>

      <p>
        10.3 As tarifas que envolvem a opera&ccedil;&atilde;o de estorno ao
        sacado/pagador ser&atilde;o pagas pelo CONTRATANTE de forma &agrave;
        vista, debitado no saldo da conta AZCEND do CONTRATANTE.
      </p>

      <p>
        <strong>11. PROPRIEDADE INTELECTUAL</strong>
      </p>

      <p>
        O CONTRATANTE se compromete a n&atilde;o infringir quaisquer direitos
        relativos a marcas, patentes ou, ainda, direito de propriedade, de
        representa&ccedil;&atilde;o e/ou autoral, responsabilizando-se perante a
        AZCEND e terceiros interessados pela obriga&ccedil;&atilde;o assumida
        neste item, obrigando-se a arcar integralmente por todo &ocirc;nus
        gerado pelo eventual descumprimento deste item, mesmo que ocorra de
        forma n&atilde;o intencional.
      </p>

      <p>
        <strong>12. RESPONSABILIDADE DO CONTRATO</strong>
      </p>

      <p>
        O CONTRATANTE &eacute; respons&aacute;vel pelo uso das ferramentas
        AZCEND, nos termos deste contrato, comprometendo-se a observar e cumprir
        integralmente a legisla&ccedil;&atilde;o nacional aplic&aacute;vel, as
        normas e pol&iacute;ticas de uso, seguran&ccedil;a e privacidade da
        AZCEND e de seus parceiros comerciais.
      </p>

      <p>
        12.1 O CONTRATANTE &eacute; respons&aacute;vel pela entrega do produto
        e/ou servi&ccedil;o referente &agrave; opera&ccedil;&atilde;o
        financeira, possibilitando o perfeito estado de uso e/ou o deleite do
        sacado/pagador.
      </p>

      <p>
        12.2 O CONTRATANTE dever&aacute; manter no m&iacute;nimo um
        endere&ccedil;o de e-mail e um n&uacute;mero de telefone, ambos ativos,
        para manter um canal de comunica&ccedil;&atilde;o com a AZCEND,
        assegurando assim os prazos operacionais estabelecidos por este
        documento&nbsp;e tamb&eacute;m&nbsp;a sua perman&ecirc;ncia no uso dos
        servi&ccedil;os.
      </p>

      <p>
        12.3 O uso da ferramenta AZCEND atrav&eacute;s de sistemas de terceiros
        &eacute; de inteira responsabilidade do CONTRATANTE e do seu fornecedor.
      </p>

      <p>
        12.4 O sacado/pagador ou pagador da cobran&ccedil;a gerada na AZCEND,
        que por sua vez &eacute; cliente do CONTRATANTE, se tornar&aacute;
        tamb&eacute;m cliente da AZCEND no ato da emiss&atilde;o da
        cobran&ccedil;a. Desta forma, entendendo que o cliente do CONTRATANTE
        tamb&eacute;m &eacute; cliente da AZCEND, fica estabelecido que a AZCEND
        poder&aacute; requerer a qualquer tempo, dados pessoais do
        sacado/pagador como: notas fiscais, comprovante de entrega do
        produto/servi&ccedil;o, ou ainda, qualquer outro documento ou
        comprova&ccedil;&atilde;o referente &agrave; opera&ccedil;&atilde;o de
        venda, ficando o CONTRATANTE ciente da obrigatoriedade do fornecimento
        integral de tais informa&ccedil;&otilde;es.
      </p>

      <p>
        12.5 A AZCEND manter&aacute; suporte t&eacute;cnico e operacional ao
        servi&ccedil;o, realizado diretamente pela AZCEND ou por terceiro por
        ele contratado, sob sua responsabilidade. O suporte ser&aacute; prestado
        das 9:00h &agrave;s 18:00h em dias &uacute;teis, via chat ou
        atrav&eacute;s de correio eletr&ocirc;nico (help@azcend.com.br). Para
        esclarecimento de d&uacute;vidas de ordem n&atilde;o funcional e
        problemas no servi&ccedil;o, o CONTRATANTE poder&aacute; usar o contato
        telef&ocirc;nico, devendo este assumir integralmente eventuais custos de
        liga&ccedil;&otilde;es locais e interurbanas. O servi&ccedil;o de
        suporte prestado via e-mail ou salas de bate-papo ser&atilde;o
        gratuitos.
      </p>

      <p>
        12.6 O CONTRATANTE se declara ciente de que as solu&ccedil;&otilde;es de
        pagamentos da AZCEND se destinam t&atilde;o somente a efetivar
        pagamentos e recebimentos em moeda corrente nacional, bem como assegura
        que todos os recursos creditados na conta AZCEND s&atilde;o oriundos de
        fontes l&iacute;citas e s&atilde;o declarados &agrave;s autoridades
        competentes.
      </p>

      <p>
        12.7 O CONTRATANTE &eacute; integralmente respons&aacute;vel pelo seu
        login e senha, os quais deve manter em sigilo.
      </p>

      <p>
        12.8 O CONTRATANTE se compromete a instalar e manter atualizados
        softwares&nbsp;anti-spywares, antiv&iacute;rus e outros que objetivem
        evitar a viola&ccedil;&atilde;o do computador que acessa o software de
        solu&ccedil;&atilde;o de pagamentos da AZCEND.
      </p>

      <p>
        <strong>13. SEGURAN&Ccedil;A</strong>
      </p>

      <p>
        A AZCEND monitora constantemente os clientes e transa&ccedil;&otilde;es
        e pode, a qualquer tempo, manifestar n&atilde;o interesse na
        presta&ccedil;&atilde;o do servi&ccedil;o, por entender que o
        CONTRATANTE ou transa&ccedil;&otilde;es n&atilde;o atendem ao perfil de
        seus neg&oacute;cios. Nesta hip&oacute;tese, o CONTRATANTE ser&aacute;
        impedido de efetuar novas cobran&ccedil;as, sendo que os demais
        servi&ccedil;os ser&atilde;o processados normalmente, incluindo
        transfer&ecirc;ncias e compensa&ccedil;&atilde;o das cobran&ccedil;as
        j&aacute; emitidas.
      </p>

      <p>
        13.1 A AZCEND se reserva no direito de validar transa&ccedil;&otilde;es
        do CONTRATANTE junto a seus clientes, com expressa ressalva que em caso
        de dificuldades de contato com os clientes do CONTRATANTE, a(s)
        transa&ccedil;&atilde;o(&otilde;es) n&atilde;o ser&aacute;(&atilde;o)
        efetivada(s) at&eacute; que a AZCEND comprove sua legitimidade. O
        CONTRATANTE desobriga a AZCEND de qualquer dano, preju&iacute;zo, multa,
        juros desencadeados pelo atraso em pagamentos e/ou repasses.
      </p>

      <p>
        13.2 Em casos em que n&atilde;o seja transparente a legitimidade da
        transa&ccedil;&atilde;o e a AZCEND n&atilde;o tenha seguran&ccedil;a
        suficiente para efetivar ou desfazer a transa&ccedil;&atilde;o, valores
        custodiados pela AZCEND ser&atilde;o bloqueados, e depositados em
        ju&iacute;zo a disposi&ccedil;&atilde;o do CONTRATANTE, visto que a
        AZCEND n&atilde;o possui elementos comprobat&oacute;rios suficientes que
        promovam o encerramento da quest&atilde;o.
      </p>

      <p>
        13.3 A AZCEND se reserva o direito de eleger as transa&ccedil;&otilde;es
        que ir&aacute; intermediar, independente do crit&eacute;rio de
        legitimidade ou ilegitimidade da transa&ccedil;&atilde;o. Neste caso, os
        valores ser&atilde;o devolvidos integralmente ao sacado/pagador, cliente
        do CONTRATANTE.
      </p>

      <p>
        13.4 Se a AZCEND constatar a exist&ecirc;ncia de dados cadastrais
        incorretos relacionados ao CONTRATANTE e este se recusar a enviar os
        documentos solicitados para a solu&ccedil;&atilde;o da
        diverg&ecirc;ncia, a conta AZCEND do CONTRATANTE pode ser bloqueada,
        n&atilde;o assistindo ao CONTRATANTE direito de
        indeniza&ccedil;&atilde;o.
      </p>

      <p>
        13.5 A AZCEND pode reter todo e qualquer valor que o
        CONTRATANTE&nbsp;tiver&nbsp;a receber se, a ju&iacute;zo da AZCEND,
        houver alto n&iacute;vel de risco operacional ou de cr&eacute;dito
        associado ao desempenho do CONTRATANTE, &agrave; sua conta AZCEND ou a
        qualquer das transa&ccedil;&otilde;es relacionadas a este ou ao(s)
        outro(s) recebedor(es).
      </p>

      <p>
        13.6 N&atilde;o &eacute; permitido o acesso &agrave;s &aacute;reas de
        programa&ccedil;&atilde;o da AZCEND, seu banco de dados ou qualquer
        outro conjunto de informa&ccedil;&otilde;es que fa&ccedil;a parte da
        atividade de&nbsp;Webmastering.
      </p>

      <p>
        13.7 N&atilde;o &eacute; autorizado ao sacado/pagador ou CONTRATANTE
        realizar ou permitir engenharia reversa, nem traduzir,&nbsp;descompilar,
        copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar,
        transmitir, emprestar, distribuir ou, de outra maneira, dispor das
        ferramentas da AZCEND e de suas funcionalidades.
      </p>

      <p>
        13.8 Na AZCEND &eacute; proibida a utiliza&ccedil;&atilde;o de
        softwares&nbsp;spider, ou de minera&ccedil;&atilde;o de dados, de
        qualquer tipo ou esp&eacute;cie, al&eacute;m de outro aqui n&atilde;o
        tipificado, mas que atue de modo automatizado, tanto para realizar
        opera&ccedil;&otilde;es massificadas ou para quaisquer outras
        finalidades.
      </p>

      <p>
        <strong>14. VIG&Ecirc;NCIA E RESCIS&Atilde;O</strong>
      </p>

      <p>
        Este contrato possui validade por prazo indeterminado, entrando em vigor
        na data do aceite atrav&eacute;s do portal online www.AZCEND.net ou na
        data de in&iacute;cio da utiliza&ccedil;&atilde;o das ferramentas da
        AZCEND pelo CONTRATANTE, o que for mais recente.
      </p>

      <p>
        14.1 Este contrato poder&aacute; ser encerrado pela AZCEND, sem nenhum
        &ocirc;nus e a qualquer momento, mediante devida
        notifica&ccedil;&atilde;o enviada ao CONTRATANTE atrav&eacute;s das
        ferramentas dispon&iacute;veis no portal online www.AZCEND.net e/ou os
        meios de contato fornecidos pelo CONTRATANTE.
      </p>

      <p>
        14.2 O encerramento deste contrato poder&aacute; ocorrer de forma
        imediata e sem aviso pr&eacute;vio, caso seja identificado o
        descumprimento do CONTRATANTE a qualquer item ou subitem previsto neste
        documento.
      </p>

      <p>
        14.2.1 A conta do CONTRATANTE ser&aacute; encerrada no caso de
        inatividade pelo per&iacute;odo de 12 (doze) meses consecutivos.
        Compreende-se como inatividade a conta n&atilde;o movimentada pelo
        usu&aacute;rio.
      </p>

      <p>
        14.3 O CONTRATANTE poder&aacute; encerrar o contrato mediante
        notifica&ccedil;&atilde;o enviada &agrave; AZCEND por escrito,
        via&nbsp;carta-postal&nbsp;para o endere&ccedil;o da AZCEND ou e-mail,
        num prazo de 30 (trinta) dias de anteced&ecirc;ncia.
      </p>

      <p>
        14.4 Ap&oacute;s receber o pedido de cancelamento da conta por parte do
        CONTRATANTE poder&aacute; ser adotado o seguinte procedimento:
      </p>

      <ul>
        <li>
          ● (i) A AZCEND ir&aacute; encaminhar documento com os termos de
          encerramento de conta para o endere&ccedil;o ou e-mail do solicitante;
        </li>
        <li>
          ● (ii) O titular da conta AZCEND dever&aacute; assinar o termo de
          encerramento e em seguida reconhecer como verdadeiro em
          cart&oacute;rio de sua regi&atilde;o com compet&ecirc;ncia para tal;
        </li>
        <li>
          ● (iii) Encaminhar via&nbsp;carta-postal&nbsp;ao endere&ccedil;o da
          AZCEND;
        </li>
        <li>
          ● (iv) Ap&oacute;s recebimento, a AZCEND analisar&aacute; a
          autenticidade, e se houver parecer positivo prosseguir&aacute; com o
          cancelamento;
        </li>
      </ul>

      <p>
        14.5 Os eventuais custos que envolvem os itens 10.3 e 10.4 deste
        documento, dever&atilde;o ser custeados integralmente pelo CONTRATANTE
        solicitante, n&atilde;o pass&iacute;veis de reembolso.
      </p>

      <p>
        14.6 Em caso de rescis&atilde;o por qualquer uma das partes e por
        qualquer motivo, o CONTRATANTE declara-se ciente de que, caso haja
        d&eacute;bitos gerados pelo uso das ferramentas AZCEND ou pelo
        descumprimento dos itens deste documento, em favor da pr&oacute;pria
        AZCEND ou a terceiros, dever&atilde;o ser pagos.
      </p>

      <p>
        14.7 Os d&eacute;bitos citados no item anterior, caso existam,
        ser&atilde;o pagos em primeiro momento com as verbas dispon&iacute;veis
        do pr&oacute;prio CONTRATANTE no sistema AZCEND, e caso os valores
        n&atilde;o sejam suficientes o CONTRATANTE dever&aacute; transferir
        &agrave; AZCEND os recursos faltantes no prazo m&aacute;ximo de 10 (dez)
        dias corridos ap&oacute;s a comunica&ccedil;&atilde;o de
        rescis&atilde;o. Caso n&atilde;o haja d&eacute;bitos pendentes no ato da
        rescis&atilde;o, os recursos dispon&iacute;veis do CONTRATANTE
        ser&atilde;o transferidos para sua conta banc&aacute;ria registrada do
        sistema.
      </p>

      <p>
        14.8 O presente contrato ser&aacute; tamb&eacute;m rescindido de pleno
        direito, independentemente de qualquer notifica&ccedil;&atilde;o ou
        interpela&ccedil;&atilde;o, judicial ou extrajudicial, no caso de o
        CONTRATANTE, de qualquer forma, comprometer a imagem p&uacute;blica da
        AZCEND e/ou de qualquer empresa associada &agrave; AZCEND na
        presta&ccedil;&atilde;o do servi&ccedil;o.
      </p>

      <p>
        <strong>15. LIMITA&Ccedil;&Otilde;ES</strong>
      </p>

      <p>
        Na extens&atilde;o m&aacute;xima permitida pela lei em vigor, o
        servi&ccedil;o &eacute; fornecido &ldquo;no estado em que se
        encontra&rdquo; e &ldquo;conforme a disponibilidade&rdquo;, com todas as
        falhas e sem garantia de qualquer esp&eacute;cie.
      </p>

      <p>
        15.1 A AZCEND n&atilde;o garante que as fun&ccedil;&otilde;es contidas
        no servi&ccedil;o atendam &agrave;s necessidades do CONTRATANTE, que a
        opera&ccedil;&atilde;o do servi&ccedil;o ser&aacute; ininterrupta ou
        livre de erros, que qualquer servi&ccedil;o continuar&aacute;
        dispon&iacute;vel, que os defeitos no servi&ccedil;o ser&atilde;o
        corrigidos ou que o servi&ccedil;o ser&aacute; compat&iacute;vel ou
        funcione com qualquer servi&ccedil;o, aplica&ccedil;&otilde;es ou
        servi&ccedil;os de terceiros.
      </p>

      <p>
        <strong>16. REVIS&Atilde;O</strong>
      </p>

      <p>
        A AZCEND revisar&aacute; periodicamente este contrato e poder&aacute;
        efetuar adequa&ccedil;&otilde;es sobre a presta&ccedil;&atilde;o dos
        servi&ccedil;os e o uso das ferramentas disponibilizadas para este fim,
        por esse motivo consulte-o com certa frequ&ecirc;ncia, no
        endere&ccedil;o eletr&ocirc;nico
        https://azcend.com.br/index.php/politica-de-privacidade/.
      </p>
      <p>
        16.1 Em caso de altera&ccedil;&atilde;o neste documento a AZCEND
        notificar&aacute; o CONTRATANTE atrav&eacute;s das ferramentas
        dispon&iacute;veis no portal online www.AZCEND.net e/ou os meios de
        contato fornecidos pelo CONTRATANTE, passando a vigorar imediatamente.
      </p>

      <p>
        16.2 Caso o CONTRATANTE esteja em desacordo com a mudan&ccedil;a no
        contrato poder&aacute; encerr&aacute;-lo observando o item 14 (quatorze)
        deste documento e seus subitens.
      </p>

      <p>
        16.3 Em caso de mudan&ccedil;a no contrato, a continuidade do uso das
        ferramentas da AZCEND ser&aacute;&nbsp;entendido&nbsp;como aceite aos
        termos das atualiza&ccedil;&otilde;es, as quais ser&atilde;o
        integralmente aplic&aacute;veis.
      </p>

      <p>
        <strong>17. POL&Iacute;TICA DE PRIVACIDADE</strong>
      </p>

      <p>
        N&oacute;s reconhecemos, nos termos da Pol&iacute;tica de Privacidade e
        da legisla&ccedil;&atilde;o aplic&aacute;vel, a confidencialidade e
        seguran&ccedil;a das informa&ccedil;&otilde;es prestadas pelos
        CONTRATANTES, empenhando-se em resguardar o seu sigilo, salvo por
        for&ccedil;a de lei ou ordem judicial.
      </p>

      <p>
        <strong>18. TRATAMENTO E PROTE&Ccedil;&Atilde;O DE DADOS</strong>
      </p>

      <p>
        Nos termos da Lei Geral de Prote&ccedil;&atilde;o de Dados (Lei n&ordm;
        13.709/18), o CONTRATANTE reconhece que a AZCEND realiza o tratamento de
        dados pessoais com finalidades espec&iacute;ficas, tais como: para o
        devido cumprimento das obriga&ccedil;&otilde;es legais e
        regulat&oacute;rias, para o exerc&iacute;cio regular de direitos e para
        a prote&ccedil;&atilde;o do cr&eacute;dito, bem como sempre que
        necess&aacute;rio para a execu&ccedil;&atilde;o do contrato firmado com
        seus clientes ou para atender aos interesses leg&iacute;timos da AZCEND,
        clientes ou de terceiros. Para qualquer outra finalidade, para a qual a
        lei n&atilde;o dispense a exig&ecirc;ncia do consentimento do
        CONTRATANTE, o tratamento estar&aacute; condicionado &agrave;
        manifesta&ccedil;&atilde;o livre, informada e inequ&iacute;voca do
        titular concordando com o tratamento de seus dados pessoais para aquela
        determinada finalidade.
      </p>

      <p>
        18.1 O CONTRATANTE expressamente autoriza que as
        informa&ccedil;&otilde;es cadastrais fornecidas, tais como nome, CPF,
        CNPJ, endere&ccedil;o, e-mail, telefone, sejam mantidas pela AZCEND, bem
        como autoriza que tais informa&ccedil;&otilde;es possam ser
        eventualmente compartilhadas com: (i) &agrave;s autoridades
        p&uacute;blicas competentes que as solicitarem formalmente, nos termos
        da legisla&ccedil;&atilde;o brasileira, em especial a Lei n&ordm;
        12.965/2014 (&ldquo;Marco Civil da Internet&rdquo;); (ii) a parceiros
        estrat&eacute;gicos, comerciais ou t&eacute;cnicos das empresas para a
        presta&ccedil;&atilde;o dos Servi&ccedil;os; e (iii) as partes
        envolvidas em uma mesma negocia&ccedil;&atilde;o, como, por exemplo,
        para um cliente envolvido em uma transa&ccedil;&atilde;o objeto de
        disputa.
      </p>

      <p>
        18.2 A AZCEND poder&aacute; compartilhar seus dados pessoais
        estritamente necess&aacute;rios para as respectivas finalidades
        espec&iacute;ficas, com fornecedores e prestadores de servi&ccedil;os,
        incluindo empresas de telemarketing, de processamento de dados, de
        tecnologia voltada &agrave; preven&ccedil;&atilde;o a fraudes,
        correspondentes banc&aacute;rios, bandeiras, credenciadoras e empresas
        ou escrit&oacute;rios especializados em cobran&ccedil;a de
        d&iacute;vidas.
      </p>

      <p>
        18.3 A AZCEND poder&aacute; fornecer seus dados pessoais sempre que
        estiver obrigado, seja em virtude de disposi&ccedil;&atilde;o legal, ato
        de autoridade competente ou ordem judicial.
      </p>

      <p>
        18.4 Mesmo ap&oacute;s o t&eacute;rmino deste contrato, os dados
        pessoais e outras informa&ccedil;&otilde;es a ele relacionadas
        poder&atilde;o ser conservados pela AZCEND para cumprimento de
        obriga&ccedil;&otilde;es legais e regulat&oacute;rias pelos prazos
        previstos na legisla&ccedil;&atilde;o vigente.
      </p>

      <p>
        <strong>19. FORO</strong>
      </p>

      <p>
        O presente documento ser&aacute; interpretado segundo a
        legisla&ccedil;&atilde;o brasileira, no idioma portugu&ecirc;s, sendo
        eleito o Foro da comarca de S&atilde;o Paulo/SP, local do
        escrit&oacute;rio administrativo da AZCEND, para dirimir toda e qualquer
        quest&atilde;o decorrente do presente instrumento, renunciando
        expressamente a qualquer outro, por mais privilegiado que seja.
      </p>

      <p>S&atilde;o Paulo, 11 de maio de 2023.</p>
    </Container>
  );
};

export default UsePrivacyTermsText;
