/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { Container } from './styles';
import Loader from '../Loader';
import Badge from '../Badge';
import Button from '../Button';
import api from '../../services/api';
import * as color from '../../styles/colors';

interface UploadHistoryProps {
  endpoint: string;
}

type FormData = {
  id_code: string;
  refused_reason: string;
  status: string;
  type: number;
  document_url: string;
  date: string;
  type_str: string;
};

const UploadTable: React.FC<UploadHistoryProps> = ({ endpoint }) => {
  const [documentList, setDocumentList] = useState<[]>([]);
  const [loading, setLoading] = useState<number>(0);
  const [noListMessage, setNoListMessage] = useState<string>('');
  const [refuseReason, setRefuseReason] = useState<string>('');

  const getUserDocuments = useCallback(async () => {
    try {
      setLoading(1);
      const res = await api.get(endpoint);
      const { data } = res;
      const exist = data.find(
        (refuse: FormData) => refuse.status == 'Recusado',
      );
      exist && setRefuseReason('Motivo');

      setDocumentList(data);
      setLoading(0);
      setNoListMessage('Nenhum arquivo foi enviado no momento');
    } catch (error) {
      setLoading(0);
    }
  }, [endpoint]);

  useEffect(() => {
    getUserDocuments();
  }, [getUserDocuments]);

  return (
    <Container>
      <Loader loading={loading} />
      {documentList.length ? (
        <table>
          <thead>
            <tr>
              <th>Data envio</th>
              <th>Status</th>
              <th>{refuseReason}</th>
              <th>Arquivo</th>
            </tr>
          </thead>
          <tbody>
            {documentList.map((d: FormData) => (
              <tr key={d.id_code}>
                <td>{d.date}</td>
                <td>
                  <Badge status={d.status} className="badge" />
                </td>
                {d.status === 'Recusado' ? (
                  <td>{d.refused_reason ? d.refused_reason : ''}</td>
                ) : (
                  <td></td>
                )}
                <td>
                  <Button
                    type="button"
                    transparent
                    onClick={() => window.open(d.document_url)}
                    className="eye-button"
                  >
                    <FiEye color={color.darkgrey} size={24} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-file-message">
          <h1>{noListMessage}</h1>
        </div>
      )}
    </Container>
  );
};

export default UploadTable;
