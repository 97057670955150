import React from 'react';
import { DotLoading } from './styles';

interface DotProps {
  color?: string;  
}

const Dot3Loading: React.FC<DotProps> = ({
  color
}) => {
  return (
    <DotLoading className='lds-ellipsis' color={color}>
        <div></div><div></div><div></div><div></div>
    </DotLoading>
  );
};

export default Dot3Loading;
