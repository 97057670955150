import styled, { css } from "styled-components";

interface BadgeProps {
    status?: string;
    pulse?: boolean;
}

export const Container = styled.div<BadgeProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;

    .circle {
        width: 15px;
        height: 15px;
        background-color: #62bd19;
        border-radius: 50%;
        position: absolute;
    }

    .ringring {
        background: #f6f7fa;
        border-radius: 30px;
        height: 25px;
        width: 25px;
        position: absolute;

        ${({ pulse }) =>
            pulse === true &&
            css`
                border: 3px solid #62bd19;
                animation: pulsate 1s ease-out;
                animation-iteration-count: infinite;
                opacity: 0;
                background: none;
            `}
    }

    @keyframes pulsate {
        0% {
            transform: scale(0.1, 0.1);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            transform: scale(1.2, 1.2);
            opacity: 0;
        }
    }

    ${({ status }) =>
        (status === "Pendente" || status === "Recusado") &&
        css`
            .circle {
                background: #fc0134;
            }

            .ringring {
                border: 3px solid #fc0134;
            }
        `}

    ${({ status }) =>
        status === "Aprovado" &&
        css`
            .circle {
                background: #00be9b;
            }

            .ringring {
                border: 3px solid #00be9b;
            }
        `}

  ${({ status }) =>
        status === "Em análise" &&
        css`
            .circle {
                background: #fcaf01;
            }

            .ringring {
                border: 3px solid #fcaf01;
            }
        `}

  ${({ status }) =>
        status === "Atendimento" &&
        css`
            .circle {
                background: #428bca;
            }

            .ringring {
                border: 3px solid #428bca;
            }
        `}

  ${({ status }) =>
        status === "Administrativo" &&
        css`
            .circle {
                background: #ac5bde;
            }

            .ringring {
                border: 3px solid #ac5bde;
            }
        `}
  ${({ status }) =>
        status === "Financeiro" &&
        css`
            .circle {
                background: #67bfa9;
            }

            .ringring {
                border: 3px solid #67bfa9;
            }
        `}
  ${({ status }) =>
        status === "Personalizado" &&
        css`
            .circle {
                background: #0fbf7f;
            }

            .ringring {
                border: 3px solid #0fbf7f;
            }
        `}
    
    ${({ status }) =>
        status === "Ativo" &&
        css`
            .circle {
                background: #10cf5c;
            }

            .ringring {
                border: 3px solid #10cf5c;
            }
        `}
    
    ${({ status }) =>
        status === "Desativada" &&
        css`
            .circle {
                background: #c3c6d1;
            }

            .ringring {
                border: 3px solid #c3c6d1;
            }
        `}

    ${({ status }) =>
        status === "Validando" &&
        css`
            .circle {
                background: #0fbf7f;
            }

            .ringring {
                border: 3px solid #0fbf7f;
            }
        `}

    ${({ status }) =>
        status === "Inválida" &&
        css`
            .circle {
                background: #ff0000;
            }

            .ringring {
                border: 3px solid #ff0000;
            }
        `}

    ${({ pulse }) =>
        pulse === false &&
        css`
            .ringring {
                border: none;
            }
        `}
`;
