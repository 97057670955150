/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useState, useEffect } from 'react';
import { Container, Row, Avatar, InfoVerifyAccount } from './styles';
import SmallCard from '../../../components/SmallCard';
import PageTopbar from '../../../components/PageTopbar';
import Loader from '../../../components/Loader';
import nexusLogo from '../../../assets/logos/2022/azcend-full.png';
import suiteCase from '../../../assets/suitcase.svg';
import api from '../../../services/api';
import { AccountsContext as Context } from '../../../context/AccountsContext';
import { Link } from 'react-router-dom';
import useToggle from '../../../hooks/useToggle';

const noAvatarImage =
  'https://cloudfox-documents.s3.amazonaws.com/cloudfox/defaults/user-default.png';

const Home: React.FC = () => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState<number>(0);
  const [isVerify, setIsVerify] = useToggle(false);
  const [showAlertVerifyAccount, setShowAlertVerifyAccount] = useState(false);
  const [hashCompany, setHashCompany] = useState<string>('');

  useEffect(() => {
    verifyBankAccountActive();
  }, []);

  async function verifyBankAccountActive() {
    try {
      setLoading(1);
      setIsVerify();
      const response = await api.get('/bank-accounts/exist-active-account');
      setLoading(0);
      setIsVerify();

      if (response.data) {
        setShowAlertVerifyAccount(!response.data.status);
        setHashCompany(response.data.hashCompany);
      }
    } catch (error) {
      setLoading(0);
    }
  }

  async function handleLoginAdmin() {
    try {
      setLoading(1);
      const response = await api.post('/send-authenticated');
      window.location.href = response.data.data.url;
      // setLoading(0);
    } catch (error) {
      setLoading(0);
    }
  }

  function capitalizeFirstLetter(string?: string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return ' ';
  }

  const imageError = useCallback(e => {
    e.target.src = noAvatarImage;
  }, []);

  return (
    <>
      <Loader
        loading={loading}
        loadingText={isVerify ? '' : 'Aguarde, estamos redirecionando'}
      />

      <Container>
        <PageTopbar
          title={`Bem vindo(a),  ${capitalizeFirstLetter(
            user?.name.split(' ')[0]
          )}`}
          subTitle="Gerencie suas informações, privacidade e segurança dos seus produtos Azcend.">
          <Avatar className="avatar">
            <img
              className="photo"
              src={user?.photo ? user?.photo : noAvatarImage}
              onError={imageError}
              alt=""
            />
          </Avatar>
        </PageTopbar>
        <Row>
          <SmallCard
            className="service-card"
            footerText="Acessar"
            contenText="Gateway de pagamentos"
            footerOnClick={handleLoginAdmin}
            cardOnClick={handleLoginAdmin}>
            <h3>ADMIN</h3>
            <img src={nexusLogo} alt="" width={200} />
          </SmallCard>
        </Row>

        {showAlertVerifyAccount && (
          <Row>
            {/* <InfoVerifyAccount>
                            <img src={suiteCase} alt="" />
                            <div className="content">
                                <strong>Você ainda não confirmou nenhum meio de recebimento. </strong>
                                <br /> <span>Configure uma conta para poder realizar saques.</span>
                            </div>
                            <Link
                                to={hashCompany ? `/companies/company-detail/${hashCompany}` : "/companies"}
                                className="btn"
                            >
                                <p>Verificar conta</p>
                            </Link>
                        </InfoVerifyAccount> */}
          </Row>
        )}
      </Container>
    </>
  );
};

export default Home;
