import React from 'react';
import { Container } from './styles';
import success from '../../assets/success-finish-icon.svg'
import error from '../../assets/error-finish-icon.svg'


interface FininishComponentProps {
  status: string,
  redirectLink?: string,
  title: string,
  description: string,
  buttonText: string,
  buttonClick?: (e:any)=>void;
}

const FininishComponent: React.FC<FininishComponentProps> = ({
  status,
  redirectLink,
  title,
  description,
  buttonText,
  buttonClick,
}) => {

  return (
      <Container status={status}>
        {status == 'success' && (
          <img src={success} style={{width: '158px',  height:"144" }}alt="" />
        )}

        {status == 'error' && (
          <img src={error} style={{width: '158px',  height:"144" }}alt="" />
        )}

        <h1>{title}</h1>

        <p>{description}</p>

        <a href={redirectLink} rel="noreferrer" onClick={buttonClick}>
          {buttonText}
        </a>

      </Container>
  );
};

export default FininishComponent;
