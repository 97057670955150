import styled, { css } from "styled-components";

interface BadgeProps {
    status?: string;
}

export const Container = styled.div<BadgeProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 23px;
    width: fit-content;
    min-width: 90px;
    white-space: pre;
    padding: 2px 8px;
    border-radius: 5px;

    ${({ status }) =>
        status === "Recusado" &&
        css`
            border: 1px solid #fc0134;
            background: #ffe8ec;
            color: #fc0134;
        `}

    ${({ status }) =>
        status === "Aprovado" &&
        css`
            border: 1px solid #00be9b;
            background: #d9f2ec;
            color: #00be9b;
        `}

  ${({ status }) =>
        (status === "Pendente" || status === "Em processo" || status === "Em análise") &&
        css`
            border: 1px solid #fcaf01;
            background: #fff7e8;
            color: #fcaf01;
        `}

  ${({ status }) =>
        status === "Atendimento" &&
        css`
            border: 1px solid #428bca;
            background: #e2f1ff;
            color: #428bca;
        `}

  ${({ status }) =>
        status === "Administrativo" &&
        css`
            border: 1px solid #ac5bde;
            background: #f7eaff;
            color: #ac5bde;
        `}
  ${({ status }) =>
        status === "Financeiro" &&
        css`
            border: 1px solid #67bfa9;
            background: #d5f2ea;
            color: #67bfa9;
        `}
  ${({ status }) =>
        status === "Personalizado" &&
        css`
            border: 1px solid #0fbf7f;
            background: #ffeddf;
            color: #0fbf7f;
        `}
    // STATUS VALIDAÇÃO
    ${({ status }) =>
        status === "Ativo" &&
        css`
            border: 1px solid #10cf5c;
            background: #f2fff7;
            color: #10cf5c;
        `}
    ${({ status }) =>
        status === "Desativada" &&
        css`
            border: 1px solid #c3c6d1;
            background: #f6f7fa;
            color: #56607e;
        `}

    ${({ status }) =>
        status === "Validando" &&
        css`
            border: 1px solid #0fbf7f;
            background: #f0fffb;
            color: #0fbf7f;
        `}

    ${({ status }) =>
        status === "Inválida" &&
        css`
            border: 1px solid #ff0000;
            background: #fff6f6;
            color: #ff0000;
        `}
`;
