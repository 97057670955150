/* eslint-disable eqeqeq */
import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
  MutableRefObject,
} from 'react';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Container, SectionTitle } from './styles';
import FormWrapper from '../../../../../components/Form';
import UploadBox from '../../../../../components/UploadBox';
import Badge from '../../../../../components/Badge';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/ModalAdmin';
import ErrorMessage from '../../../../../components/ErrorMessage';
import { ICompany } from '../../../../../entities/Company';
import useCompanyFileUpload from '../../../../../hooks/useCompanyFileUpload';
import { isValidFileType } from '../../../../../utils/functions.js';
import UploadTable from '../../../../../components/UploadTable';
import useToggle from '../../../../../hooks/useToggle';
import { AccountsContext as Context } from '../../../../../context/AccountsContext';

interface CompanyProps {
  company: ICompany | undefined;
  sendDataToCompanyDetail?: any;
}

const errorFileTypeMessage =
  'Por favor, confira se seu arquivo é do tipo .jpeg, .jpg, .png, .pdf e ele deve possuir no máximo 10MB de tamanho.';

const CompanyDocuments: React.FC<CompanyProps> = ({
  company,
  sendDataToCompanyDetail,
}) => {
  const {
    disableUploadCompanyAddressProveDocuments,
    disableUploadCompanyBankAccountDocuments,
    disableUploadCompanyDocuments,
  } = useContext(Context);
  const [documentErrorMsg, setDocumentErrorMsg] = useState('');
  const [addressErrorMsg, setAddressErrorMsg] = useState('');
  const [bankErrorMsg, setBankErrorMsg] = useState('');
  const { loadingStatus, uploadFile, companyData } = useCompanyFileUpload();
  const [isModalOpen, setIsModalOpen] = useToggle(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const location = useLocation();
  const toScrollRef = useRef() as MutableRefObject<HTMLDivElement>;

  const uploadDocuments = useCallback(
    (file, type) => {
      if (isValidFileType(file)) {
        uploadFile(file, type, company?.id_code!);
      } else {
        type === 'EMPRESA_CCMEI' && setDocumentErrorMsg(errorFileTypeMessage);
        type === 'EMPRESA_RESIDENCIA' &&
          setAddressErrorMsg(errorFileTypeMessage);
        type === 'EMPRESA_EXTRATO' && setBankErrorMsg(errorFileTypeMessage);
      }
    },
    [company, uploadFile],
  );

  // Modals

  const companyUploadListModal = (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={setIsModalOpen}
      closeModal={setIsModalOpen}
      headerLabel={modalTitle}
    >
      <UploadTable
        endpoint={`/companies/get-documents/${company?.id_code!}/type/${endpoint}`}
      />
    </Modal>
  );

  const handleCompanyModal = useCallback(
    (title, type) => {
      setModalTitle(title);
      setEndpoint(type);
      setIsModalOpen();
    },
    [setIsModalOpen],
  );

  const tooltipAddress = (
    <ReactTooltip
      effect="solid"
      place="top"
      id="tooltip-company-address"
      aria-haspopup="true"
    >
      <p style={{ color: '#fff', maxWidth: '300px', textAlign: 'center' }}>
        Por favor, verifique se os campos de endereço da empresa estão
        corretamente preenchidos.
      </p>
      <p style={{ color: '#fff', maxWidth: '300px', textAlign: 'center' }}>
        Os campos de upload só serão liberados após o seu preenchimento.
      </p>
    </ReactTooltip>
  );

  const tooltipBank = (
    <ReactTooltip
      effect="solid"
      place="top"
      id="tooltip-bank-statement"
      aria-haspopup="true"
    >
      <p style={{ color: '#fff', maxWidth: '300px', textAlign: 'center' }}>
        Por favor, verifique se os campos de conta bancária estão corretamente
        preenchidos.
      </p>
      <p style={{ color: '#fff', maxWidth: '300px', textAlign: 'center' }}>
        Os campos de upload só serão liberados após o seu preenchimento.
      </p>
    </ReactTooltip>
  );

  const scrollToElement = useCallback(() => {
    toScrollRef.current &&
      toScrollRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [toScrollRef]);

  useEffect(() => {
    if (companyData) {
      sendDataToCompanyDetail(companyData);
      setUploadSuccess(true);
    }
    location.hash == '#documents' && scrollToElement();
  }, [
    company,
    companyData,
    sendDataToCompanyDetail,
    disableUploadCompanyAddressProveDocuments,
    disableUploadCompanyBankAccountDocuments,
    disableUploadCompanyDocuments,
    location,
    scrollToElement,
  ]);

  return (
    
    <Container cardTitle="Documentos" loading={loadingStatus}>
      {companyUploadListModal}
      <FormWrapper>
        <p className="subtitle">
          Para manter a nossa comunidade segura, precisamos comprovar alguns
          dados através dos documentos da sua empresa.
        </p>
        {company?.type == 2 && (
          <div style={{ margin: '0 -7.5px 0 -7.5px' }}>
            <div className="col col-sm-12" style={{ marginBottom: '50px' }}>
              <div className="section-title-badge row" ref={toScrollRef}>
                <SectionTitle className="col-md-7">
                  Certificado de cadastro de Micro Empresário Individual (CCMEI)
                  ou o contrato social
                </SectionTitle>
                <div className="col-md-3 col-xs-6">
                  <Badge
                    className="badge"
                    status={company.contract_status_translate}
                  />
                </div>
                <div className="col-sm-2 col-xs-6">
                  <Button
                    type="button"
                    className="view-uploads-button"
                    onClick={() =>
                      handleCompanyModal(
                        'Documento da empresa',
                        'EMPRESA_CCMEI',
                      )
                    }
                  >
                    Ver uploads
                  </Button>
                </div>
              </div>
              {company.contract_status_translate != 'Aprovado' && (
                <>
                  <p>
                    Se for MEI, o documento é o certificado de condição de MEI.
                    Se for ME é o requerimento de empresário ou documento
                    assinado pela junta comercial do estado.
                  </p>
                  <UploadBox
                    id="companyDocument"
                    className="uploadbox"
                    wholeFile={(file: File) =>
                      uploadDocuments(file, 'EMPRESA_CCMEI')
                    }
                    disabled={disableUploadCompanyDocuments}
                    uploadStatus={uploadSuccess}
                  />
                  {documentErrorMsg && (
                    <ErrorMessage>{documentErrorMsg}</ErrorMessage>
                  )}
                </>
              )}
            </div>
            <div className="col col-sm-12" style={{ marginBottom: '50px' }}>
              <div className="section-title-badge row">
                <SectionTitle className="bank-statement-title col-md-7">
                  Comprovante de residência da empresa
                </SectionTitle>
                <div className="col-md-3 col-xs-6">
                  <Badge
                    className="badge"
                    status={company.address_status_translate}
                  />
                </div>
                <div className="col-sm-2 col-xs-6">
                  <Button
                    type="button"
                    className="view-uploads-button"
                    onClick={() =>
                      handleCompanyModal(
                        'Comprovante de residência',
                        'EMPRESA_RESIDENCIA',
                      )
                    }
                  >
                    Ver uploads
                  </Button>
                </div>
              </div>
              {company.address_status_translate != 'Aprovado' && (
                <>
                  <p>
                    O Comprovante de endereço precisa estar no nome do usuário
                    ou da empresa e precisa estar dentro do prazo de 90 dias de
                    vencimento. São aceitos como comprovante empresarial as
                    contas de: água, luz, internet, telefone fixo, telefone
                    móvel, gás encanado. Pode
                    ser via digital ou foto.
                  </p>
                  <div data-for="tooltip-company-address" data-tip>
                    <UploadBox
                      id="companyAddress"
                      className="uploadbox"
                      wholeFile={(file: File) =>
                        uploadDocuments(file, 'EMPRESA_RESIDENCIA')
                      }
                      disabled={disableUploadCompanyAddressProveDocuments}
                      uploadStatus={uploadSuccess}
                    />
                  </div>
                  {addressErrorMsg && (
                    <ErrorMessage>{addressErrorMsg}</ErrorMessage>
                  )}
                  {disableUploadCompanyAddressProveDocuments && tooltipAddress}
                </>
              )}
            </div>
          </div>
        )}
        {/*
        <div style={{ margin: '0 -7.5px 0 -7.5px' }}>
          <div className="col col-sm-12">
            <div className="section-title-badge row">
              <SectionTitle className="bank-statement-title col-md-7">
                Extrato bancário da sua conta
              </SectionTitle>
              <div className="col-md-3 col-xs-6">
                <Badge
                  className="badge"
                  status={company?.bank_status_translate}
                />
              </div>
              <div className="col-sm-2 col-xs-6">
                <Button
                  type="button"
                  className="view-uploads-button"
                  onClick={() =>
                    handleCompanyModal('Extrato bancário', 'EMPRESA_EXTRATO')}
                >
                  Ver uploads
                </Button>
              </div>
            </div>
            {company?.bank_status_translate != 'Aprovado' && (
              <>
                <p>
                  Para finalizar, precisamos de um print do seu internet banking
                  ou extrato da sua conta corrente, onde consta o nome da
                  empresa ou nome do empresário seguido do{' '}
                  {company?.type === 1 ? 'CPF' : 'CNPJ'}, Banco, Agência e
                  Conta.
                </p>
                <div data-for="tooltip-bank-statement" data-tip>
                  <UploadBox
                    id="bankStatement"
                    className="uploadbox"
                    wholeFile={(file: File) =>
                      uploadDocuments(file, 'EMPRESA_EXTRATO')}
                    disabled={disableUploadCompanyBankAccountDocuments}
                    uploadStatus={uploadSuccess}
                  />
                </div>
                {bankErrorMsg && <ErrorMessage>{bankErrorMsg}</ErrorMessage>}
                {disableUploadCompanyBankAccountDocuments && tooltipBank}
              </>
            )}
          </div>
        </div>
        */}
      </FormWrapper>
    </Container>    
  );
};

export default CompanyDocuments;
