import styled from 'styled-components';
import * as color from '../../styles/colors';

interface TextProps {
  primary?: boolean;
  secondary?: boolean;
}

export const Title = styled.p<TextProps>`
  font-size: ${({ secondary }) => (secondary ? '20px' : '18px')};
  /* font-weight: ${({ secondary }) => (secondary ? '600' : 'bold')}; */
  font-weight: bold;
  color: ${({ primary, secondary }) =>
    primary ? color.orange : secondary ? color.darkgrey : '#2E2E2E'};
  margin: 0;
  line-height: 1.4;
`;
