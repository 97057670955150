/* eslint-disable eqeqeq */
import React, { useCallback, useState } from 'react';
import { CompanyBox, Content } from './styles';

interface TaxCustamProps {
  id: string | undefined;
  fantasyName: string | undefined;
  companyDocument: string | undefined;
  boletoTax: string | undefined;
  pixTax: string | undefined;
  creditCardTax: string | undefined;
  checkoutTax: string | undefined;
  creditCardReleaseMoneyDays: number | undefined;
  bankSlipReleaseMoneyDays: number | undefined;
  pixReleaseMoneyDays: number | undefined;
}

const TaxCustomContainer: React.FC<TaxCustamProps> = ({
  id,
  fantasyName,
  companyDocument,
  boletoTax,
  pixTax,
  creditCardTax,
  checkoutTax,
  creditCardReleaseMoneyDays,
  pixReleaseMoneyDays,
  bankSlipReleaseMoneyDays,
}) => {
  const [taxType, setTaxType] = useState<string | Blob>('');
  const [loading, setLoading] = useState<number>(0);

  const translateRule = (value: any) => {
    return value == 'value' ? 'Valor (R$)' : 'Porcentagem (%)';
  };

  const divClass = checkoutTax ? 'col-sm-6' : 'col-sm-4';

  return (
    <CompanyBox key={id}>
      <Content>
        <div className="row">
          <h5 className="header company-name col-sm-8 col-xs-12">
            {fantasyName}
          </h5>

          <h5 className="header company-document col-sm-4 col-xs-12">
            {companyDocument}
          </h5>
        </div>

        <div className="row">
          <div className={`col-xs-12 ${divClass}`}>
            <p>
              Taxa de Boleto: <span className="info">{boletoTax}</span>
            </p>
          </div>

          <div className={`col-xs-12 ${divClass}`}>
            <p>
              Taxa de PIX: <span className="info">{pixTax}</span>
            </p>
          </div>

          <div className={`col-xs-12 ${divClass}`}>
            <p>
              Taxa de Cartão: <span className="info">{creditCardTax}</span>
            </p>
          </div>

          {checkoutTax ? (
            <div className={`col-xs-12 ${divClass}`}>
              <p>
                Taxa de Checkout: <span className="info">{checkoutTax}</span>
              </p>
            </div>
          ) : (
            ''
          )}
        </div>

        <p className="release-info">
          Prazo para liberação de vendas no cartão: D
          {creditCardReleaseMoneyDays}
        </p>
        <p className="release-info">
          Prazo para liberação de vendas no pix: D{pixReleaseMoneyDays}
        </p>
        <p className="release-info">
          Prazo para liberação de vendas no boleto: D{bankSlipReleaseMoneyDays}
        </p>
      </Content>
    </CompanyBox>
  );
};

export default TaxCustomContainer;
