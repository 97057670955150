import styled from "styled-components";
import Card from "../../../../components/Card";
import * as color from "../../../../styles/colors";

interface TextProps {
    primary?: boolean;
    secondary?: boolean;
}

interface TransferOptionProps {
    value?: string;
}

export const Container = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`;

export const Title = styled.p<TextProps>`
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px !important;
    line-height: 120%;
    color: #56607e;

    @media (max-width: 576px) {
        font-size: 16px !important;
    }
`;

export const TitleSecondary = styled.p`
    font-size: "16px !important";
    margin-top: -12px !important;
    max-width: 285px;

    @media (max-width: 576px) {
        font-size: 12px !important;
        max-width: 195px;
    }
`;

export const Subtile = styled.p`
    font-size: "16px !important";
    margin-top: -12px !important;

    @media (max-width: 576px) {
        font-size: 12px !important;
    }
`;

export const TransferContent = styled.div`
    display: flex;
    flex-direction: "row";
    margin-top: 24px;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;
export const TransferOption = styled.div<TransferOptionProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #f6f7fa;
    padding: 75px 24px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 12px;
    margin-left: 12px;
    cursor: pointer;

    max-width: 315px;
    max-height: 315px;

    transition: 0.8s;

    &:first-child {
        margin-right: 12px;
        margin-left: 0px;
    }

    & path {
        transition: 0.8s;
    }

    &:hover {
        border: 2px solid #0fbf7f;
        box-sizing: border-box;
        box-shadow: 0px 0px 12px rgba(255, 109, 0, 0.24);

        & path {
            fill: #0fbf7f;
        }
    }

    @media (max-width: 800px) {
        margin: 12px 0px 0px;

        &:first-child {
            margin: 0px 0px 12px;
        }
    }

    @media (max-width: 576px) {
        padding: 24px;
        height: 166px;

        svg {
            width: 26px;
            height: 26px;

            min-width: 26px;
            min-height: 26px;
        }
    }
`;
