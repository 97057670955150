import React from "react";
import { Blurs, Container, Content } from "./styles";
// @ts-ignore
import TypeWriterEffect from "react-typewriter-effect";

import nexusLogoWhite from "../../assets/logos/2022/logo_horizontal_white.png";
import logoNexus from "../../assets/logos/2022/azcend-full.png";
import blueBlurOne from "../../assets/purple-container/blurs/blur-blue-1.svg";
import purpleBlurOne from "../../assets/purple-container/blurs/blur-purple-1.svg";
import blueBlurTwo from "../../assets/purple-container/blurs/blur-blue-2.svg";
import purpleBlurTwo from "../../assets/purple-container/blurs/blur-purple-2.svg";
interface PurpleBackgroundContainerProps {
    className?: string;
    intro: boolean;
}

const PurpleBackgroundContainer: React.FC<PurpleBackgroundContainerProps> = ({ className, intro }) => {
    return (
        <Container>
            <Blurs>
                <img alt="" className="purpleBlurOne" src={purpleBlurOne} />
                <img alt="" className="blueBlurOne" src={blueBlurOne} />

                <img alt="" className="purpleBlurTwo" src={purpleBlurTwo} />
                <img alt="" className="blueBlurTwo" src={blueBlurTwo} />

                <img alt="" className="purpleBlurThree" src={purpleBlurOne} />
            </Blurs>

            <Content intro={intro}>
                <div className="text">
                    <img alt="" className="logo" src={nexusLogoWhite} />
                    <h1 className="title">Aqui você vende</h1>

                    <div className="typing-container">
                        <TypeWriterEffect
                            textStyle={{
                                color: "#00b7ff",
                                fontWeight: 700,
                                fontSize: "55px",
                            }}
                            startDelay={3000}
                            cursorColor="#00b7ff"
                            multiText={["produtos físicos", "assinaturas", "qualidade", "importados"]}
                            multiTextDelay={3000}
                            typeSpeed={50}
                            loop={true}
                        />
                    </div>

                    <p className="subtitle">A solução em pagamentos online com as</p>
                    <p className="subtitle">taxas de aprovação mais altas do Brasil!</p>
                </div>

                <div className="logo-center">
                    <img alt="" src={logoNexus} />
                </div>
            </Content>
        </Container>
    );
};

export default PurpleBackgroundContainer;
