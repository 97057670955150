import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Title from '../../components/Title';
import Button from '../../components/Button';
import { Container } from './styles';
import api from '../../services/api';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import { AccountsContext as Context } from '../../context/AccountsContext';
import { login } from '../../services/auth.js';
import TextInput from '../../components/FormInputs/TextInput';
import AnimatedPurpleContainerPage from '../../components/AnimatedPurpleContainerPage';
import { toast } from 'react-toastify';
import PasswordRequirements from '../../components/PasswordRequirements';
import { hasCapitalLowerLetters, hasEightChar, hasSpecialChar } from '../../utils/functions';
import FininishComponent from '../../components/FininishComponent';

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState<number>(0);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { setUser } = useContext(Context);
  const [hasError, setHasError] = useState<boolean>(false);
  const history = useHistory();

  const token = useUrlSearchParams('token');
  const email = useUrlSearchParams('email');
  // PASSWORD -----------------------------------------
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
  const [statusPassword, setStatusPassword] = useState<string>("");
  const [statusConfirmPassword, setStatusConfirmPassword] = useState<string>("");

  const [eightChar, setEightChar] = useState(false);
  const [capitalLowerLetters, setCapitalLowerLetters] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);

  function testPassword(password : string) {
    setEightChar(hasEightChar.test(password))
    setCapitalLowerLetters(hasCapitalLowerLetters.test(password))
    setSpecialChar(hasSpecialChar.test(password))
  } 

  useEffect(() => {
    testPassword(password);
  }, []);

  const passwordValidationCheck = useCallback(
    (e) => {
      const value = e.target.value;
      setPassword(value);
      setPasswordError("");

      setConfirmPassword('');
      setConfirmPasswordError('');
      setStatusConfirmPassword('')

      testPassword(value);
    },
    [hasEightChar, hasCapitalLowerLetters, hasSpecialChar, password, setPassword]
  );

  const passwordValidationError = () => {
    var passwordValidate = true;

    if (!hasEightChar.test(password)) {
      setStatusPassword('');
      setPasswordError("Sua senha deve ter ao menos oito caracteres.");
      setEightChar(false);
      passwordValidate = false;
    } else {
      setEightChar(true);
    }

    if (!hasCapitalLowerLetters.test(password)) {
      setStatusPassword('');
      setPasswordError("Sua senha deve ter letras minusculas e maiusculas.");
      setCapitalLowerLetters(false);
      passwordValidate = false;
    } else {
      setCapitalLowerLetters(true);
    }

    if (!hasSpecialChar.test(password)) {
      setStatusPassword('');
      setPasswordError("Sua senha deve ter números e caracteres especiais.");
      setSpecialChar(false);
      passwordValidate = false;
    } else {
      setSpecialChar(true);
    }

    if(passwordValidate) {
      setStatusPassword('success');
    }


    return passwordValidate;
  };

  const confirmPasswordCheck = () => {
    if ((confirmPassword === password) && password != '' && confirmPassword != '' && passwordValidationError()) {
      setStatusConfirmPassword('success');
      setConfirmPasswordError("");
      return true;
    } else {

      setStatusConfirmPassword('');
      if(passwordValidationError()){
        setConfirmPasswordError("A confirmação de senha não confere.");
      }else{
        setConfirmPasswordError("Formato de senha inválido.");
      }
      
      return false;
    }
  };

  const toggleError = () => {
    setHasError(!hasError);
  }

  const handleLogin = async () => {
    if (confirmPassword !== password) {
      setErrorMsg('Senhas não se coincidem');
      return;
    }

    if ( passwordValidationError() && confirmPassword == password) {
      setLoading(1);
      const formData = new FormData();

      formData.append('password', password);
      formData.append('password_confirmation', confirmPassword);
      formData.append('email', email);
      formData.append('token', token);

      try {
        const res = await api.post('/password/reset', formData);
        setLoading(0);
        const userRes = res.data.user;
        localStorage.clear();
        setUser(userRes);
        login(res.data.token);
        history.push('/home');
      } catch (error) {
        setLoading(0);
        toggleError();
      }
    }
  };

  return (
    <AnimatedPurpleContainerPage loading={loading} intro={false}>
      {hasError == false ? (
        <Container>
          <Title className='title'>
            Crie sua nova senha
          </Title>

          <TextInput
            className="password"
            id="password"
            name="password"
            mask=""
            type="password"
            placeholder="Nova senha"
            value={password}
            onChange={passwordValidationCheck}
            onBlur={passwordValidationError}
            error={passwordError}
            status={statusPassword}
          />
          
          <TextInput
            className="confirm-password"
            id="confirm-password"
            name="confirm-password"
            mask=""
            type="password"
            placeholder="Confirmar nova senha"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
            onBlur={confirmPasswordCheck}
            error={confirmPasswordError}
            status={statusConfirmPassword}
          />

          <PasswordRequirements 
            hasEightCharacters={eightChar} 
            hasCapitalLowerLetters={capitalLowerLetters} 
            hasSpecialCharacters={specialChar}
          />        
          
          <Button
            primary
            type="button"
            className="btn-reset-password"
            textTransform
            onClick={handleLogin}
          >
            FAZER LOGIN
          </Button>
        </Container>
      ):(
        <FininishComponent
          status='error'
          title='Algo deu errado :('
          description='Não conseguimos atender a sua solicitação nesse momento. Pedimos que você tente outra vez ou volte mais tarde.'
          buttonText='ENTENDI'
          buttonClick={toggleError}
        />
      )}
      
    </AnimatedPurpleContainerPage>
  );
};

export default ResetPassword;
