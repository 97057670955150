/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Container,
  AvatarContainer,
  Avatar,
  SectionTitle,
  Button,
  ButtonValidateAccount,
} from './styles';
import FormWrapper from '../../../../components/Form';
import cameraIcon from '../../../../assets/camera-orange.svg';
import Input from '../../../../components/Input';
import Modal from '../../../../components/ModalAdmin';
import ImageCrop from './ImageCrop';
import CodeInput from '../../../../components/VerificationCodeInput';
import Loader from '../../../../components/Loader';
import useToggle from '../../../../hooks/useToggle';
import api from '../../../../services/api';
import { IUser } from '../../../../entities/User';
import { AccountsContext as Context } from '../../../../context/AccountsContext';
import ValidateProgress from '../../../../components/ValidateProgress';
import Badge from '../../../../components/Badge';
import codeIcon from '../../../../assets/modal-icons/code-icon.svg';
import { borderRadius } from 'polished';
import { useHistory } from 'react-router-dom';

interface PersonalFormProps {
  user: IUser | undefined;
  setUser: any;
}

type FormData = {
  name: string;
  sex: string;
  cellphone: string;
  email: string;
  document: string;
  date_birth: string;
};

const noAvatarImage =
  'https://cloudfox-documents.s3.amazonaws.com/cloudfox/defaults/user-default.png';

const PersonalForm: React.FC<PersonalFormProps> = ({ user, setUser }) => {
  const { setDisableUploadPersonalDocuments } = useContext(Context);
  const [isModalOpen, setIsModalOpen] = useToggle(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isEmailVerifyCodeModalOpen, setIsEmailVerifyCodeModalOpen] =
    useToggle(false);
  const [isPhoneVerifyCodeModalOpen, setIsPhoneVerifyCodeModalOpen] =
    useToggle(false);
  const [loading, setLoading] = useState<number>(0);
  const [document, setDocument] = useState<string>('');
  const [cellphone, setCellphone] = useState<string>('');
  const { register, setValue, handleSubmit, errors, reset, watch } =
    useForm<FormData>({
      defaultValues: {
        ...user,
      },
    });

  const phoneWatch = watch('cellphone');

  const [biometryStep, setBiometryStep] = useState<number | undefined>(
    user?.step_biometry
  );

  // Modal States
  const [statusModal, setStatusModal] = useState('confirm');
  const [loadingModal, setLoadingModal] = useState<number>(0);

  useEffect(() => {
    setBiometryStep(user?.step_biometry);
  }, [user]);

  async function sendPhoneCode(cellphone: string) {
    setLoading(1);
    try {
      if (phoneWatch.substring(1, 3) == '55' && phoneWatch.length < 17) {
        toast.error('Por favor, peencha em celular válido');
      } else {
        await api.post('/profile/send-cellphone-code', {
          cellphone,
        });
        setLoading(0);
        toast.success('Seu código foi enviado com sucesso!');
        setIsPhoneVerifyCodeModalOpen();
      }
    } catch (error) {
      //@ts-ignore
      setErrorMsg(error.response.data.message);
      toast.error('Algo não saiu como esperado. Tente novamente.');
    } finally {
      setLoading(0);
    }
  }

  async function sendEmailToken(email: string) {
    setLoading(1);

    try {
      await api.post('/profile/send-email-code', {
        email,
      });
      setLoading(0);
      toast.success('Seu código foi enviado com sucesso!');
      setIsEmailVerifyCodeModalOpen();
    } catch (error) {
      setLoading(0);
      //@ts-ignore
      setErrorMsg(error.response.data.message);
      toast.error('Algo não saiu como esperado. Tente novamente.');
    }
  }

  const phoneTokenComplete = useCallback(
    e => {
      setLoading(1);
      api
        .post('/profile/match-cellphone-verify-code', {
          cellphone: user?.cellphone,
          token: e,
        })
        .then(res => {
          setLoading(0);
          toast.success('Celular confirmado com sucesso!');
          setUser(res.data.data);
          setIsPhoneVerifyCodeModalOpen();
        })
        .catch(error => {
          setLoading(0);
          if (error.response) {
            setErrorMsg(error.response.data.message);
          }
        });
    },
    [user, setIsPhoneVerifyCodeModalOpen, setUser]
  );

  const emailTokenComplete = useCallback(
    e => {
      setLoading(1);
      api
        .post('/profile/match-email-verify-code', {
          email: user?.email,
          token: e,
        })
        .then(res => {
          setLoading(0);
          toast.success('Email confirmado com sucesso!');
          setUser(res.data.data);
          setIsEmailVerifyCodeModalOpen();
        })
        .catch(error => {
          setLoading(0);
          if (error.response) {
            setErrorMsg(error.response.data.message);
          }
        });
    },
    [user, setIsEmailVerifyCodeModalOpen, setUser]
  );

  const imageError = useCallback(e => {
    e.target.src = noAvatarImage;
  }, []);

  const savePersonalInfo = useCallback(
    async (data: FormData) => {
      if (user?.email === data.email && cellphone === data.cellphone) {
        toast.warning('Dados pessoais continuam inalterados');
      } else {
        setLoading(1);

        try {
          const res = await api.put('/profile', {
            ...data,
          });
          const userRes: IUser = res.data.data;
          setUser(userRes);
          setLoading(0);
          toast.success('Perfil atualizado com sucesso!');
        } catch (error) {
          if (errors == 500) {
            toast.error('Ocorreu um problema, tente novamente mais tarde.');
          } else {
            //@ts-ignore
            const errorData = error.response.data;
            Object.entries(errorData.errors).map((resError: any) => {
              toast.error(resError[1][0]);
            });
          }

          setLoading(0);
        }
      }
    },
    [user, cellphone, setUser, errors]
  );

  const closeModalFromImageCrop = useCallback(
    e => {
      e && setIsModalOpen();
    },
    [setIsModalOpen]
  );

  // Edit avatar modal
  const editAvatarModal = (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={setIsModalOpen}
      closeModal={setIsModalOpen}
      headerLabel="Editar imagem de avatar">
      <ImageCrop closeModal={closeModalFromImageCrop} />
    </Modal>
  );

  // Email verification code modal
  const emailCodeVerificationModal = (
    <Modal
      smallWidth
      isOpen={isEmailVerifyCodeModalOpen}
      onRequestClose={setIsEmailVerifyCodeModalOpen}
      closeModal={setIsEmailVerifyCodeModalOpen}
      headerLabel="Verificar código">
      <CodeInput
        onComplete={emailTokenComplete}
        loading={loading}
        icon={codeIcon}
        title={'Verifique seu email'}
        text="Um código foi enviado para o seu email. Digite seu código abaixo para
        confirmar seu email."
        errorMsg={errorMsg && errorMsg}
      />
    </Modal>
  );

  // Phone verification code modal
  const phoneCodeVerificationModal = (
    <Modal
      smallWidth
      isOpen={isPhoneVerifyCodeModalOpen}
      onRequestClose={setIsPhoneVerifyCodeModalOpen}
      closeModal={setIsPhoneVerifyCodeModalOpen}
      headerLabel="Verificar código">
      <CodeInput
        onComplete={phoneTokenComplete}
        loading={loading}
        icon={codeIcon}
        title={'Verifique seu celular'}
        text="Um código foi enviado para o seu celular. Digite seu código abaixo para
        confirmar seu celular."
        errorMsg={errorMsg && errorMsg}
      />
    </Modal>
  );

  // Email error message
  const emailNotVerifiedErrorMsg = (
    <span>
      Email não verificado, clique{' '}
      <Button type="button" onClick={() => sendEmailToken(`${user?.email}`)}>
        aqui
      </Button>{' '}
      para verificá-lo!
    </span>
  );

  const phoneNotVerifiedErrorMsg = (
    <span>
      Celular não verificado, clique{' '}
      <Button type="button" onClick={() => sendPhoneCode(`${user?.cellphone}`)}>
        aqui
      </Button>{' '}
      para verificá-lo!
    </span>
  );

  const normalizePhone = (tel: string) => {
    tel = tel.replace(/\D/g, '');
    tel = tel.replace(/^(\d)/, '+$1');
    if (tel.substring(1, 3) == '55') {
      tel = tel.replace(/(.{3})(\d)/, '$1($2');
      tel = tel.replace(/(.{6})(\d)/, '$1)$2');
      tel = tel.replace(/(.{4})$/, '-$1');
    }

    return tel;
  };

  useEffect(() => {
    reset({
      ...user,
      document,
      cellphone,
    });

    if (user?.document) {
      const formatedCPF = user?.document.replace(
        /^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/,
        '$1.$2.$3-$4'
      );
      setDocument(formatedCPF);
    }

    if (user?.cellphone) {
      const formatedPhone = normalizePhone(user?.cellphone);
      setCellphone(formatedPhone);
    }

    if (user?.name === '' || user?.name === null) {
      setDisableUploadPersonalDocuments(true);
    }
    // user?.personal_document_status === 3 && setCanEdit(true);
  }, [
    cellphone,
    document,
    reset,
    setDisableUploadPersonalDocuments,
    setValue,
    user,
  ]);

  const onAlterPhone = async () => {
    setLoading(1);
    try {
      const res = await api.post('/profile/reset-cellphone-verify');
      setUser(res.data.data);
    } catch (error) {
      if (errors == 500) {
        toast.error('Ocorreu um problema, tente novamente mais tarde.');
      } else {
        //@ts-ignore
        const errorData = error.response.data;
        Object.entries(errorData.errors).map((resError: any) => {
          toast.error(resError[1][0]);
        });
      }
    } finally {
      setLoading(0);
    }
  };

  const routeChange = (url: string) => {
    window.location.replace(url);
  };

  return (
    <Container>
      <Loader loading={loading} />
      {editAvatarModal}
      {emailCodeVerificationModal}
      {phoneCodeVerificationModal}
      <p className="top-text">
        Não se preocupe, estas informações não estarão visíveis para outros
        usuários.
      </p>
      <SectionTitle>Dados pessoais</SectionTitle>
      <FormWrapper submitButton onSubmit={handleSubmit(savePersonalInfo)}>
        <div className="row">
          <AvatarContainer className="col-sm-4 col-xs-12">
            <p>Sua Foto</p>
            <Avatar onClick={setIsModalOpen}>
              <img
                className="photo"
                src={user?.photo ? user?.photo : noAvatarImage}
                onError={imageError}
                alt=""
              />
              <div className="icon-background" />
              <img className="icon" src={cameraIcon} alt="" />
            </Avatar>
          </AvatarContainer>
          <Input
            type="text"
            label="Nome completo"
            id="name"
            name="name"
            mask=""
            disabled={!user?.can_edit_personal_information}
            className="col-xs-12 col-sm-8"
            register={register}
          />

          <Input
            label="CPF"
            id="document"
            name="document"
            mask="999.999.999-99"
            type="text"
            maxLength={25}
            disabled
            className="col-sm-5 col-xs-12"
            // value={document}
            setValue={setValue}
            register={register}
            errorMessage={errors.document?.message}
          />
          {user?.is_account_owner ? (
            <>
              <Input
                status={user?.cellphone_verified}
                label="Celular (WhatsApp)"
                id="cellphone"
                name="cellphone"
                mask="+.. (..) .....-...."
                className="col-sm-7 col-xs-12"
                register={register}
                maxLength={17}
                placeholder="+55(00)00000-0000"
                errorMessage={
                  user?.cellphone_verified === 0 && phoneNotVerifiedErrorMsg
                }
                onChange={event => {
                  const { value } = event.target;
                  event.target.value = normalizePhone(value);
                }}
              />
              <Input
                status={user?.email_verified}
                label="Email"
                id="email"
                name="email"
                mask=""
                className="col-sm-12 col-xs-12"
                register={register}
                errorMessage={
                  user?.email_verified === 0 && emailNotVerifiedErrorMsg
                }
              />
            </>
          ) : (
            <>
              <Input
                label="Celular (WhatsApp)"
                id="cellphone"
                name="cellphone"
                mask="+.. (..) .....-...."
                className="col-sm-7 col-xs-12"
                disabled
                register={register}
              />
              <Input
                label="Email"
                id="email"
                name="email"
                mask=""
                className="col-sm-12 col-xs-12"
                disabled
                register={register}
              />
            </>
          )}
        </div>
      </FormWrapper>

      {user?.is_account_owner && (
        <>
          <div className="spaced-row" style={{ marginTop: '54px' }}>
            <SectionTitle>Status da validação de identidade</SectionTitle>
            <Badge status={user?.biometry_status_translate} />
          </div>

          {Number(user?.biometry_status) == 1 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <ButtonValidateAccount
                type="button"
                onClick={() =>
                  routeChange(
                    process.env.REACT_APP_ADMIN +
                      '/account-validation/' +
                      user?.id_code
                  )
                }>
                Validar minha conta
              </ButtonValidateAccount>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </Container>
  );
};

export default PersonalForm;
