import styled from 'styled-components';
import Select from 'react-select';
import * as color from '../../styles/colors';
import arrowDownIcon from '../../assets/chevron-down-orange.svg';

export const Container = styled.div`
  padding: 0 15px;
  margin: 0 0 16px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${color.darkgrey};
  margin-left: 10px;
  line-height: 27px;
`;

export const SelectInput = styled(Select)`
  .accounts {
    &__control {
      height: 48px;
      width: 100%;
      border-radius: 10px;
      /* padding: 6px 40px 6px 12px; */
      border: 1px solid #c3c6d1;
      /* background: url(${arrowDownIcon}) no-repeat right 12px center/23px 22px; */

      -webkit-appearance: none;
      -moz-appearance: none;

      &--is-focused {
        border-color: #c3c6d1;
        outline: 0 !important;
        box-shadow: none;
        :hover {
          border-color: #c3c6d1;
        }
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      svg {
        stroke: ${color.orange};
        fill: ${color.orange};
      }
    }

    &__single-value {
      top: 60%;
      width: 100%;
    }
  }
`;

// export const Select = styled(Select)`
//   height: 48px;
//   width: 100%;
//   border-radius: 10px;
//   padding: 6px 40px 6px 12px;
//   border: 1px solid #c3c6d1;
//   background: url(${arrowDownIcon}) no-repeat right 12px center/23px 22px;

//   -webkit-appearance: none;
//   -moz-appearance: none;
// `;
