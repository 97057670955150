/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Container, CompanyBox, Content } from './styles';
import Card from '../../../../../components/Card';
import Button from '../../../../../components/Button';
import api from '../../../../../services/api';
import { ICompany } from '../../../../../entities/Company';
import { AccountsContext as Context } from '../../../../../context/AccountsContext';
import TaxDefaultContainer from './TaxDefaultContainer';
import TaxCustomContainer from './TaxCustomContainer';

const SiriusCompanies: React.FC = () => {
  const { setSiriusLoadingSync } = useContext(Context);
  const [companies, setCompanies] = useState<[]>();

  const [loading, setLoading] = useState<number>(0);
  const [noCompaniesMessage, setNoCompaniesMessage] = useState<string>('');

  useEffect(() => {
    (async () => {
      setLoading(1);
      setSiriusLoadingSync(1);
      try {
        const response = await api.get('/companies');
        const companiesList = response.data.data;

        const newCompanies = companiesList.filter((c: ICompany) => {
          return c.status == 'Aprovado';
        });

        setCompanies(newCompanies);
        setLoading(0);
        setSiriusLoadingSync(0);

        if (newCompanies.length === 0) {
          setNoCompaniesMessage('Nenhuma empresa está aprovada no momento');
        }
      } catch (error) {
        setLoading(0);
        setSiriusLoadingSync(0);
      }
    })();
  }, [setSiriusLoadingSync]);

  return (
    <Container>
      <Card
        className="companies-list"
        cardTitle="Prazos e tarifas"
        loading={loading}>
        {companies && companies?.length !== 0 ? (
          <>
            <div className="top-tax-info">
              <p>
                Selecione abaixo as tarifas e prazos que mais se encaixam no
                modelo do seu negócio.
              </p>
              <br />
              <p>
                Em <b>produtos físicos</b> se aplicam o plano escolhido + envio
                do <b>código de rastreio válido.</b> Nós usamos este código para
                comprovar a legitimidade da transação e informar ao cliente cada
                passo da sua compra.
              </p>
              <br />
              <p>
                Ex.: Plano D15, o dinheiro estará disponível depois de 15 dias
                caso o código de rastreio esteja válido, caso contrário, a
                transação ficará pendente até o código ser reconhecido pelo
                sistema de rastreio.
              </p>
              <br />
              <p>
                Em <b>produtos digitais</b> existe a garantia de 7 dias exigidos
                pelo CDC (Código de Defesa do Consumidor) para o direito de
                arrependimento e o valor estará disponível para saque após este
                período no plano D2. No caso do plano D15, a garantia do cliente
                já terá expirado e você poderá sacar o valor imediatamente no
                15º dia.
              </p>
            </div>
            {companies &&
              companies?.length !== 0 &&
              companies.map((c: ICompany) => (
                <>
                  <TaxCustomContainer
                    id={c.id_code}
                    fantasyName={c.fantasy_name}
                    companyDocument={c.document}
                    boletoTax={c.boleto_tax}
                    pixTax={c.pix_tax}
                    creditCardTax={c.credit_card_tax}
                    checkoutTax={c.checkout_tax}
                    creditCardReleaseMoneyDays={
                      c.credit_card_release_money_days
                    }
                    pixReleaseMoneyDays={c.pix_release_money_days}
                    bankSlipReleaseMoneyDays={c.bank_slip_release_money_days}
                  />
                </>
              ))}

            <div className="bottom-tax-info">
              <p>Taxa fixa de R$ 1.00 por transação.</p>
              <p>
                Em boletos com o valor menor de R$ 40,00 a taxa cobrada será de
                R$ 3,00.
              </p>
            </div>
          </>
        ) : (
          <p className="no-companies-message">{noCompaniesMessage}</p>
        )}
      </Card>
    </Container>
  );
};

export default SiriusCompanies;
