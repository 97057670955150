import styled from 'styled-components';
import Card from '../../../../components/Card';
import * as color from '../../../../styles/colors';
// import avatar from '../../../assets/img/avatar.jpg';

export const Container = styled(Card)`
  .top-text {
    font-size: 13px;
    max-width: 400px;
    color: ${color.darkgrey};
    margin-bottom: 35px;
  }

  .spaced-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SectionTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #565f7e;
  margin: 14px 0 14px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 15px;

  @media (max-width: 576px) {
    justify-content: center;
  }
`;

export const Avatar = styled.div`
  position: relative;
  margin-left: 1rem !important;
  min-width: 90px;
  height: 90px;
  cursor: pointer;

  .photo {
    min-width: 90px;
    height: 90px;
    border: 4px solid ${color.orange};
    border-radius: 50px;
  }

  .icon-background {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 90px;
    height: 29px;
    opacity: 0.7;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
  }

  .icon {
    position: absolute;
    bottom: 8px;
    left: 36px;
  }
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  color: blue;
  font-style: italic;
  line-height: 2px;
`;

export const ButtonValidateAccount = styled.button`
  margin-top: 20px;
  background-color: ${color.green};
  border-radius: 4px;
  border: ${color.green};
  padding: 15px 40px;
  height: 60px;
  line-height: 1;
  color: #fff;
  font-weight: bold;

  :hover {
    background-color: #060607;
    border-color: #060607;
    transition: all ease 0.3s;
  }
`;
