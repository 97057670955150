import React, { createContext } from "react";
import useRegister from "../hooks/useRegister";

interface IRegisterContextData {
  fullName: string;
  setFullName: (e: string) => void;
  cpf: string;
  setCpf: (e: string) => void;
  email: string;
  setEmail: (e: string) => void;
  phone: string;
  setPhone: (e: string) => void;
  password: string;
  setPassword: (e: string) => void;
  confirmPassword: string;
  setConfirmPassword: (e: string) => void;
  step: number;
  nextStep: (e?: any) => void;
  previousStep: (e?: any) => void;
  inviteCode: string | null;
  setInviteCode: (e: string | null) => void;
  hasPromotionalTax: string | null;
  setHasPromotionalTax: (e: string | null) => void;
  useTerms: any;
  setUseTerms: any;
  registerFinished: any;
  setRegisterFinished: any;
  redirectLink: any;
  setRedirectLink: any;
  emailValidated: any;
  setEmailValidated: any;
  errorFinish: string | null;
  setErrorFinish: (e: string | null) => void;
  src: string | null;
  setSrc: (e: string | null) => void;
  utmSource: string | null;
  setUtmSource: (e: string | null) => void;
  utmMedium: string | null;
  setUtmMedium: (e: string | null) => void;
  utmCampaign: string | null;
  setUtmCampaign: (e: string | null) => void;
  utmTerm: string | null;
  setUtmTerm: (e: string | null) => void;
  utmContent: string | null;
  setUtmContent: (e: string | null) => void;
  utmId: string | null;
  setUtmId: (e: string | null) => void;
  Fbclid: string | null;
  setFbclid: (e: string | null) => void;
  Gclid: string | null;
  setGclid: (e: string | null) => void;
  urlData: string | null;
  setUrlData: (e: string | null) => void;
  emailCode: string | null;
  setEmailCode: (e: string | null) => void;
  phoneCode: string | null;
  setPhoneCode: (e: string | null) => void;
}

const RegisterContext = createContext<IRegisterContextData>(
  {} as IRegisterContextData
);

const RegisterProvider: React.FC = ({ children }) => {
  const {
    fullName,
    setFullName,
    cpf,
    setCpf,
    email,
    setEmail,
    phone,
    setPhone,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    step,
    nextStep,
    previousStep,
    inviteCode,
    setInviteCode,
    hasPromotionalTax,
    setHasPromotionalTax,
    useTerms,
    setUseTerms,
    registerFinished,
    setRegisterFinished,
    redirectLink,
    setRedirectLink,
    emailValidated,
    setEmailValidated,
    errorFinish,
    setErrorFinish,
    src,
    setSrc,
    utmSource,
    setUtmSource,
    utmMedium,
    setUtmMedium,
    utmCampaign,
    setUtmCampaign,
    utmTerm,
    setUtmTerm,
    utmContent,
    setUtmContent,
    utmId,
    setUtmId,
    Fbclid,
    setFbclid,
    Gclid,
    setGclid,
    urlData,
    setUrlData,
    emailCode,
    setEmailCode,
    phoneCode,
    setPhoneCode,
  } = useRegister();

  return (
    <RegisterContext.Provider
      value={{
        fullName,
        setFullName,
        cpf,
        setCpf,
        email,
        setEmail,
        phone,
        setPhone,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        step,
        nextStep,
        previousStep,
        inviteCode,
        setInviteCode,
        hasPromotionalTax,
        setHasPromotionalTax,
        useTerms,
        setUseTerms,
        registerFinished,
        setRegisterFinished,
        redirectLink,
        setRedirectLink,
        emailValidated,
        setEmailValidated,
        errorFinish,
        setErrorFinish,
        src,
        setSrc,
        utmSource,
        setUtmSource,
        utmMedium,
        setUtmMedium,
        utmCampaign,
        setUtmCampaign,
        utmTerm,
        setUtmTerm,
        utmContent,
        setUtmContent,
        utmId,
        setUtmId,
        Fbclid,
        setFbclid,
        Gclid,
        setGclid,
        urlData,
        setUrlData,
        emailCode,
    setEmailCode,
    phoneCode,
    setPhoneCode,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

export { RegisterContext, RegisterProvider };
