/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from './styles';
import GobackLink from '../../../../components/GoBackLink';
// import CompanyProfile from './CompanyProfile';
// import CompanyLinkedServices from './CompanyLinkedServices';
import CompanyAddressForm from './CompanyAddressForm';
import CompanyProfileForm from './CompanyProfileForm';
import CompanyDocuments from './CompanyDocuments';
import PageTopbar from '../../../../components/PageTopbar';
import api from '../../../../services/api';
import { ICompany } from '../../../../entities/Company';
import FormBankAccount from '../../FormBankAccount';
import { CreateBankAccountProvider } from '../../../../context/CreateBankAccountContext';
// import { ViewBankAccountContext } from '../../../../context/CreateBankAccountContext';
interface CompanyProps {
  id: string;
}

interface formBankAccount {
  id: string;
  option: number;
}

const CompanyDetail: React.FC = () => {
  // const { company, setCompany } = useContext(Context);
  const [company, setCompany] = useState<ICompany>();
  const { id } = useParams<CompanyProps>();
  //const {option, setOption} = useState<formBankAccount>();
  // const { setViewCard } = useContext(ViewBankAccountContext);

  const dataFromCompanyDocuments = useCallback(data => {
    setCompany(data);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/companies');
        // setCompanies(response.data.data);
        const comp = response.data.data;
        const findCompany = comp.find((c: ICompany) => c.id_code == id);
        setCompany(findCompany);
      } catch (error) {}
    })();
  }, [id, setCompany]);

  return (
    <Container>
      <PageTopbar
        title="Empresas"
        subTitle="Gerencie as empresas relacionadas com seus produtos Azcend"
      />
      <GobackLink to="/companies" className="goback-button" />
      {/* <CompanyProfile /> */}
      {/* <CompanyLinkedServices /> */}

      <CompanyProfileForm company={company} />

      {company?.status === 'Aprovado' && (
        <CreateBankAccountProvider>
          <FormBankAccount
            company={company}
            sendDataToCompanyDetail={dataFromCompanyDocuments}
          />
        </CreateBankAccountProvider>
      )}
      
      {(company?.zip_code == null ||
      company?.city == null ||
      company?.state == null ||
      company?.street == null ||
      company?.support_telephone == null) &&
      ((company?.bank_accounts?.filter((x) => x.status=='VERIFIED')?.length? 1 : 0) > 0) && (
      <CompanyAddressForm company={company} />
      )}
      
      {company && company?.status != 'Aprovado' && (
        <CompanyDocuments
          company={company}
          sendDataToCompanyDetail={dataFromCompanyDocuments}
        />
      )}
    </Container>
  );
};

export default CompanyDetail;
