/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable no-redeclare */
/* eslint-disable no-var */
/* eslint-disable block-scoped-var */
/* eslint-disable eqeqeq */
export function isValidEmailAddress(address) {
  return !!address.match(/.+@.+/);
}

export function isValidCNPJ(val) {
  var cnpj = val.trim();

  cnpj = cnpj.replace(/\./g, '');
  cnpj = cnpj.replace('-', '');
  cnpj = cnpj.replace('/', '');
  cnpj = cnpj.split('');

  var v1 = 0;
  var v2 = 0;
  var aux = false;

  for (var i = 1; cnpj.length > i; i++) {
    if (cnpj[i - 1] != cnpj[i]) {
      aux = true;
    }
  }

  if (aux == false) {
    return false;
  }

  for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
    if (p1 >= 2) {
      v1 += cnpj[i] * p1;
    } else {
      v1 += cnpj[i] * p2;
    }
  }

  v1 %= 11;

  if (v1 < 2) {
    v1 = 0;
  } else {
    v1 = 11 - v1;
  }

  if (v1 != cnpj[12]) {
    return false;
  }

  for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
    if (p1 >= 2) {
      v2 += cnpj[i] * p1;
    } else {
      v2 += cnpj[i] * p2;
    }
  }

  v2 %= 11;

  if (v2 < 2) {
    v2 = 0;
  } else {
    v2 = 11 - v2;
  }

  if (v2 != cnpj[13]) {
    return false;
  }
  return true;
}

export function isValidFileType(file) {
  if (
    (file.type === 'image/png' && file.size < 10000000) ||
    (file.type === 'image/jpeg' && file.size < 10000000) ||
    (file.type === 'application/pdf' && file.size < 10000000)
  ) {
    return true;
  }
  return false;
}

export function getFomattedDate(date){
  var date = new Date(date);
  var day  = date.getDate().toString();
  var dayF = (day.length == 1) ? '0'+day : day;
  
  var month  = (date.getMonth()+1).toString();
  var monthF = (month.length == 1) ? '0'+month : month;
  
  var yearF = date.getFullYear();

  var hour = (date.getHours()).toString();
  var hourF =  (hour.length == 1) ? '0' + hour : hour;
  
  var minutes = (date.getMinutes()).toString();
  var minutesF =  (minutes.length == 1) ? '0' + minutes : minutes;
  
  return dayF+"/"+monthF+"/"+yearF+" "+hourF+":"+minutesF;
}

export function getMinuteDiff(startDate, endDate){

  var startDate = new Date(startDate)
  var endDate = new Date(endDate)

  var diff =(endDate.getTime() - startDate.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export default function emptyCache(){
  if('caches' in window){
  caches.keys().then((names) => {
          names.forEach(name => {
              caches.delete(name);
          })
      });
  }
}


export const hasOneLetter = new RegExp("^(?=.*[A-Za-z])");
export const hasOneNumber = new RegExp("^(?=.*[0-9])");

export const hasEightChar = new RegExp("^(?=.{8,})");
export const hasCapitalLowerLetters = new RegExp("^(?=.*[A-Z][a-z]*)");
export const hasSpecialChar = new RegExp("^(?=.*[^A-Za-z0-9_])(?=.*[0-9])", 'g');

