import React, { useEffect, useContext } from 'react';
import { Container } from './styles';
import { RegisterContext as Context } from '../../../context/RegisterContext';
import AnimatedPurpleContainerPage from '../../../components/AnimatedPurpleContainerPage';
import FininishComponent from '../../../components/FininishComponent';

const FinishRegister: React.FC = () => {
  const { email, phone, redirectLink } = useContext(Context);

  return (
    <AnimatedPurpleContainerPage loading={0} intro={false}>
      <FininishComponent
        status={'success'}
        title={'Cadastro realizado com sucesso! :)'}
        description={'Agora vamos te redirecionar ao dashboard!!'}
        buttonText={'IR PARA A MINHA CONTA'}
        redirectLink={redirectLink}
      />
    </AnimatedPurpleContainerPage>
  );
};

export default FinishRegister;
