import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Subtile,
  ValidatePixContainer,
  LinkButton,
  KeyTypeRadioLabel,
  KeyTypeRadioOption,
  KeyTypeRadioGroup,
  ContentPixKey,
  LabelPixKey,
} from './styles';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { Label } from '../../../../components/Input/styles';
import redDot from '../../../../assets/red_dot.svg';
import orangeDot from '../../../../assets/orange_dot.svg';
import greenDot from '../../../../assets/green_dot.svg';
import { ViewBankAccountContext } from '../../../../context/CreateBankAccountContext';
import { ICompany } from '../../../../entities/Company';
import api from '../../../../services/api';
import pusher from '../../../../services/pusher';
import Modal from '../../../../components/ModalAdmin';
import useToggle from '../../../../hooks/useToggle';

import ModalSendingData from '../ModalSendingData';
import ModalValidationReject from '../ModalValidationReject';
import ModalWaiting from '../ModalWaiting';
import ModalValidationSuccess from '../ModalValidationSuccess';

import AlertBox from '../../../../components/AlertBox';
import { exit } from 'process';
import { validateEmail } from '../../../../utils/functions';
interface transferTypeProps {
  company: ICompany | undefined;
}

const CardPIX: React.FC<transferTypeProps> = ({ company }) => {
  const { setViewCard, translateTypeKey } = useContext(ViewBankAccountContext);
  const [values, setValues] = useState({
    company_id: company?.id,
    transfer_type: 'PIX',
    type_key_pix: '',
    key_pix: '',
  });

  const [typeKeyPix, setTypeKeyPix] = useState<string>('CNPJ');
  const [keyPix, setKeyPix] = useState<string>('');
  const [keyPixLabel, setKeyPixLabel] = useState<string>('');
  const [disabledInput, setDisabledInput] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  const [showModalSending, setShowModalSending] = useState<boolean>(false);
  const [showModalValidationSuccess, setShowModalValidationSuccess] =
    useState<boolean>(false);
  const [showModalValidationReject, setShowModalValidationReject] =
    useState<boolean>(false);
  const [showModalWaiting, setShowModalWaiting] = useState<boolean>(false);

  const [hashId, setHashId] = useState<string>('');
  const [statusPusher, setStatusPusher] = useState<string>('');

  const [loading, setLoading] = useState<number>(0);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertShow, setAlertShow] = useState<boolean>(false);
  const [statusVerify, setStatusVerify] = useState<string>('PENDING');
  const [alertType, setAlertType] = useState<string>('warning');
  const [processing, setProcessing] = useState<boolean>(false);

  const [th, setTh] = useState<any>(null);

  useEffect(() => {
    const channel = pusher.subscribe(`validation-pix-${company?.id_code}`);

    channel.bind('change-status', (data: any) => {
      if (data.status && data.bankAccountId) {
        setStatusPusher(data.status);
      }
    });
  }, []);

  useEffect(() => {
    if (statusPusher) {
      verifyStatusKey(statusPusher);
    }
  }, [statusPusher]);

  useEffect(() => {
    switch (statusVerify) {
      case 'PROCESSING':
      case 'PENDING':
        setAlertType('warning');
        break;
      case 'VERIFIED':
        clearTimeout(th);
        setAlertType('success');
        setShowModalSending(false);
        setShowModalValidationSuccess(true);
        break;
      case 'REFUSED':
        clearTimeout(th);
        setAlertType('danger');
        setShowModalSending(false);
        setShowModalValidationReject(true);
        setProcessing(false);
        break;
      case 'ERROR':
        setAlertType('danger');
        setShowModalSending(false);
        setProcessing(false);
        break;
    }
  }, [statusVerify]);

  useEffect(() => {
    switch (typeKeyPix) {
      case 'CPF':
        setKeyPixLabel('Cpf');
        break;
      case 'CNPJ':
        setKeyPixLabel('Cnpj');
        break;
      case 'EMAIL':
        setKeyPixLabel('E-mail');
        break;
      case 'TELEFONE':
        setKeyPixLabel('Celular');
        break;
      case 'CHAVE_ALEATORIA':
        setKeyPixLabel('Chave Aleatória');
        break;
      default:
        setKeyPixLabel('');
    }
  }, [typeKeyPix]);

  const handleSelectPixType = (e: any) => {
    setTypeKeyPix(e.target.value);

    setDisabledInput(false);
    setDisabledButton(true);

    setKeyPix('');
    if (e.target.value === 'CNPJ' || e.target.value === 'CPF') {
      setKeyPix(company?.document ?? '');
      setDisabledInput(true);
      setDisabledButton(false);
    }
  };

  const handleChangeInput = (e: any) => {
    setDisabledButton(false);
    if (e.target.value === '') {
      setDisabledButton(true);
    }

    switch (typeKeyPix) {
      case 'EMAIL':
        setDisabledButton(!validateEmail(e.target.value));
        break;
      case 'TELEFONE':
        setDisabledButton(!phoneValidate(e.target.value));
        break;
      case 'CHAVE_ALEATORIA':
        setDisabledButton(e.target.value.length != 36);
        break;
    }

    setKeyPix(e.target.value);
  };

  const handleVerifyKeyPix = async () => {
    const formData = new FormData();
    formData.append('hash_id', hashId);
    formData.append('company_id', (company?.id ?? '').toString());
    formData.append('transfer_type', 'PIX');
    formData.append('type_key_pix', typeKeyPix);
    formData.append('key_pix', keyPix);

    setProcessing(true);
    setShowModalSending(true);
    await api
      .post(`bank-account/verify/pix-key`, formData)
      .then(response => {
        if (response.data) {
          setAlertShow(true);
          setHashId(response.data.hashId);
          setAlertMessage(
            `Chave enviada para verificação. Aguarde só mais alguns minutos.`
          );
          setStatusVerify(response.data.status);

          let thTemp = setTimeout(() => {
            setShowModalSending(false);
            setShowModalWaiting(true);
          }, 60000);
          setTh(thTemp);
        }
      })
      .catch(error => {
        setProcessing(false);
        setAlertShow(true);
        setStatusVerify('ERROR');
        setDisabledButton(false);
        let message = `Estamos com algumas dificultades técnicas, tente novamente mais tarde!`;
        if (error.response) {
          if (error.response.data.message) {
            message = error.response.data.message;
          }
        }
        setAlertMessage(message);
      });
  };

  const verifyStatusKey = (status: string) => {
    let message = '';
    setStatusVerify(status);

    switch (status) {
      case 'VERIFIED':
        setAlertMessage(`Sua chave PIX foi validada com sucesso.`);
        break;
      case 'REFUSED':
        message = `Não conseguimos validar sua chave de tipo ${translateTypeKey(
          typeKeyPix
        )} ${keyPix}. Por favor, utilize uma chave válida.`;
        setAlertMessage(message);
        setAlertShow(true);
        setDisabledButton(false);
        break;
    }
  };

  const getIconSvg = (option: string) => {
    switch (option) {
      case 'CNPJ':
      case 'CPF':
        return (
          <svg
            width="12"
            height="16"
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 0C0.89543 0 0 0.895431 0 2V14C0 15.1046 0.89543 16 2 16H10C11.1046 16 12 15.1046 12 14V5.41421C12 5.01639 11.842 4.63486 11.5607 4.35355L7.64645 0.43934C7.36514 0.158035 6.98361 0 6.58579 0H2ZM1 2C1 1.44772 1.44772 1 2 1H6V4.5C6 5.32843 6.67157 6 7.5 6H11V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V2ZM10.7929 5H7.5C7.22386 5 7 4.77614 7 4.5V1.20711L10.7929 5Z"
              fill="#56607E"
            />
          </svg>
        );
      case 'EMAIL':
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.5 4C16.8807 4 18 5.11929 18 6.5V14.5C18 15.8807 16.8807 17 15.5 17H4.5C3.11929 17 2 15.8807 2 14.5V6.5C2 5.11929 3.11929 4 4.5 4H15.5ZM17 7.961L10.2535 11.931C10.1231 12.0077 9.96661 12.0205 9.82751 11.9693L9.74649 11.931L3 7.963V14.5C3 15.3284 3.67157 16 4.5 16H15.5C16.3284 16 17 15.3284 17 14.5V7.961ZM15.5 5H4.5C3.67157 5 3 5.67157 3 6.5V6.802L10 10.9199L17 6.801V6.5C17 5.67157 16.3284 5 15.5 5Z"
              fill="#56607E"
            />
          </svg>
        );
      case 'TELEFONE':
        return (
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 12C3.72386 12 3.5 12.2239 3.5 12.5C3.5 12.7761 3.72386 13 4 13H6C6.27614 13 6.5 12.7761 6.5 12.5C6.5 12.2239 6.27614 12 6 12H4ZM2 0C0.89543 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H8C9.10457 16 10 15.1046 10 14V2C10 0.89543 9.10457 0 8 0H2ZM1 2C1 1.44772 1.44772 1 2 1H8C8.55228 1 9 1.44772 9 2V14C9 14.5523 8.55228 15 8 15H2C1.44772 15 1 14.5523 1 14V2Z"
              fill="#56607E"
            />
          </svg>
        );
      case 'CHAVE_ALEATORIA':
        return (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.10487 3.0112C6.37488 3.06906 6.54686 3.33486 6.489 3.60487L6.19005 5H8.66666L9.01033 3.39539C9.06816 3.12537 9.33394 2.95336 9.60396 3.01119C9.87398 3.06902 10.046 3.3348 9.98816 3.60482L9.68934 5H10.5C10.7761 5 11 5.22386 11 5.5C11 5.77614 10.7761 6 10.5 6H9.47516L9.04681 8H10C10.2761 8 10.5 8.22386 10.5 8.5C10.5 8.77614 10.2761 9 10 9H8.83263L8.48902 10.6043C8.43118 10.8744 8.16541 11.0464 7.89539 10.9885C7.62537 10.9307 7.45336 10.6649 7.51119 10.3949L7.80995 9H5.3329L4.989 10.6049C4.93114 10.8749 4.66535 11.0469 4.39534 10.989C4.12533 10.9311 3.95334 10.6654 4.0112 10.3953L4.3102 9H3.5C3.22386 9 3 8.77614 3 8.5C3 8.22386 3.22386 8 3.5 8H4.52449L4.95306 6H4C3.72386 6 3.5 5.77614 3.5 5.5C3.5 5.22386 3.72386 5 4 5H5.16735L5.5112 3.39534C5.56906 3.12533 5.83486 2.95334 6.10487 3.0112ZM5.54719 8H8.02413L8.45248 6H5.97576L5.54719 8ZM0 2.5C0 1.11929 1.11929 0 2.5 0H11.5C12.8807 0 14 1.11929 14 2.5V11.5C14 12.8807 12.8807 14 11.5 14H2.5C1.11929 14 0 12.8807 0 11.5V2.5ZM2.5 1C1.67157 1 1 1.67157 1 2.5V11.5C1 12.3284 1.67157 13 2.5 13H11.5C12.3284 13 13 12.3284 13 11.5V2.5C13 1.67157 12.3284 1 11.5 1H2.5Z"
              fill="#56607E"
            />
          </svg>
        );
    }
  };

  const handleClickButton = () => {
    if (statusVerify === 'PENDING') {
      handleVerifyKeyPix();
    } else {
      setKeyPix('');
      setAlertShow(false);
      setDisabledButton(true);
      setStatusVerify('PENDING');
    }
  };

  const invalidPhoneNumbers = [
    '5511111111111',
    '5522222222222',
    '5533333333333',
    '5544444444444',
    '5555555555555',
    '5566666666666',
    '5577777777777',
    '5588888888888',
    '5599999999999',
    '5500000000000',
  ];
  const phoneValidate = (phone: string) => {
    if (!phone) {
      return false;
    }
    if (phone.length != 11) {
      return false;
    }

    if (invalidPhoneNumbers.includes(phone)) {
      return false;
    }
    return true;
  };

  const handleCloseAlert = () => {
    setAlertShow(false);
  };

  const handleCloseRedirectList = () => {
    window.location.reload();
    //setViewCard('');
  };

  const modalSendingData = (
    <Modal
      isOpen={showModalSending}
      onRequestClose={() => setShowModalSending(false)}
      closeModal={() => setShowModalSending(false)}
      smallWidth={true}>
      <ModalSendingData title={hashId ? 'Aguardando confirmação...' : ''} />
    </Modal>
  );

  const modalValidationSuccess = (
    <Modal
      isOpen={showModalValidationSuccess}
      onRequestClose={handleCloseRedirectList}
      closeModal={handleCloseRedirectList}
      smallWidth={true}>
      <ModalValidationSuccess
        onCancel={handleCloseRedirectList}
        transferType="PIX"
      />
    </Modal>
  );

  const modalValidationReject = (
    <Modal
      isOpen={showModalValidationReject}
      onRequestClose={handleCloseRedirectList}
      closeModal={handleCloseRedirectList}
      smallWidth={true}>
      <ModalValidationReject
        onCancel={handleCloseRedirectList}
        onConfirm={() => setShowModalValidationReject(false)}
        transferType="PIX"
      />
    </Modal>
  );

  const modalWaiting = (
    <Modal
      isOpen={showModalWaiting}
      onRequestClose={handleCloseRedirectList}
      closeModal={handleCloseRedirectList}
      smallWidth={true}>
      <ModalWaiting onConfirm={handleCloseRedirectList} />
    </Modal>
  );

  return (
    <>
      <Container cardTitle="Recebimentos via PIX" loading={loading}>
        <Subtile>
          Para ativar saques 24h por dia, 7 dias por semana, confirme uma chave
          PIX.
        </Subtile>
        <Subtile>
          <strong>
            Sua chave PIX deve estar associada ao{' '}
            {company?.type === 1
              ? 'CPF do seu cadastro'
              : 'CNPJ da sua empresa'}
            .
          </strong>
        </Subtile>

        {alertShow && (
          <AlertBox
            type={alertType}
            message={alertMessage}
            closeBarOnClick={handleCloseAlert}></AlertBox>
        )}

        {modalValidationSuccess}
        {/* {modalSendingData} */}
        {/* {modalValidationSuccess} */}
        {/* {modalValidationReject} */}
        {/* {modalWaiting} */}

        {statusVerify === 'PENDING' && (
          <>
            <br />
            <Label>Tipo de chave</Label>
            <KeyTypeRadioGroup onChange={handleSelectPixType}>
              <KeyTypeRadioOption
                id="typeCNPJ"
                type="radio"
                name="typeKeyPix"
                value={company?.type === 1 ? 'CPF' : 'CNPJ'}
              />
              <KeyTypeRadioLabel htmlFor="typeCNPJ">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 12 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2 0C0.89543 0 0 0.895431 0 2V14C0 15.1046 0.89543 16 2 16H10C11.1046 16 12 15.1046 12 14V5.41421C12 5.01639 11.842 4.63486 11.5607 4.35355L7.64645 0.43934C7.36514 0.158035 6.98361 0 6.58579 0H2ZM1 2C1 1.44772 1.44772 1 2 1H6V4.5C6 5.32843 6.67157 6 7.5 6H11V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V2ZM10.7929 5H7.5C7.22386 5 7 4.77614 7 4.5V1.20711L10.7929 5Z"
                    fill="#56607E"
                  />
                </svg>
                <span>{company?.type === 1 ? 'CPF' : 'CNPJ'}</span>
              </KeyTypeRadioLabel>

              <KeyTypeRadioOption
                id="typeEmail"
                type="radio"
                name="typeKeyPix"
                value="EMAIL"
              />
              <KeyTypeRadioLabel htmlFor="typeEmail">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.5 4C16.8807 4 18 5.11929 18 6.5V14.5C18 15.8807 16.8807 17 15.5 17H4.5C3.11929 17 2 15.8807 2 14.5V6.5C2 5.11929 3.11929 4 4.5 4H15.5ZM17 7.961L10.2535 11.931C10.1231 12.0077 9.96661 12.0205 9.82751 11.9693L9.74649 11.931L3 7.963V14.5C3 15.3284 3.67157 16 4.5 16H15.5C16.3284 16 17 15.3284 17 14.5V7.961ZM15.5 5H4.5C3.67157 5 3 5.67157 3 6.5V6.802L10 10.9199L17 6.801V6.5C17 5.67157 16.3284 5 15.5 5Z"
                    fill="#56607E"
                  />
                </svg>
                <span>E-mail</span>
              </KeyTypeRadioLabel>

              <KeyTypeRadioOption
                id="typeCelphone"
                type="radio"
                name="typeKeyPix"
                value="TELEFONE"
              />
              <KeyTypeRadioLabel htmlFor="typeCelphone">
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4 12C3.72386 12 3.5 12.2239 3.5 12.5C3.5 12.7761 3.72386 13 4 13H6C6.27614 13 6.5 12.7761 6.5 12.5C6.5 12.2239 6.27614 12 6 12H4ZM2 0C0.89543 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H8C9.10457 16 10 15.1046 10 14V2C10 0.89543 9.10457 0 8 0H2ZM1 2C1 1.44772 1.44772 1 2 1H8C8.55228 1 9 1.44772 9 2V14C9 14.5523 8.55228 15 8 15H2C1.44772 15 1 14.5523 1 14V2Z"
                    fill="#56607E"
                  />
                </svg>
                <span>Celular</span>
              </KeyTypeRadioLabel>

              <KeyTypeRadioOption
                id="typeRandom"
                type="radio"
                name="typeKeyPix"
                value="CHAVE_ALEATORIA"
              />
              <KeyTypeRadioLabel htmlFor="typeRandom">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.10487 3.0112C6.37488 3.06906 6.54686 3.33486 6.489 3.60487L6.19005 5H8.66666L9.01033 3.39539C9.06816 3.12537 9.33394 2.95336 9.60396 3.01119C9.87398 3.06902 10.046 3.3348 9.98816 3.60482L9.68934 5H10.5C10.7761 5 11 5.22386 11 5.5C11 5.77614 10.7761 6 10.5 6H9.47516L9.04681 8H10C10.2761 8 10.5 8.22386 10.5 8.5C10.5 8.77614 10.2761 9 10 9H8.83263L8.48902 10.6043C8.43118 10.8744 8.16541 11.0464 7.89539 10.9885C7.62537 10.9307 7.45336 10.6649 7.51119 10.3949L7.80995 9H5.3329L4.989 10.6049C4.93114 10.8749 4.66535 11.0469 4.39534 10.989C4.12533 10.9311 3.95334 10.6654 4.0112 10.3953L4.3102 9H3.5C3.22386 9 3 8.77614 3 8.5C3 8.22386 3.22386 8 3.5 8H4.52449L4.95306 6H4C3.72386 6 3.5 5.77614 3.5 5.5C3.5 5.22386 3.72386 5 4 5H5.16735L5.5112 3.39534C5.56906 3.12533 5.83486 2.95334 6.10487 3.0112ZM5.54719 8H8.02413L8.45248 6H5.97576L5.54719 8ZM0 2.5C0 1.11929 1.11929 0 2.5 0H11.5C12.8807 0 14 1.11929 14 2.5V11.5C14 12.8807 12.8807 14 11.5 14H2.5C1.11929 14 0 12.8807 0 11.5V2.5ZM2.5 1C1.67157 1 1 1.67157 1 2.5V11.5C1 12.3284 1.67157 13 2.5 13H11.5C12.3284 13 13 12.3284 13 11.5V2.5C13 1.67157 12.3284 1 11.5 1H2.5Z"
                    fill="#56607E"
                  />
                </svg>
                <span>Aleatória</span>
              </KeyTypeRadioLabel>
            </KeyTypeRadioGroup>
          </>
        )}

        <ContentPixKey>
          {statusVerify != 'PENDING' && (
            <LabelPixKey>
              <h6>Tipo de chave</h6>
              <div>
                {getIconSvg(typeKeyPix)}
                <span>{keyPixLabel}</span>
              </div>
            </LabelPixKey>
          )}

          <Input
            label="Chave Pix"
            id="form-key-pix"
            name="keyPix"
            mask=""
            type="text"
            placeholder="Insira sua chave pix"
            className="pix-input"
            disabled={disabledInput}
            value={keyPix}
            defaultValue={values.key_pix}
            onChange={handleChangeInput}
          />
        </ContentPixKey>

        <>
          <ValidatePixContainer>
            {(statusVerify === 'PENDING' || statusVerify === 'ERROR') && (
              <p className="status-pix">
                <img src={orangeDot} alt="Verifique sua chave" />
                Para confirmar, solicite a verificação
              </p>
            )}
            {statusVerify === 'PROCESSING' && (
              <p className="status-pix">
                <img src={orangeDot} alt="Verifique sua chave" />
                Sua chave está em verificação
              </p>
            )}
            {statusVerify === 'VERIFIED' && (
              <p className="status-pix">
                <img src={greenDot} alt="Chave verificada" />
                Chave verificada
              </p>
            )}
            {statusVerify === 'REFUSED' && (
              <p className="status-pix">
                <img src={redDot} alt="Chave inexistente" />
                Chave PIX inválida
              </p>
            )}

            {!processing && (
              <Button
                onClick={handleClickButton}
                primaryPill
                type="button"
                disabled={disabledButton}>
                {statusVerify === 'PENDING'
                  ? 'Solicitar verificação de chave'
                  : 'Validar outra chave'}
              </Button>
            )}
          </ValidatePixContainer>
        </>
      </Container>
      {!processing && (
        <LinkButton
          onClick={() => {
            setViewCard('TED');
          }}>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22.6449 10.2632L3.35587 0.758477C3.21984 0.691451 3.06417 0.675896 2.91757 0.714681C2.58221 0.803411 2.38227 1.14721 2.471 1.48257L4.94461 10.8319L2.47144 20.1704C2.4326 20.317 2.44814 20.4728 2.51519 20.6088C2.66853 20.92 3.04509 21.048 3.35626 20.8946L11.2749 16.9927C11.3263 16.4501 11.4405 15.9258 11.6102 15.427L4.03921 19.1576L6.10321 11.3613C6.14065 11.3683 6.17925 11.3719 6.2187 11.3719H15.0125L15.1254 11.3618C15.4184 11.3086 15.6406 11.0521 15.6406 10.7438C15.6406 10.3969 15.3594 10.1157 15.0125 10.1157H6.2187L6.13774 10.1208L6.05987 10.1359L4.03842 2.49529L20.5936 10.6529L22.4028 11.5094L22.6449 11.3901C22.7691 11.3289 22.8695 11.2284 22.9307 11.1043C23.084 10.7931 22.9561 10.4166 22.6449 10.2632ZM23.8062 17.6532C23.8062 20.7753 21.2752 23.3063 18.1531 23.3063C15.0309 23.3063 12.5 20.7753 12.5 17.6532C12.5 14.531 15.0309 12 18.1531 12C21.2752 12 23.8062 14.531 23.8062 17.6532ZM18.1531 14.5125C17.8062 14.5125 17.525 14.7938 17.525 15.1407V17.6532C17.525 18.0001 17.8062 18.2813 18.1531 18.2813H20.0374C20.3844 18.2813 20.6656 18.0001 20.6656 17.6532C20.6656 17.3063 20.3844 17.025 20.0374 17.025H18.7812V15.1407C18.7812 14.7938 18.5 14.5125 18.1531 14.5125Z"
              fill="#0FBF7F"
            />
          </svg>
          <span>Quero receber via TED</span>
        </LinkButton>
      )}
    </>
  );
};

export default CardPIX;
