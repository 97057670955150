import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Container } from './styles';
import FormWrapper from '../../../../../components/Form';
import Input from '../../../../../components/Input';
import InputCep from '../../../../../components/InputCep';
import Button from '../../../../../components/Button';
import { ICompany } from '../../../../../entities/Company';
import api from '../../../../../services/api';
import { AccountsContext as Context } from '../../../../../context/AccountsContext';
import { string } from 'yup';
import InputPhone from '../../../../../components/FormInputs/InputPhone';

interface CompanyProfileProps {
  company: ICompany | undefined;
}

type FormData = {
  city: string;
  state: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  zip_code: string;
  support_telephone: string;
};

const CompanyAddressForm: React.FC<CompanyProfileProps> = ({ company }) => {
  const { setDisableUploadCompanyAddressProveDocuments } = useContext(Context);
  const [loading, setLoading] = useState<number>(0);
  const [canSaveForThisTime, setSaving] = useState<boolean>(false);

  const { register, reset, setValue, handleSubmit, errors } = useForm<FormData>(
    {
      defaultValues: {
        ...company,
      },
    },
  );

  useEffect(() => {
    reset({
      ...company,
    });

    if (
      company?.zip_code === '' ||
      company?.zip_code === null ||
      company?.street === '' ||
      company?.street === null ||
      company?.city === '' ||
      company?.city === null ||
      company?.state === '' ||
      company?.state === null ||
      company?.neighborhood === '' ||
      company?.neighborhood === null ||
      company?.support_telephone === '' ||
      company?.support_telephone === null
    ) {
      setDisableUploadCompanyAddressProveDocuments(true);
    } else {
      setDisableUploadCompanyAddressProveDocuments(false);
    }
  }, [company, reset, setDisableUploadCompanyAddressProveDocuments]);

  const updateAddressFromAPI = useCallback(
    async () => {
      setLoading(1);
      try {
        let obj: { cnpj: string } = { cnpj: '' };
        obj.cnpj = company?.document?.replace(/\D/g, '') || '';
        const response = await api.post('/companies/consult-cnpj', obj);

        if (response.data.message) {
          toast.error(response.data.message);
          setSaving(true); //indepente do erro, seta como true para o usuário poder atualizar o endereço manualmente
          setLoading(0);
          return;
        }

        setValue('zip_code', response.data.endereco.cep);
        setValue('street', response.data.endereco.logradouro);
        setValue('number', response.data.endereco.numero);
        setValue('neighborhood', response.data.bairro);
        setValue('city', response.data.endereco.municipio.descricao);
        setValue('state', response.data.endereco.uf);
        setValue('complement', response.data.endereco.complemento);
        setValue('support_telephone', response.data.telefones[0].ddd  . response.data.telefones[0].numero);
        


        if(company) {
        company.zip_code = response.data.endereco.cep;
        company.street = response.data.endereco.logradouro;
        company.number = response.data.endereco.numero;
        company.neighborhood = response.data.endereco.bairro;
        company.city = response.data.endereco.municipio.descricao;
        company.state = response.data.endereco.uf;
        company.complement = response.data.endereco.complemento;
        company.support_telephone = response.data.telefones[0].ddd  . response.data.telefones[0].numero;
        }	

        setSaving(true);
        setLoading(0);

      } catch (error) {

        setSaving(true); //indepente do erro, seta como true para o usuário poder atualizar o endereço manualmente
        setLoading(0);
        
        if (error.response.data.errors) {
          toast.error(error.response.data.errors.join(', '));
        } else {
          toast.error(error.response.data.message);
        }
      }
    },
    [company, canSaveForThisTime, setValue],
  );
  
  const onSubmit = useCallback(
    async (data: FormData) => {
      setLoading(1);
      try {
        await api.put(`/companies/address/${company?.id_code}`, {
          ...data?.zip_code && data || company,
          country: 'brazil',
          isDataFromAPI: canSaveForThisTime && true || false,
        });
        setLoading(0);
        toast.success('Endereço atualizado com sucesso!');
        setDisableUploadCompanyAddressProveDocuments(false);
      } catch (error) {
        toast.error(error.response.data.message);
        setLoading(0);
      }
      setSaving(false);
    },
    [company, canSaveForThisTime, setValue, setDisableUploadCompanyAddressProveDocuments],
  );

  return (
    <Container cardTitle="Endereço" loading={loading}>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <InputCep
            label="CEP"
            id="zipcode"
            name="zip_code"
            className="col-xs-12 col-sm-3"
            type="text"
            mask="99999-999"
            value={company?.zip_code}
            setValue={setValue}
            setLoading={setLoading}
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            disabled={!company?.can_edit_address_information && !canSaveForThisTime}
            errorMessage={errors.zip_code?.message}
          />
          <Input
            mask=""
            label="Rua"
            id="street"
            name="street"
            className="col-xs-12 col-sm-7"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
              minLength: {
                value: 3,
                message: 'Obrigatorio pelo menos 3 caracteres',
              },
            })}
            disabled={!company?.can_edit_address_information && !canSaveForThisTime}
            errorMessage={errors.street?.message}
          />
          <Input
            mask=""
            label="Número"
            id="number"
            name="number"
            className="col-xs-6 col-sm-2"
            register={register}
            disabled={!company?.can_edit_address_information && !canSaveForThisTime}
          />
          <Input
            mask=""
            label="Complemento"
            id="complement"
            name="complement"
            className="col-xs-6 col-sm-5"
            register={register}
            disabled={!company?.can_edit_address_information && !canSaveForThisTime}
          />
          <Input
            mask=""
            label="Cidade"
            id="city"
            name="city"
            className="col-xs-6 col-sm-5"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            disabled={!company?.can_edit_address_information && !canSaveForThisTime}
            errorMessage={errors.city?.message}
          />
          <Input
            mask=""
            label="Estado"
            id="state"
            name="state"
            className="col-xs-6 col-sm-2"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            disabled={!company?.can_edit_address_information && !canSaveForThisTime}
            errorMessage={errors.state?.message}
          />
          <Input
            label="Bairro"
            id="neighborhood"
            name="neighborhood"
            className="col-xs-6 col-sm-5"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            disabled={!company?.can_edit_address_information && !canSaveForThisTime}
            errorMessage={errors.neighborhood?.message}
          />
          <Input
            label="Telefone"
            id="support_telephone"
            name="support_telephone"
            className="col-xs-6 col-sm-5"
            type="text"
            mask="(99)99999-9999"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            disabled={!company?.can_edit_address_information && !canSaveForThisTime}
            errorMessage={errors.support_telephone?.message}
          />
          {/* Has to be a SELECT */}
          {/* <Input
            mask=""
            label="País"
            id="country"
            name="country"
            className="col-xs-6 col-sm-5"
          /> */}
        </div>
        {(company?.can_edit_address_information || canSaveForThisTime) && (
          <div
            className="row"
            style={{ margin: '20px 0 0 0', justifyContent: 'flex-end' }}
          >
            <Button primaryPill>Salvar</Button>
          </div>
        )}
      </FormWrapper>
      <div
            className="row"
            style={{ margin: '20px 0 0 0', justifyContent: 'flex-end', display: !canSaveForThisTime && 'block' || 'none'}}
            
          >
            <Button primaryOutline onClick={updateAddressFromAPI}>
              Atualizar endereço
            </Button>
      </div>
    </Container>
  );
};

export default CompanyAddressForm;
