import styled from 'styled-components';
import Card from '../Card';
import * as color from '../../styles/colors';

interface SmallCardProps {
  primary?: boolean;
  secondary?: boolean;
}

export const CardContainer = styled(Card)`
  padding: 30px 30px 0 30px;
  height: 233px;
  width: 397px;

  .card-title {
    margin-bottom: 20px;
    max-width: 285px;
  }

  @media (max-width: 765px) {
    width: 100%;
  }
`;

export const CardFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  height: 50px;
  width: 100%;
  border-top: 1px solid rgba(86, 96, 126, 0.12);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    background: #f7f7f7;
  }
`;

export const ContentText = styled.p<SmallCardProps>`
  font-size: 14px;
  color: ${({ primary }) =>
    primary ? color.orange : color.darkgrey} !important;
`;

export const RedCircle = styled.div`
  position: absolute;
  height: 60px;
  width: 60px;
  right: 12px;
  top: 10px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fc0134;

  img {
    filter: brightness(0) invert(1);
    height: 40px;
    width: 40px;
  }
`;

export const Icon = styled.img`
  position: absolute;
  width: 40px;
  height: 40px;
  right: 16px;
  top: 16px;
`;
