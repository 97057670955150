import styled, { css } from 'styled-components';
import { FiCheck, FiX } from 'react-icons/fi';
import MaskedInput from 'react-input-mask';
import * as color from '../../styles/colors';

interface AdminInputProps {
  status?: number;
}

export const CheckIcon = styled(FiCheck)`
  position: absolute;
  left: 25px;
  top: 40px;
  color: ${color.green};
  font-size: 24px;
`;

export const PendingIcon = styled(FiX)`
  position: absolute;
  left: 25px;
  top: 40px;
  color: ${color.red};
  font-size: 24px;
`;

export const Container = styled.div`
  position: relative;
  padding: 0 15px;
  margin: 0 0 16px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${color.darkgrey};
  margin-left: 10px;
  line-height: 27px;
`;

export const Masked = styled(MaskedInput)``;

export const Input = styled.input<AdminInputProps>`
  height: 48px;
  width: 100%;
  border-radius: 10px;
  padding: 6px 12px;
  border: 1px solid #c3c6d1;

  ${({ status }) =>
    status === 1 &&
    css`
      border: 2px solid ${color.green};
      padding-left: 45px;
    `}

  ${({ status }) =>
    status === 0 &&
    css`
      border: 2px solid ${color.red};
      padding-left: 45px;
    `}
`;
