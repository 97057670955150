import styled from 'styled-components';
import * as color from '../../../../styles/colors';
import { shade } from 'polished';

export const Container = styled.div`
  
    display: flex;
    flex-direction: column;
    align-items: center;
  
    div {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        max-width: 400px;

        > img {
        width: 82px;
        height: 82px;
        margin-bottom: 15px;
        }

        b {
            text-align: center;
        }
    
        h1 {
        font-size: 18px;
        margin-bottom: 15px;
        text-align: center;
        }

        p {
        margin-bottom: 0px;
        text-align: center;
        }

        button {
        margin-bottom: 0px;
        cursor: pointer;
        }

        .cancel {
        text-decoration: underline;
        cursor: pointer;
        color: ${color.darkgrey}
        }
    }

    .content {
        max-width: 300px;
    }

    .buttons-container {
        margin-top: 32px;
        display:flex;
        flex-direction:row;
        justify-content:center;

        .ok-button {
            background-color: #F6F7FA;
            border-radius: 50px;     
            border:1px solid #F6F7FA; 
            padding: 12px 30px;
            height: 45px;
            line-height: 1;
            color: ${color.darkgrey};
            font-weight: bold;
            margin-left: 20px;
            font-size:16px;
                
            :hover {
                background-color: ${shade(0.1, '#F6F7FA')};        
                transition: all ease 0.3s;
            }
        }
    }
  
`;