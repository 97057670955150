import { useState, useCallback } from 'react';

export default function useBankAccount() {

    const [viewCard, setViewCard] = useState<string>('');
    const [transferType,setTransferType] = useState<string>('');
    
    // useEffect(() => {
    //     const viewStr = localStorage.getItem('viewCard') as string;
    //     if (viewStr) setViewCard(viewStr);

    //     const transferType = localStorage.getItem('transferType') as string;
    //     if (transferType) setTransferType(transferType);
    
    // }, []);

    // useEffect(() => {
    //     if (viewCard) localStorage.setItem('viewCard', viewCard);    
    //     if (transferType) localStorage.setItem('transferType', transferType);    
    // }, [viewCard,transferType]);

    const changeView = useCallback(
        (param) => {    
            //localStorage.setItem('viewCard', param.toString());
            setViewCard(param);
            if(param=='PIX' || param=='TED'){
                setTransferType(param);
                //localStorage.setItem('transferType', param.toString());
            }
        },
        [viewCard,transferType],
    );    

    const translateTypeKey = (option:any)=>{        
        switch(option){
            case 'CPF': return 'Cpf';
            case 'CNPJ': return 'Cnpj';
            case 'EMAIL': return 'E-mail';
            case 'TELEFONE': return 'Celular';
            case 'CHAVE_ALEATORIA': return 'Aleatória';
            default: return '';
        }        
    }

    return {
        viewCard,
        setViewCard,
        transferType,
        setTransferType,
        changeView,
        translateTypeKey
    };
}
