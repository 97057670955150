import React, { useContext } from 'react';
import { Container, ModalContent } from './styles';
import Card from '../../../../../components/Card';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/ModalAdmin';
import UsePrivacyTermsText from '../../../../../components/UsePrivacyTermsText';
import useToggle from '../../../../../hooks/useToggle';
import { AccountsContext as Context } from '../../../../../context/AccountsContext';

const SiriusAbout: React.FC = () => {
  const { user } = useContext(Context);

  const [isCompanyTermsModalOpen, setIsCompanyTermsModalOpen] =
    useToggle(false);

  const companyTermsModal = (
    <Modal
      isOpen={isCompanyTermsModalOpen}
      onRequestClose={setIsCompanyTermsModalOpen}
      closeModal={setIsCompanyTermsModalOpen}>
      <ModalContent>
        <UsePrivacyTermsText />
      </ModalContent>
    </Modal>
  );
  return (
    <Container>
      {companyTermsModal}

      <Card cardTitle="Bem-vindo a Azcend!">
        {/* <p>Azcend é uma empresa de processamento de pagamentos online.</p>
        <br /> */}
        {/* <p>
                    A Azcend vai melhorar tudo aquilo que encantou o mercado, um sistema de pagamentos fluído,
                    transparente e com incríveis taxas de aprovação, agora, sob o selo de qualidade Azcend com mais
                    de 1 milhão de transações aprovadas!
                </p> */}
        <div className="row access-terms-row">
          <div className="active-access">
            <p className="active">Ativo em </p>
            &nbsp;
            <p className="access-date">{user?.created_at}</p>
          </div>
          <div className="terms">
            <Button transparent onClick={setIsCompanyTermsModalOpen}>
              Acessar termos
            </Button>
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default SiriusAbout;
