import styled from "styled-components";
import * as color from "../../../../styles/colors";
import { shade } from "polished";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        max-width: 400px;

        > img {
            width: 82px;
            height: 82px;
            margin-bottom: 15px;
        }

        b {
            text-align: center;
        }

        h1 {
            font-size: 18px;
            margin-bottom: 15px;
            text-align: center;
        }

        p {
            margin-bottom: 25px;
            text-align: center;
        }

        button {
            margin-bottom: 15px;
            cursor: pointer;
        }

        .cancel {
            text-decoration: underline;
            cursor: pointer;
            color: ${color.darkgrey};
        }
    }

    .content {
        max-width: 300px;
    }

    .buttons-container {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .cancel-button {
            background-color: #f6f7fa;
            border-radius: 50px;
            border: 1px solid #f6f7fa;
            color: ${color.darkgrey};
            padding: 12px 22px;
            height: 45px;
            font-weight: bold;

            :hover {
                color: ${shade(-0.8, color.darkgrey)};
                transition: all ease 0.3s;
            }
        }

        .ok-button {
            background-color: #0fbf7f;
            border-radius: 50px;
            border: 1px solid #0fbf7f;
            padding: 12px 30px;
            height: 45px;
            line-height: 1;
            color: #fff;
            font-weight: bold;
            margin-left: 20px;

            :hover {
                background-color: ${shade(0.1, "#EC5C2B")};
                transition: all ease 0.3s;
            }
        }
    }

    @media (max-width: 976px) {
        .ok-button {
            padding: 12px 5px !important;
            margin-left: 10px;
        }
    }
`;
