import styled, { css } from "styled-components";

interface WarningBarProps {
    type: string; //'warning' | 'danger' | 'success';
    close?: boolean;
}

export const Container = styled.div<WarningBarProps>`
    width: inherit;
    padding: 16px;
    border: 1px solid #0fbf7f;
    background: #f0fffb;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 14px;
    margin: 15px 0px;

    .alert-content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .alert-message {
            font-weight: 700;
            overflow-wrap: break-word;
            width: 97%;
        }

        .alert-close-button {
            position: absolute;
            right: 45px;
            margin-left: 20px;
        }
        .alert-close-button svg {
            color: #c3c3c3 !important;
            width: 14px;
            height: 14px;
        }
    }

    ${({ type }) =>
        type === "warning" &&
        css`
            border: 1px solid #0fbf7f;
            background: #f0fffb;
            .alert-message {
                color: #0fbf7f;
            }
        `}

    ${({ type }) =>
        type === "danger" &&
        css`
            border: 1px solid #ff0000;
            background: #fff7f7;
            .alert-message {
                color: #ff0000;
            }
        `}

    ${({ type }) =>
        type === "success" &&
        css`
            border: 1px solid #24ee75;
            background: #f2fff7;
            .alert-message {
                color: #10cf5c;
            }
        `}
`;
