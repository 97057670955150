import { colors } from "react-select/src/theme";
import styled from "styled-components";
import Card from "../../../../components/Card";
import * as color from "../../../../styles/colors";

interface TextProps {
    primary?: boolean;
    secondary?: boolean;
}
interface ValidatePixContainerProps {
    has_pix_key?: boolean;
}

interface typeKeyPix {
    value?: string;
}

export const Container = styled(Card)`
    margin-top: 30px;
`;

export const Title = styled.p<TextProps>`
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px !important;
    line-height: 120%;
    color: #56607e;
`;

export const Subtile = styled.p`
    font-size: 16px !important;

    margin-bottom: 20px;
    strong {
        font-weight: 700;
    }
`;

export const ValidatePixContainer = styled.div<ValidatePixContainerProps>`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    p {
        margin: 2px 0px 6px 0px;
        align-items: center;
        display: flex;
        gap: 5px;
    }

    .status-pix {
        display: flex;
    }

    .label {
        color: ${({ has_pix_key }) => (has_pix_key ? color.brightgreen : color.darkgrey)};
    }
    @media (max-width: 576px) {
        flex-direction: column;

        button {
            width: 100%;
            padding: 15px 0px;
        }

        .status-pix {
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 25px;
        }
    }
`;

export const LinkButton = styled.div`
    display: flex;
    flex-direction: "row";
    text-align: "center";
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    span {
        color: #0fbf7f;
        margin-left: 12px;
    }
    cursor: pointer;
`;

export const KeyTypeRadioGroup = styled.label`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const KeyTypeRadioOption = styled.input`
    position: absolute;
    left: -999px;
    margin: 0px;

    &:checked + label {
        background: #0fbf7f;
        border: 1px solid #0fbf7f;
        box-sizing: border-box;
        & span {
            color: #ffffff !important;
        }
        & path {
            fill: #ffffff;
        }
    }
`;

export const KeyTypeRadioLabel = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px;
    width: fit-content;
    height: 50px;
    margin: 0 8px 0 0;
    color: ${color.darkgrey};
    transition: all 0.3s;

    border: 1px solid #c3c6d1;
    box-sizing: border-box;
    border-radius: 30px;
    cursor: pointer;

    & > first-child {
        margin: 0;
    }

    & path {
        color: ${color.darkgrey};
    }

    & span {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        line-height: 100%;
        color: ${color.darkgrey};
        margin-left: 5px;
    }

    &:hover {
        background: #0fbf7f;
        border: 1px solid #0fbf7f;
        box-sizing: border-box;
        & span {
            color: #ffffff !important;
        }
        & path {
            fill: #ffffff;
        }
    }

    @media (max-width: 576px) {
        margin-bottom: 10px;
    }
`;

export const ContentPixKey = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 24px;
    @media (max-width: 576px) {
        margin-top: 4px;

        flex-direction: column;
        align-items: baseline;

        .pix-input {
            padding: 0;
            width: 100%;
        }

        .pix-input label {
            margin: 0;
        }
    }
`;

export const LabelPixKey = styled.div`
    margin-right: 32px;
    width: 106px;
    h6 {
        font-size: 14px;
    }

    div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 15px;
        span {
            margin-left: 5px;
        }
    }

    @media (max-width: 576px) {
        div {
            margin-bottom: 20px;
        }
    }
`;
