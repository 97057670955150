import React from 'react'
import iconWaiting from '../../../../assets/icon_waiting.svg'
import { Container } from './styles'
import Button from '../../../../components/Button';

interface WaitingProps {
    onConfirm?: any;    
}

const ModalWaiting: React.FC<WaitingProps> = ({ onConfirm}) => {
    return (
        <Container>
            <div>
                <img src={iconWaiting} alt="Sending"></img>
                <h1>
                    <b>Vamos precisar de mais tempo...</b>
                </h1>
                <p>Ainda não conseguimos validar a sua conta, mas não se preocupe, você receberá um e-mail quando tudo estiver certo por aqui!</p>
            </div>
            <div className="buttons-container">               
                <Button className="ok-button" onClick={onConfirm}>Ok, entendi</Button>
            </div>
        </Container>
    )
}

export default ModalWaiting;