import React, { useState, useCallback, useContext } from 'react';
import { ICompany } from '../../../entities/Company';
import CardListAccounts from './CardListAccounts';
import CardPIX from './CardPIX';
import CardTED from './CardTED';
import CardTransferType from './CardTransferType';
import { ViewBankAccountContext } from '../../../context/CreateBankAccountContext';

const bankAccountProvider = React.createContext({ view: '' });
interface CompanyProfileProps {
  company: ICompany | undefined;
  sendDataToCompanyDetail?: any;
}

type CompanyBankAccount = {
  companyId: number;
  transferType: string;
  type_key_pix: boolean;
  key_pix: string;
  bank: string;
  agency: string;
  agency_digit: string;
  account: string;
  account_digit: string;
  is_default: number;
  status: string;
};

const FormBankAccount: React.FC<CompanyProfileProps> = ({
  company,
  sendDataToCompanyDetail,
}) => {
  const { viewCard, setViewCard } = useContext(ViewBankAccountContext);
  const [loading, setLoading] = useState<number>(0);

  const renderCard = useCallback(() => {
    switch (viewCard) {
      case 'PIX':
        return <CardPIX company={company}></CardPIX>;
      case 'TED':
        return <CardTED company={company}></CardTED>;
      case 'TYPE':
        return <CardTransferType company={company}></CardTransferType>;
      default:
        return <CardListAccounts company={company}></CardListAccounts>;
    }
  }, [viewCard]);

  return <>{renderCard()}</>;
};

export default FormBankAccount;

/*
 */
