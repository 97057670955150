import styled from 'styled-components';
import ContentBox from '../../../components/ContentBox';
import * as color from '../../../styles/colors';
import userIcon from '../../../assets/user-fi.svg';
import trashIcon from '../../../assets/trash.svg';
import editIcon from '../../../assets/edit.svg';
import plusIcon from '../../../assets/plus.svg';

interface PeopleAndAccessProps {
  avatarImg?: string;
  edit?: boolean;
  trash?: boolean;
}

export const Container = styled.div`
  position: relative;

  .no-collaborators-message {
    font-size: 20px;
    text-align: center;
  }

  @media (max-width: 375.9px) {
    .card {
      padding: 15px;
    }
  }
`;

export const Users = styled(ContentBox)`
  display: flex;
  padding: 14px 20px 14px 12px;
  margin-bottom: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;

  img {
    margin-right: 28px;
    width: 40px;
    height: 40px;
  }

  .service-container {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 576px) {
      margin-bottom: 10px;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .edit-button {
      margin-right: 10px;
    }
  }
`;

export const UserProfile = styled.div`
  display: flex;
  @media (max-width: 576px) {
    margin-bottom: 10px;
  }
`;

export const Avatar = styled.div<PeopleAndAccessProps>`
  margin-right: 0.9rem !important;
  background-image: url(${({ avatarImg }) => avatarImg || userIcon});
  width: 42px;
  height: 42px;
  background-size: cover;
  border-radius: 25px;
  border: 2px solid #fff;
  box-shadow: 0px 2px 4px rgba(14, 40, 93, 0.2);

  @media (max-width: 375.9px) {
    width: 36px;
    height: 36px;
  }
`;

export const UserNameEmail = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  .user-name {
    color: ${color.orange};
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const ServiceType = styled.div`
  position: relative;
  margin: 10px 0 0 20px;
  color: ${color.darkgrey};

  ::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: #00be9b;
    border-radius: 10px;
    left: -20px;
    top: 3px;
  }
`;

export const RoundButton = styled.button<PeopleAndAccessProps>`
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background: url(${({ edit }) => (edit ? editIcon : trashIcon)}) center
    no-repeat ${color.orange};
  background-size: 14px;
  border: 1px solid ${color.orange};
`;

export const AddButton = styled.button`
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background: url(${plusIcon}) no-repeat center ${color.orange};
  background-size: 24px;
  border: 1px solid ${color.orange};
  position: fixed;
  right: 55px;
  bottom: 30px;
  box-shadow: 0px 12px 25px rgba(14, 40, 93, 0.15);

  @media (max-width: 546px) {
    position: unset;
    margin-top: 20px;
    width: 100%;
    height: 40px;
  }

  @media (max-width: 769px) {
    position: unset;
    margin-top: 20px;
  }
`;
