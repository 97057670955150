import styled from 'styled-components';
import ContentBox from '../../../../../../components/ContentBox';

export const CompanyBox = styled(ContentBox)`
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  img {
    margin-right: 28px;
    width: 40px;
    height: 40px;
  }

  .dropdown-container {
    top: 10px;
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;

  .header {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .info {
    font-size: 16px;
    font-weight: 600;
    padding-top: 8px;
  }

  .release-info {
    margin-top: 15px;
  }
`;
