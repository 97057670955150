import axios, { AxiosError } from "axios";
import { getToken, logout } from "./auth";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token && token.length) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
/**
const resIntercept = api.interceptors.response.use((response) => {
    return response
}, (error) => {
  
   if(error.response.status == 401) {
    alert('você não tem permissão ou token expirado')
    window.location.href = "/";
   }

   return axios(error.config);
})
 */

api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      logout();
      window.location.href = "/signin";
    }
    return Promise.reject(error);
  }
);

export default api;
