import React from 'react';
import { FiX } from 'react-icons/fi';
import { CSSProperties } from 'styled-components';
import { Container } from './styles';
import Button from '../Button';

interface AlertBoxProps {
    message?: string;
    buttonClick?: () => void;
    closeBarOnClick?: () => void;
    type:string;//'danger' | 'warning' | 'success';
    className?: string;
    style?: CSSProperties;
}

const AlertBox: React.FC<AlertBoxProps> = ({
    type,
    buttonClick,
    message,
    className,
    style,
    closeBarOnClick,
}) => {
    return (
        <Container type={type} className={className} style={style}>
            <div className="alert-content">
                    <p className="alert-message">{message}</p>
                {/* <div className="row" style={{ alignItems: 'center' }}>
                </div> */}
                <Button
                    transparent
                    className="alert-close-button"
                    onClick={closeBarOnClick}
                >
                    {type === 'danger' && <FiX color="#fff" size={20} />}
                    {type === 'warning' && <FiX color="#000" size={20} />}
                    {type === 'success' && <FiX color="#000" size={20} />}
                </Button>
            </div>
        </Container>
    );
};

export default AlertBox;
