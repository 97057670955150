import styled from "styled-components";
import * as color from '../../styles/colors';

interface ContainerProps {
  height?: string|number;
  width?: string|number;
}

export const Container = styled.div<ContainerProps>`
  height: ${props => `${props.height}px` };
  width: ${props => `${props.width}px` };;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  line-height: 1.4;
  margin: 20px 0 34px;
  padding: 0;
  overflow-y: auto;
  overflow-x: wrap;
  overflow-wrap: break-word;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: ${color.orange} #f5f5f5;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${color.orange};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #e5e5e5;
    background-color: #f5f5f5;
    border-radius: 4px;
  }

  .transparent-effect {
    pointer-events: none;
    width: 100%;
    height: 40px;
    position: sticky;
  }

  .botton {
    bottom: 0;
    height: 40px;
    background: linear-gradient(180deg, transparent 0%, rgba(255,255,255,1) 100%);
  }

  .top  {
    top: 0;
    height: 20px;
    background: linear-gradient(0deg, transparent 0%, rgba(255,255,255,1) 100%);
  }

  .btn-finish {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 15px;
    }
  }

`;