import styled from 'styled-components';
import Card from '../../../../../components/Card';

export const Container = styled(Card)`
  .company-name,
  .company-document {
    font-size: 16px;
    font-weight: 600;
    padding-top: 8px;
  }
`;

export const BlockItem = styled.div`
  padding:0px 15px;
  margin-bottom: 10px;
  
  @media (max-width: 576px){
    padding:0px;
}
`;