import styled, { keyframes } from "styled-components";
import * as color from "../../styles/colors";

interface ContainerProps {
    showLogo?: boolean;
    topbar?: boolean;
    intro?: boolean;
}

const float = keyframes`
  25% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
`;

const blurRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

export const Container = styled.div<ContainerProps>`
    height: 100vh;
    align-items: stretch;

    .topbar {
        display: ${({ topbar }) => (topbar ? "block" : "none")};
    }
`;

const fadeIn = keyframes`
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const PageContainer = styled.div<ContainerProps>`
    position: relative;
    height: 100vh;

    .page-bkg {
        display: none;
        animation: 2s ${fadeIn};
        animation-fill-mode: forwards;
    }

    @media (min-width: 769px) {
        display: flex;
        align-items: stretch;

        .page-bkg {
            display: block;
        }
    }
`;

export const Content = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    height: 100%;

    opacity: 0;

    background: #fff;
    z-index: 2;

    .scroll-container {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        background: #fff;
        overflow-x: hidden;

        width: 100%;
        height: 100%;

        margin-top: 0;
        margin-bottom: 0;
    }

    animation: 1s ${fadeIn};
    animation-delay: ${(props) => (props.intro === true ? "5s" : "0s")};
    animation-fill-mode: forwards;

    @media (min-width: 1080px) {
        width: 40%;
    }

    @media (max-width: 890px) {
        width: 100%;
    }
`;

export const MobileContainer = styled.div`
    display: none;
    justify-content: center;
    align-items: center;

    position: relative;

    width: 100%;
    height: 300px;

    background: #021318;

    .blurs {
        position: absolute;
        width: 100%;
        height: 100%;

        animation: ${blurRotate} 10s ease-in-out;
        animation-delay: 5s;
    }

    .blur {
        position: absolute;
        width: 300px;
        animation: ${float} 7s Infinite ease-in-out;
    }

    .blueBlurOne {
        left: -106px;
    }

    .purpleBlurOne {
        right: -132px;
    }

    .content-logo {
        z-index: 2;
        padding: 20px;
        margin: 54px 0 80px 0;

        img {
            width: 175px;
            margin-bottom: 30px;
        }
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 100%;
        color: ${color.white};
        z-index: 2;
    }

    .title.orange,
    .title.orange span {
        color: ${color.orange};
        z-index: 2;
    }

    .title.orange span {
        color: ${color.orange};
    }

    .subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: ${color.white};
        z-index: 2;
    }

    .typing-container {
        margin-bottom: 16px;
    }

    .type-cursor {
        background: ${color.orange};
        line-height: 60px;
        margin-left: 3px;
        animation: blink 0.8s infinite;
        width: 7px;
        height: 40px;
        display: inline-block;
    }

    @keyframes blink {
        50% {
            opacity: 0;
        }
    }

    @media (max-width: 890px) {
        display: flex;
    }
`;

export const ChildrenContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    margin: auto;
    width: 100%;
    height: fit-content;
    background: #fff;
    z-index: 3;
    min-width: 250px;

    img {
    }

    @media (max-width: 890px) {
        margin: -32px 0 0 0;

        border-radius: 32px 32px 0px 0px;
    }
`;
