import styled, { css } from "styled-components";
import { shade } from "polished";
import * as color from "../../styles/colors";

interface ButtonProps {
    primary?: boolean;
    primaryOutline?: boolean;
    grey?: boolean;
    secondaryOutline?: boolean;
    textTransform?: boolean;
    primaryPill?: boolean;
    secondaryPill?: boolean;
    transparent?: boolean;
    opacity?: number;
    boldtext?: boolean;
    fitContent?: boolean;
}

export const Button = styled.button<ButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    text-transform: ${({ textTransform }) => textTransform && "uppercase"};
    ${({ primary }) =>
        primary &&
        css`
            height: 42px;
            width: 100%;
            padding: 10px 20px;
            border-radius: 4px;
            background: ${color.darkgrey};
            color: #fff;
            border: 1px solid #fff;
            transition: 0.3s;

            &:hover {
                background: ${shade(0.1, color.black)};
                /* border: 1px solid ${shade(0.1, color.orange)}; */
            }
        `}

    ${({ primaryOutline }) =>
        primaryOutline &&
        css`
            height: 42px;
            width: 100%;
            padding: 10px 20px;
            border-radius: 4px;
            background: transparent;
            color: ${color.orange};
            border: 1px solid ${color.orange};
            transition: 0.3s;

            &:hover {
                border: 1px solid ${shade(0.1, color.orange)};
            }
        `}

  ${({ secondaryOutline }) =>
        secondaryOutline &&
        css`
            height: 40px;
            width: 100%;
            padding: 10px 20px;
            border-radius: 4px;
            background: transparent;
            color: ${color.blue};
            border: 1px solid ${color.blue};

            &:hover {
                border: 1px solid ${shade(0.1, color.blue)};
            }
        `}

  ${({ primaryPill }) =>
        primaryPill &&
        css`
            border-radius: 50px;
            padding: 12px 30px;
            height: 42px;
            line-height: 1;
            font-weight: bold;
            background-color: ${color.orange};
            border: ${color.orange};
            color: #fff;
            transition: 0.3s;

            &:hover {
                background-color: ${shade(0.1, color.orange)};
            }
        `}

  ${({ transparent }) =>
        transparent &&
        css`
            background: transparent;
            border: none;
        `}

  ${({ opacity }) =>
        opacity === 1 &&
        css`
            opacity: 0.5;
            &:hover {
                background-color: ${color.orange};
            }
        `}

${({ grey }) =>
        grey &&
        css`
            height: 42px;
            width: 100%;
            padding: 10px 20px;
            border-radius: 4px;
            background-color: ${color.lighgreyblue};
            border-radius: 4px;
            border: ${color.lighgreyblue};
            padding: 12px 30px;
            color: #3d4456;

            &:hover {
                background: ${shade(0.1, color.lighgreyblue)};
                /* border: 1px solid ${shade(0.1, color.orange)}; */
            }
        `}
`;
