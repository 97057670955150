import styled from "styled-components";
import Card from "../../../../components/Card";
import * as color from "../../../../styles/colors";
import ContentBox from "../../../../components/ContentBox";
import trashIcon from "../../../../assets/trash.svg";
interface TextProps {
    primary?: boolean;
    secondary?: boolean;
}
interface ValidatePixContainerProps {
    has_pix_key?: boolean;
}

export const Container = styled(Card)`
    margin-top: 30px;
`;

export const Title = styled.p<TextProps>`
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px !important;
    line-height: 120%;
    color: #56607e;
`;

export const Subtile = styled.p`
    font-size: "16px !important";
    margin-top: -12px !important;
    margin-bottom: 24px;
`;

export const KeyTypesOption = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px;

    border: 1px solid #c3c6d1;
    box-sizing: border-box;
    border-radius: 30px;
    margin: 0px 8px;
    cursor: pointer;

    &:hover {
        background: #0fbf7f;
        border: 1px solid #0fbf7f;
        box-sizing: border-box;
        & span {
            color: #ffffff !important;
        }
        & path {
            fill: #ffffff;
        }
    }
    &:first-child {
        margin-left: 0px;
    }
`;

export const KeyTypesOptionLabel = styled.span`
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 100%;
    color: #56607e;
    margin-left: 10px;
`;

export const Account = styled(ContentBox)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: 1px solid #f6f7fa;
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom: 5px;
    &.active {
        border: 1px solid #d7d7d7;
        & path {
            fill: #0fbf7f;
        }
    }

    @media (max-width: 576px) {
        justify-content: space-between;
        padding: 10px;
    }
`;

export const AccountInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 576px) {
    }
`;

export const AccountLabel = styled.div`
    flex-direction: column;
    justify-contenr: center;
    margin-left: 16px;
    p.active {
        color: ${color.orange};
    }

    @media (max-width: 576px) {
        .title {
            display: none;
        }

        .subtitle {
            font-size: 12px;
        }
    }
`;
export const AccountStatus = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    .badge-dot {
        display: none;
    }

    @media (max-width: 576px) {
        .badge-dot {
            display: flex;
        }

        .badge-label {
            display: none;
        }
    }
`;
export const AccountDelete = styled.div`
    height: 24px;
    width: 24px;
    justify-content: flex-end;
`;
export const ButtonDelete = styled.div`
    height: 24px;
    width: 24px;
    border-radius: 24px;
    background: url(${trashIcon}) center no-repeat ${color.orange};
    background-size: 14px;
    border: 1px solid ${color.orange};
    cursor: pointer;
    @media (max-width: 576px) {
        float: right;
    }
`;

export const FooterContainer = styled.div<ValidatePixContainerProps>`
    display: flex;
    flex-direction: "row";
    flex-wrap: wrap;
    margin-top: 24px;
    justify-content: space-between;

    .footer-obs {
        margin: 2px 0 6px 8px;
        align-items: center;
        display: flex;
        gap: 5px;
    }

    .label {
        color: ${({ has_pix_key }) => (has_pix_key ? color.brightgreen : color.darkgrey)};
    }

    @media (max-width: 576px) {
        flex-direction: "column";
        justify-content: center;
        aling-items: center;
        margin-top: 5px;

        button {
            width: 100%;
        }

        .footer-obs {
            margin: 0 0 24px 0;
            text-align: center;
            font-size: 12px;
            display: flex;
            gap: 5px;
        }
    }
`;
