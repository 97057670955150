import React, { useContext } from 'react';
import FininishComponent from '../../../components/FininishComponent';
import { RegisterContext as Context } from '../../../context/RegisterContext';

const ErrorRegister: React.FC = () => {
  const {
    previousStep,
    errorFinish
  } = useContext(Context)

  const handleError = () => {
    previousStep(1);
  };

  return (
      <FininishComponent
        status={'error'}
        title={'Algo deu errado :('}
        description={errorFinish ? errorFinish : 'Não foi possível realizar seu cadastro. Tente novamente mais tarde.'}
        buttonText={'VOLTAR'}
        buttonClick={handleError}
      />
  );
};

export default ErrorRegister;
