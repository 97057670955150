import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Container, LinkButton, ValidatePixContainer, Subtile } from "./styles";
import Form from "../../../../components/Form";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import useBankList from "../../../../hooks/useBankList";
import { ICompany } from "../../../../entities/Company";
import api from "../../../../services/api";
import pusher from "../../../../services/pusher";
import { AccountsContext as Context } from "../../../../context/AccountsContext";

import Modal from "../../../../components/ModalAdmin";
import ModalSendingData from "../ModalSendingData";
import ModalValidationReject from "../ModalValidationReject";
import ModalWaiting from "../ModalWaiting";
import ModalValidationSuccess from "../ModalValidationSuccess";

import orangeDot from "../../../../assets/orange_dot.svg";
import redDot from "../../../../assets/red_dot.svg";
import greenDot from "../../../../assets/green_dot.svg";

import useToggle from "../../../../hooks/useToggle";
import { ViewBankAccountContext } from "../../../../context/CreateBankAccountContext";
import AlertBox from "../../../../components/AlertBox";
import { stringify } from "querystring";
interface CompanyProfileProps {
    company: ICompany | undefined;
    sendDataToCompanyDetail?: any;
}

interface BankProps {
    name: string;
    full_name: string;
    code: string;
    ispb: string;
}

interface ParamTypes {
    id: string;
}

type FormData = {
    company_id: string | undefined;
    bank: string;
    account: string;
    account_digit: string;
    agency: string;
    agency_digit: string;
    bank_ispb: string;
    account_type: string;
};

const CardTED: React.FC<CompanyProfileProps> = ({ company, sendDataToCompanyDetail }) => {
    const [bank, setBank] = useState<string>("");
    const [account_type, setAccountType] = useState<string>("");
    const [bankIspb, setBankIspb] = useState<string>("");

    const bankList = useBankList();

    const [isModalOpen, setIsModalOpen] = useToggle(false);
    const { setViewCard } = useContext(ViewBankAccountContext);

    const params = useParams<ParamTypes>();

    const { register, reset, setValue, handleSubmit, errors } = useForm<FormData>({
        defaultValues: {
            company_id: company?.id_code,
            bank: "",
            bank_ispb: "",
            account: "",
            account_digit: "",
            agency: "",
            agency_digit: "",
            account_type: "",
        },
    });

    const [disabledInput, setDisabledInput] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);

    const [loading, setLoading] = useState<number>(0);
    const [alertType, setAlertType] = useState<string>("warning");
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [alertShow, setAlertShow] = useState<boolean>(false);
    const [statusVerify, setStatusVerify] = useState<string>("PENDING");
    const [hashId, setHashId] = useState<string>("");
    const [dataFormTemp, setDataFormTemp] = useState<any>([]);
    const [statusPusher, setStatusPusher] = useState<string>("");

    const [showModalSending, setShowModalSending] = useState<boolean>(false);
    const [showModalValidationSuccess, setShowModalValidationSuccess] = useState<boolean>(false);
    const [showModalValidationReject, setShowModalValidationReject] = useState<boolean>(false);
    const [showModalWaiting, setShowModalWaiting] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);

    const [th, setTh] = useState<any>(null);

    useEffect(() => {
        const channel = pusher.subscribe(`validation-pix-${company?.id_code}`);

        channel.bind("change-status", (data: any) => {
            if (data.status && data.bankAccountId) {
                setStatusPusher(data.status);
            }
        });
    }, []);

    useEffect(() => {
        if (statusPusher) {
            verifyStatusKey(statusPusher);
        }
    }, [statusPusher]);

    const list_bank = bankList.filter((b: BankProps) => b.code !== null).map((b: BankProps) => {
        return { value: b.code, label: b.code + " - " + b.name, ispb: b.ispb };
    });

    const list_account_type = [{ value: "CORRENTE", label: "Conta Corrente" }, { value: "POUPANCA", label: "Conta Poupança" }];

    useEffect(() => {
        register({ name: "bank" }, { required: { value: true, message: "Campo obrigatório" } });
        register({ name: "account_type" }, { required: { value: true, message: "Campo obrigatório" } });
    }, [register]);

    const handleBankChange = useCallback(
        (e) => {
            console.log(e);
            setValue("bank", e.value);
            setBank(e.value);
            setBankIspb(e.ispb);
        },
        [setValue]
    );

    const handleAccountTypeChange = useCallback(
        (e) => {
            console.log(e);
            setValue("account_type", e.value);
            setAccountType(e.value);
        },
        [setValue]
    );

    useEffect(() => {
        switch (statusVerify) {
            case "PROCESSING":
                setAlertType("warning");
                break;
            case "PENDING":
                setAlertType("warning");
                setDisabledInput(false);
                setDisabledButton(false);
                break;
            case "VERIFIED":
                setAlertType("success");
                clearTimeout(th);
                break;
            case "REFUSED":
                setAlertType("danger");
                setProcessing(false);
                clearTimeout(th);
                break;
            case "ERROR":
                setAlertType("danger");
                setProcessing(false);
                break;
        }
    }, [statusVerify]);
   
    const updateAddressFromAPI = useCallback(
        async () => {
            try {
            await api.put(`/companies/address/${company?.id_code}`, {
                ...company,
                country: 'brazil',
                isDataFromAPI: true,
            });
            } catch (error) {
            console.log(error);
            }
        },
        [company]
    );

    const getAddressFromAPI = useCallback(
        async () => {
          try {
            let obj: { cnpj: string } = { cnpj: '' };
            obj.cnpj = company?.document?.replace(/\D/g, '') || '';
            const response = await api.post('/companies/consult-cnpj', obj);
        
            if(company) {
                company.zip_code = response.data.cep;
                company.street = response.data.logradouro;
                company.number = response.data.numero;
                company.neighborhood = response.data.bairro;
                company.city = response.data.municipio;
                company.state = response.data.uf;
                company.complement = response.data.complemento;
                company.support_telephone = response.data.ddd_telefone_1;
                company.support_email = response.data.correioEletronico;
            }

            await updateAddressFromAPI();
          } catch (error) {            
            console.log(error);
          }
        },
        [company],
    );

    const onSubmit = handleSubmit(async (data: FormData) => {
        setProcessing(true);
        setShowModalSending(true);
        setDisabledButton(true);
        setDisabledInput(true);
        console.log(data);
        await api
            .post(`/bank-account/verify/account`, {
                ...data,
                company_id: company?.id_code,
                bank_ispb: bankIspb,
            })
            .then((response) => {
                if (response.data) {
                    setAlertMessage(response.data.message);
                    setAlertShow(true);

                    setHashId(response.data.hashId);
                    setStatusVerify(response.data.status);
                    setAlertMessage(`Sua conta está sendo verificada. Isso pode levar algumas horas.`);
                    setDataFormTemp(data);
                    
                    if (company?.street == null || company?.street == '' ||
                        company?.number == null || company?.number == '' ||
                        company?.neighborhood == null || company?.neighborhood == '' ||
                        company?.city == null || company?.city == '' ||
                        company?.state == null || company?.state == '' ||
                        company?.zip_code == null || company?.zip_code == '' ||
                        company?.support_telephone == null || company?.support_telephone == ''
                    ) {
                        console.log('atualizando endereço');
                        getAddressFromAPI();
                    }

                    let thTemp = setTimeout(() => {
                        setShowModalSending(false);
                        setShowModalValidationSuccess(true);
                    }, 10000);
                    setTh(thTemp);
                }
            })
            .catch((error) => {
                setProcessing(false);
                setIsModalOpen();
                setShowModalSending(false);
                setAlertShow(true);
                let message = "Estamos com algumas dificultades técnicas, tente novamente mais tarde!";

                if (error.response) {
                    if (error.response.data.message) {
                        message = error.response.data.message;
                        if (error.response.data.errors) {
                            message = error.response.data.errors[0];
                        }
                    }
                }

                setAlertMessage(message);
                setStatusVerify("ERROR");
                setDisabledButton(false);
            });
    });

    const verifyStatusKey = (status: string) => {
        let message = "";

        setStatusVerify(status);

        switch (status) {
            case "VERIFIED":
                setAlertMessage(`Sua conta foi verficada com sucesso.`);
                setAlertShow(true);
                setShowModalSending(false);
                setShowModalValidationSuccess(true);
                break;
            case "REFUSED":
                let bankName = "";
                list_bank.map((row) => {
                    if (row.value === bank) {
                        bankName = row.label;
                    }
                });

                message = `Sua conta não pôde ser verficada.`;
                if (dataFormTemp.account && dataFormTemp.account_digit) {
                    message = `A conta ${dataFormTemp.account}-${dataFormTemp.account_digit} do Banco ${bankName} não pôde ser verficada.`;
                }
                message += ` Por favor, informe uma conta válida ou contate nosso suporte.`;

                setAlertMessage(message);
                setAlertShow(true);
                setShowModalSending(false);
                setShowModalValidationReject(true);
                break;
        }
    };

    const handleCloseAlert = () => {
        setAlertShow(false);
    };

    const handleCloseRedirectList = () => {
        window.location.reload();
    };

    const modalSendingData = (
        <Modal
            isOpen={showModalSending}
            onRequestClose={() => setShowModalSending(false)}
            closeModal={() => setShowModalSending(false)}
            smallWidth={true}
        >
            <ModalSendingData title={hashId ? "Aguardando confirmação..." : ""} />
        </Modal>
    );

    const modalValidationSuccess = (
        <Modal
            isOpen={showModalValidationSuccess}
            onRequestClose={handleCloseRedirectList}
            closeModal={handleCloseRedirectList}
            smallWidth={true}
        >
            <ModalValidationSuccess onCancel={handleCloseRedirectList} transferType="TED" />
        </Modal>
    );

    const modalValidationReject = (
        <Modal
            isOpen={showModalValidationReject}
            onRequestClose={handleCloseRedirectList}
            closeModal={handleCloseRedirectList}
            smallWidth={true}
        >
            <ModalValidationReject
                onCancel={handleCloseRedirectList}
                onConfirm={setShowModalValidationReject}
                transferType="TED"
                company={company}
            />
        </Modal>
    );

    const modalWaiting = (
        <Modal
            isOpen={showModalWaiting}
            onRequestClose={handleCloseRedirectList}
            closeModal={handleCloseRedirectList}
            smallWidth={true}
        >
            <ModalWaiting onConfirm={handleCloseRedirectList} />
        </Modal>
    );

    return (
        <>
            <Container cardTitle="Forma de recebimento" loading={loading}>
                <Subtile>
                    Com base no cadastro da sua empresa, seus saques serão liquidados na{" "}
                    <b>chave pix: {company?.document}</b>.
                </Subtile>
                <Subtile>
                    <strong>
                        Para algumas adiquirentes é necessário também uma conta associada ao{" "}
                        {company?.type == 1 ? "CPF do seu cadastro" : "CNPJ da sua empresa"}.
                    </strong>
                </Subtile>
                {alertShow && (
                    <AlertBox type={alertType} message={alertMessage} closeBarOnClick={handleCloseAlert}></AlertBox>
                )}
                {modalSendingData}
                {modalValidationSuccess}
                {modalValidationReject}
                {modalWaiting}
                {
                <Form onSubmit={onSubmit}>
                    <div className="row">
                        <Select
                            name="bank"
                            className="col-md-6 col-xs-12"
                            label="Banco"
                            value={list_bank.find((v) => v.value === bank)}
                            options={list_bank}
                            onChange={handleBankChange}
                            errorMessage={errors.bank?.message}
                            disabled={disabledInput}
                        />
                        <Select
                            name="account_type"
                            className="col-md-6 col-xs-12"
                            label="Tipo Conta"
                            value={list_account_type.find((v) => v.value === account_type)}
                            options={list_account_type}
                            onChange={handleAccountTypeChange}
                            errorMessage={errors.account_type?.message}
                            disabled={disabledInput}
                        />
                    </div>
                    <div className="row">
                        <Input
                            label="Agência"
                            id="bank-agency"
                            name="agency"
                            mask=""
                            placeholder="Agência"
                            className="col-md-6 col-xs-12"
                            register={register({
                                required: { value: true, message: "Campo obrigatório" },
                            })}
                            errorMessage={errors.agency?.message}
                            disabled={disabledInput}
                        />
                        <Input
                            label="Digito"
                            id="bank-agency-digit"
                            name="agency_digit"
                            mask=""
                            placeholder="Digito"
                            className="col-md-6 col-xs-12"
                            register={register}
                            disabled={disabledInput}
                        />
                    </div>
                    <div className="row">
                        <Input
                            label="Conta"
                            id="bank-account"
                            name="account"
                            mask=""
                            placeholder="Conta"
                            className="col-md-6 col-xs-12"
                            register={register({
                                required: { value: true, message: "Campo obrigatório" },
                            })}
                            errorMessage={errors.account?.message}
                            disabled={disabledInput}
                        />
                        <Input
                            label="Digito"
                            id="bank-account-digit"
                            name="account_digit"
                            mask=""
                            placeholder="Digito"
                            className="col-md-6 col-xs-12"
                            register={register}
                            disabled={disabledInput}
                        />
                    </div>
                    <>
                        <ValidatePixContainer>
                            {(statusVerify == "PENDING" || statusVerify == "ERROR") && (
                                <p className="status-pix">
                                    <img src={orangeDot} alt="Verifique sua chave" />
                                    Para confirmar, solicite a verificação
                                </p>
                            )}
                            {statusVerify == "PROCESSING" && (
                                <p className="status-pix">
                                    <img src={orangeDot} alt="Verifique sua chave" />
                                    Conta em processo de verificação
                                </p>
                            )}
                            {statusVerify == "VERIFIED" && (
                                <p className="status-pix">
                                    <img src={greenDot} alt="Chave verificada" />
                                    Conta verificada
                                </p>
                            )}
                            {statusVerify == "REFUSED" && (
                                <p className="status-pix">
                                    <img src={redDot} alt="Chave inexistente" />
                                    Conta não pôde ser verificada
                                </p>
                            )}

                            {statusVerify == "PENDING" && (
                                <Button primaryPill type="submit" disabled={disabledButton}>
                                    Verificar minha conta{" "}
                                </Button>
                            )}
                            {(statusVerify == "REFUSED" || statusVerify == "ERROR") && (
                                <Button
                                    primaryPill
                                    type="button"
                                    onClick={() => {
                                        setStatusVerify("PENDING");
                                    }}
                                >
                                    Trocar conta
                                </Button>
                            )}
                        </ValidatePixContainer>
                    </>
                </Form>
                }
            </Container>
        </>
    );
};

export default CardTED;
