/* eslint-disable eqeqeq */
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import {
  Container,
  CompanyBox,
  Content,
  Row,
  AddButton,
  CompanyStatus,
  Status,
  CompanyBoxInactive,
} from './styles';
import Card from '../../../components/Card';
import PageTopbar from '../../../components/PageTopbar';
import Badge from '../../../components/Badge';
import Modal from '../../../components/ModalAdmin';
import suitcaseIcon from '../../../assets/suitcase.svg';
import dragIcon from '../../../assets/drag-icon.svg';
import api from '../../../services/api';
import { AccountsContext as Context } from '../../../context/AccountsContext';
import { ICompany } from '../../../entities/Company';
import useToggle from '../../../hooks/useToggle';
import CompanyStatusFlagContent from './CompanyStatusFlagContent';
import CompanyDeleteContent from './CompanyDeleteContent';
import BadgeDot from '../../../components/BadgeDot';

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: 'none',
  borderRadius: '10px',
  margin: `0 0 20px 0`,

  // change background colour if dragging
  background: isDragging ? '#fff9ef' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const Companies: React.FC = () => {
  const [companies, setCompanies] = useState<[]>([]);
  const [loading, setLoading] = useState<number>(0);
  const [forceToReload, setForceToReload] = useState<number>(0);
  const [statusFlag, setStatusFlag] = useState<number>(0);
  const [companyId, setCompanyId] = useState<string>('');
  const [noCompaniesMessage, setNoCompaniesMessage] = useState<string>('');
  const [hasCompanies, setHasCompanies] = useState<boolean>();
  const [hasActiveCompanies, setHasActiveCompanies] = useState<boolean>(false);
  const { user } = useContext(Context);

  const [disableDraggable, setDisableDraggable] = useState<boolean>(true);

  const [isDeleteCompanyModalOpen, setIsDeleteCompanyModalOpen] =
    useToggle(false);
  const [isCompanyStatusFlagModalOpen, setIsCompanyStatusFlagModalOpen] =
    useToggle(false);

  const closeModal = useCallback(
    type => {
      if (type === 'delete') {
        setIsDeleteCompanyModalOpen();
      }

      if (type === 'flag') {
        setIsCompanyStatusFlagModalOpen();
      }
      setForceToReload(forceToReload + 1);
    },
    [
      forceToReload,
      setIsCompanyStatusFlagModalOpen,
      setIsDeleteCompanyModalOpen,
    ]
  );

  const EditCompany = useCallback(
    (id, active_flag, remove) => {
      return [
        ...(active_flag
          ? [
              {
                id: 1,
                name: (
                  <Link
                    to={`/companies/company-detail/${id}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}>
                    Editar
                  </Link>
                ),
                method: () => {},
              },
            ]
          : []),
        {
          id: 2,
          name: active_flag != 1 ? 'Ativar' : 'Inativar',
          method: function changeCompanyStatusFlag() {
            setIsCompanyStatusFlagModalOpen();
            setCompanyId(id);
            setStatusFlag(active_flag);
          },
        },
        ...(remove
          ? [
              {
                id: 3,
                name: 'Excluir',
                method: function deleteCompany() {
                  setIsDeleteCompanyModalOpen();
                  setCompanyId(id);
                },
              },
            ]
          : []),
      ];
    },
    [setIsCompanyStatusFlagModalOpen, setIsDeleteCompanyModalOpen]
  );

  const handleDrop = useCallback(
    async result => {
      if (!result.destination) {
        return;
      }
      const companiesIdArray = [] as any;
      const items = companies || [];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      items.map((c: ICompany) => companiesIdArray.push(c.id_code));

      setCompanies(items);

      try {
        await api.post('/companies/update-order', {
          order: companiesIdArray,
        });
        toast.success('Empresa reordenada com sucesso!');
      } catch (error) {}
    },
    [companies]
  );

  useEffect(() => {
    setHasActiveCompanies(false);
    var actives = 0;
    companies.map((company: ICompany) => {
      if (company.active_flag == 1) {
        actives += 1;
        setHasActiveCompanies(true);
      }
    });

    actives <= 1 ? setDisableDraggable(true) : setDisableDraggable(false);
  }, [companies]);

  useEffect(() => {
    (async () => {
      setLoading(1);
      try {
        const response = await api.get('/companies');
        const companiesList = response.data.data;
        setCompanies(companiesList);
        setLoading(0);
        if (companiesList.length === 0) {
          setNoCompaniesMessage('Nenhuma empresa adicionada');
          setHasCompanies(false);
        } else {
          setNoCompaniesMessage('');
          setHasCompanies(true);
        }
      } catch (error) {
        setLoading(0);
      }
    })();
  }, [forceToReload]);

  const companyStatusFlagModal = (
    <Modal
      isOpen={isCompanyStatusFlagModalOpen}
      onRequestClose={setIsCompanyStatusFlagModalOpen}
      closeModal={setIsCompanyStatusFlagModalOpen}
      smallWidth>
      <CompanyStatusFlagContent
        id={companyId}
        closeModal={closeModal}
        status={statusFlag}
      />
    </Modal>
  );

  const companyDeleteModal = (
    <Modal
      isOpen={isDeleteCompanyModalOpen}
      onRequestClose={setIsDeleteCompanyModalOpen}
      closeModal={setIsDeleteCompanyModalOpen}
      smallWidth>
      <CompanyDeleteContent id={companyId} closeModal={closeModal} />
    </Modal>
  );

  return (
    <Container>
      {companyDeleteModal}
      {companyStatusFlagModal}
      <PageTopbar
        title="Empresas"
        subTitle="Gerencie as empresas relacionadas com seus produtos Azcend"
      />
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="droppable">
          {provided => (
            <Card
              className="companies-list-card"
              loading={loading}
              style={{ minHeight: `${loading ? '200px' : '0'}` }}>
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ borderRadius: '20px' }}>
                {companies &&
                  companies?.map(
                    (company: ICompany, index) =>
                      company.active_flag == 1 && (
                        <Draggable
                          key={company.id_code}
                          draggableId={company.id_code || ''}
                          index={index}
                          isDragDisabled={disableDraggable}>
                          {(prov, snapshot) => (
                            <div
                              ref={prov.innerRef}
                              {...prov.draggableProps}
                              {...prov.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                prov.draggableProps.style
                              )}>
                              <CompanyBox
                                menuList={EditCompany(
                                  company.id_code,
                                  company.active_flag,
                                  company.can_remove
                                )}
                                key={company.id_code}>
                                <div className="icon-div">
                                  {companies.length > 1 ? (
                                    <img
                                      className="drag-icon"
                                      src={dragIcon}
                                      alt=""
                                    />
                                  ) : (
                                    ''
                                  )}

                                  <img src={suitcaseIcon} alt="" />
                                </div>
                                <Content>
                                  <Row>
                                    <Link
                                      to={`/companies/company-detail/${company.id_code}`}
                                      style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                      }}
                                      className="col-sm-8 col-12">
                                      <h5 className="header company-name col-12">
                                        {company.fantasy_name}
                                      </h5>
                                    </Link>
                                    <h5 className="header company-name col-sm-4 col-12">
                                      {company.document}
                                    </h5>
                                  </Row>
                                  <div className="company-status row">
                                    <div className="status-date col-sm-7 col-12">
                                      <p className="register-date">
                                        Cadastrado em{' '}
                                        {company.created_at?.replace(/ .*/, '')}
                                      </p>
                                      <CompanyStatus
                                        isCompanyActive={company.active_flag}>
                                        {company.active_flag == 0
                                          ? 'Inativo'
                                          : 'Ativo'}
                                      </CompanyStatus>
                                    </div>
                                    <Status className="col-sm-4 col-12">
                                      <div className="desktop">
                                        <p>Status:</p>
                                        <Badge status={company.status} />
                                      </div>

                                      <div className="mobile">
                                        <BadgeDot status={company.status} />
                                      </div>
                                    </Status>
                                  </div>
                                </Content>
                              </CompanyBox>
                            </div>
                          )}
                        </Draggable>
                      )
                  )}
                {provided.placeholder}
              </div>
            </Card>
          )}
        </Droppable>
      </DragDropContext>

      <Card className="companies-list-card-inactive">
        {companies &&
          companies?.map(
            (company: ICompany, index) =>
              company.active_flag != 1 && (
                <CompanyBoxInactive
                  menuList={EditCompany(
                    company.id_code,
                    company.active_flag,
                    company.can_remove
                  )}
                  key={company.id_code}>
                  <div className="icon-div">
                    <img src={suitcaseIcon} alt="" />
                  </div>
                  <Content>
                    <Row>
                      <h5 className="header company-name-disabled col-sm-8 col-xs-12">
                        {company.fantasy_name}
                      </h5>
                      <h5 className="header col-sm-4 col-xs-12">
                        {company.document}
                      </h5>
                    </Row>
                    <div className="company-status row">
                      <div className="status-date col-sm-7 col-12">
                        <p className="register-date">
                          Cadastrado em {company.created_at?.replace(/ .*/, '')}
                        </p>
                        <CompanyStatus isCompanyActive={company.active_flag}>
                          {company.active_flag == 0 ? 'Inativo' : 'Ativo'}
                        </CompanyStatus>
                      </div>
                      <Status className="col-sm-4 col-12">
                        <div className="desktop">
                          <p>Status:</p>
                          <Badge status={company.status} />
                        </div>

                        <div className="mobile">
                          <BadgeDot status={company.status} />
                        </div>
                      </Status>
                    </div>
                  </Content>
                </CompanyBoxInactive>
              )
          )}

        <p className="no-companies-message">{noCompaniesMessage}</p>
        <p
          className="tip-message"
          style={{
            display:
              hasActiveCompanies && hasCompanies && companies.length > 1
                ? 'block'
                : 'none',
          }}>
          <b>Dica:</b> Utilize o drag and drop{' '}
          <img className="min-drag-icon" src={dragIcon} alt="" /> para reodernar
          as empresas.
        </p>
      </Card>
      {user?.biometry_status == '3' && (
        <AddButton to={'/companies/company-create-juridico'} />
      )}
    </Container>
  );
};

export default Companies;
