import React from 'react'
import iconCheckCircle from '../../../../assets/icon_check_circle.svg'
import { Container } from './styles'
import Button from '../../../../components/Button';

interface ValidationSuccess {
    onCancel?: any;    
    transferType?:String;  
}

const ModalValidationSuccess: React.FC<ValidationSuccess> = ({ onCancel,transferType}) => {
    return (
        <Container>
            <div>
                <img src={iconCheckCircle} alt="Success"></img>
                <h1>
                    <b>{transferType=='PIX'? 'Chave PIX':'Conta bancária'} validada!</b>
                </h1>
                <p>Verificamos e não há nenhum erro com o qual se preocupar. Relaxe e siga vendendo!</p>
                
            </div>
            <div className="buttons-container">               
                <Button className="ok-button" onClick={onCancel}>Fechar</Button>
            </div>
        </Container>
    )
}

export default ModalValidationSuccess;