import styled, { css } from 'styled-components';
import * as color from '../../styles/colors';
import checkIcon from '../../assets/icons/checkmark-icon.svg';
import redCrossIcon from '../../assets/icons/red-cross-icon.svg';

interface PasswordProps {
  goodPassword?: boolean;
}

export const Container = styled.div`
  margin-bottom: 20px 0 20px 0 !important;
  
  line-height: 28px;
  padding: 10px;
  background: #FAFAFA;
  border-radius: 4px;
  
`;

export const ValidationStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const ValidationText = styled.p<PasswordProps>`
  font-size: 12px;
  color: ${color.grey};
`;

export const CircleStatus = styled.div<PasswordProps>`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border-radius: 15px;
  transition: background .5s;

  ${({ goodPassword }) =>
    goodPassword
      ? css`
          background: url(${checkIcon}) no-repeat center/15px 14px;
        `
      : css`
          background: url(${redCrossIcon}) no-repeat center/15px 14px;
        `}
`;

export const BoldText = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #636363;
`;
