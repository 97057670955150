import React from 'react'
import iconReject from '../../../../assets/icon_reject.svg'
import { Container } from './styles'
import Button from '../../../../components/Button';
interface ValidationRejectProps {
    onConfirm?: any;
    onCancel?: any;
    company?: any;
    transferType?:string
}


const ModalValidationReject: React.FC<ValidationRejectProps> = ({ onConfirm, onCancel, company, transferType }) => {
    return (
        <Container>
            <div>
                <img src={iconReject} alt="Sending"></img>
                <h1>
                    <b>
                        {transferType=='PIX' ? 'Não conseguimos validar a chave PIX':'Não conseguimos validar sua conta'}
                    </b>
                </h1>
                <p>{
                    transferType=='PIX' ?
                    `Verifique se sua chave PIX existe e se é de uma conta relacionada com o seu ${company?.type==1?'CPF':'CNPJ'}`:
                    `Verifique os dados digitados e se a conta bancária está vinculada ao seu ${company?.type==1?'CPF':'CNPJ'}. Não são aceitas contas de terceiros ou de pessoas físicas.`
                }</p>               
            </div>
            <div className="buttons-container">
                <Button className="cancel-button" onClick={onCancel}>
                    Fechar
                </Button>
                <Button className="ok-button" onClick={onConfirm}>
                    Nova {transferType=='PIX'?'conta':'chave'}
                </Button>
            </div>
        </Container>
    )
}

export default ModalValidationReject;