import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../components/Button";
import Form from "../../components/Form";

import api from "../../services/api";
import { login, logout } from "../../services/auth.js";

import { AccountsContext as Context } from "../../context/AccountsContext";

import { Container, Row } from "./styles";
import AnimatedPurpleContainerPage from "../../components/AnimatedPurpleContainerPage";
import TextInput from "../../components/FormInputs/TextInput";
import { validateEmail } from "../../utils/functions";

const Signin: React.FC = () => {
    const { setUser } = useContext(Context);

    const history = useHistory();
    const [loading, setLoading] = useState<number>(0);

    const [errorEmailMsg, setErrorEmailMsg] = useState("");
    const [emailStatus, setEmailStatus] = useState("");

    const [disabledLogin, setDisabledLogin] = useState(true);

    const [errorPasswordMsg, setErrorPasswordMsg] = useState("");

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [from, setFrom] = useState<string>("");

    useEffect(() => {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const codeFromURL = params.get("from");
        codeFromURL ? setFrom(codeFromURL) : setFrom("");
    }, [from]);

    useEffect(() => {
        if (password.length > 0 && email.length > 0 && errorEmailMsg.length === 0) {
            setDisabledLogin(false);
        } else {
            setDisabledLogin(true);
        }
    }, [email, password, errorEmailMsg]);

    const handleEmailValidate = () => {
        setEmailStatus("");

        if (!validateEmail(email)) {
            setErrorEmailMsg("Por favor, preencha com um e-mail válido.");
        }
    };

    async function handleLogin(e: any) {
        e.preventDefault();
        setLoading(1);
        try {
            const response = await api.post("/login", {
                email,
                password,
                from,
            });

            const userRes = response.data.user;
            localStorage.clear();
            setUser(userRes);
            login(response.data.token);

            if (from) {
                const response = await api.post("/send-authenticated");
                window.location.href = response.data.data.url;
            } else {
                setLoading(0);
                history.push("/home");
            }
        } catch (error) {
            setLoading(0);
            //@ts-ignore
            const errors = error.response.data;

            if (errors.errors !== undefined) {
                if (errors.errors.email !== undefined) setErrorEmailMsg(errors.errors.email[0]);

                if (errors.errors.password !== undefined) setErrorPasswordMsg(errors.errors.password[0]);
            } else {
                setErrorPasswordMsg(errors.message);
            }
        }
    }

    return (
        <AnimatedPurpleContainerPage loading={loading} intro={false}>
            <Container>
                <h1>Acesse sua conta</h1>

                <Form>
                    <TextInput
                        id="email"
                        name="email"
                        mask=""
                        type="email"
                        placeholder="Seu e-mail"
                        value={email}
                        onChange={(e) => {
                            setErrorEmailMsg("");
                            setEmail(e.currentTarget.value);
                        }}
                        onBlur={handleEmailValidate}
                        error={errorEmailMsg}
                        status={emailStatus}
                    />

                    <TextInput
                        id="password"
                        name="password"
                        mask=""
                        type="password"
                        placeholder="Sua senha"
                        value={password}
                        onChange={(e) => {
                            setErrorPasswordMsg("");
                            setPassword(e.currentTarget.value);
                        }}
                        error={errorPasswordMsg}
                    />

                    <Row style={{ justifyContent: "flex-end" }}>
                        <Link to="/forgot-password" className="btn-forgot-password">
                            Esqueceu sua senha?
                        </Link>
                    </Row>

                    <Button
                        primary
                        type="submit"
                        className="btn-signin"
                        textTransform
                        onClick={handleLogin}
                        disabled={false}
                    >
                        FAZER LOGIN
                    </Button>
                </Form>
                <Row>
                    <span className="has-account">
                        Não tem uma conta?
                        <Link to="/signup" className="link">
                            Cadastre-se
                        </Link>
                    </span>
                </Row>
            </Container>
        </AnimatedPurpleContainerPage>
    );
};

export default Signin;
