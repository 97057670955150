import styled from 'styled-components';
import InputMask from 'react-input-mask';
import * as color from '../../../styles/colors';
import eyeOpenIcon from '../../../assets/icons/eye-open.svg';
import eyeClosedIcon from '../../../assets/icons/eye-closed.svg';

interface InputProps {
  error?: string;
  passwordType?: string;
  status?: string;
}

interface TextWrapperProps {
  split?: boolean;
  error?: string;
}

export const TextWrapper = styled.div<TextWrapperProps>`
  position: relative;
  width: ${({ split }) => (split ? '45%' : '100%')};
  height: 49px;
  margin-bottom: ${(props) => (props.error ? '30px' : '20px')};
`;

export const Input = styled(InputMask)<InputProps>`
  width: 100%;
  height: 100%;
  border: 1px solid ${({error}) => (error ? color.red : '#C9C9C9' )};
  border-radius: 4px;
  outline: 0;
  padding: 5px 10px;
  background: #ffffff;
  color: #2e2e2e;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 13px;

  ::placeholder {
    color: transparent;
  }

  :placeholder-shown ~ .form__label {
    font-size: 13px;
    cursor: text;
    top: 15px;
    left: 10px;
    color: ${({ error }) => (error ? color.red : '#999999')};
    opacity: 1;
  }

  :focus ~ .form__label {
    color: ${({ error }) => (error ? color.red : '#999999')};
    position: absolute;
    top: -10px;
    left: 10px;
    display: block;
    transition: 0.2s;
    font-size: 13px;
    opacity: 1;
  }

  :focus {
    border: 1px solid ${({ error }) => (error ? color.red : '#999999')};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 99999s ease-out, background-color 99999s ease-out";
    -webkit-transition-delay: 9999s;
  }

  &:-webkit-autofill ~ .form__label,
  &:-webkit-autofill:hover ~ .form__label,
  &:-webkit-autofill:focus ~ .form__label,
  &:-webkit-autofill:active ~ .form__label {
    color: ${({ error }) => (error ? color.red : '#999999')};
    position: absolute;
    top: -10px;
    left: 10px;
    display: block;
    transition: 0.2s;
    font-size: 13px;
    opacity: 1;
  }

  ${({ status }) => (status === 'success' ? 'border: 1px solid ' + color.green : '')};
  
`;

export const TextLabel = styled.label<InputProps>`
  position: absolute;
  top: -10px;
  left: 10px;
  display: block;
  transition: 0.2s;
  font-weight: normal;
  font-size: 13px;
  color: ${({ error }) => (error ? color.red : '#bcbcbc')};
  font-family: 'Inter', sans-serif;
  background: #fff;
  padding: 0 2px;
  /* opacity: 0.7; */
`;

export const ShowPasswordButton = styled.button<InputProps>`
  width: 20px;
  height: 20px;
  background-image: url(${({ passwordType }) => (passwordType === "password" ? eyeOpenIcon : eyeClosedIcon)});
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-size: ${({ passwordType }) => (passwordType === "password" ? '20px' : '24px')};
  border: none;
  cursor: pointer;
  top: 14px;
  right: 15px;
  position: absolute;
  filter: opacity(0.5);
`;
