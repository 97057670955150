import React, { useCallback, useContext, useEffect, useState } from "react";
import {} from "./styles";
import RegisterForm from "./RegisterForm";
import AnimatedPurpleContainerPage from "../../components/AnimatedPurpleContainerPage";
import EmailTokenView from "./EmailTokenView";
import { RegisterContext as Context } from "../../context/RegisterContext";
import PhoneTokenView from "./PhoneTokenView";
import ErrorRegister from "./ErrorRegister";

import TagManager from "react-gtm-module";

const Register: React.FC = () => {
    const { step, setHasPromotionalTax, setInviteCode } = useContext(Context);

    useEffect(() => {
        const tagManagerArgs = {
            gtmId: "GTM-WNPXQQ5T", // GTM Enviado pela equipe do marketing v4 Company
        };

        TagManager.initialize(tagManagerArgs);
    }, []);

    // Promotional Tax
    useEffect(() => {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const promotionalTax = params.get("promotional-tax");
        if (promotionalTax) {
            setHasPromotionalTax(promotionalTax);
        }

        const codeFromURL = params.get("i");
        codeFromURL ? setInviteCode(codeFromURL) : setInviteCode("");
    });

    const renderContent = useCallback(() => {
        switch (step) {
            case 1:
                return <RegisterForm />;
            case 2:
                return <EmailTokenView />;
            case 3:
                return <PhoneTokenView />;
            case 4:
                return <ErrorRegister />;
            default:
                return <RegisterForm />;
        }
    }, [step]);

    return (
        <>
            {
                <AnimatedPurpleContainerPage loading={0} intro={false}>
                    {renderContent()}
                </AnimatedPurpleContainerPage>
            }
        </>
    );
};

export default Register;
