import React from 'react';
import { Container } from './styles';
import PageTopbar from '../../../../components/PageTopbar';
import GobackLink from '../../../../components/GoBackLink';
import SiriusNotifications from './SiriusNotifications';
import SiriusAbout from './SiriusAbout';
import SiriusCompanies from './SiriusCompanies';

const SiriusPage: React.FC = () => {
  return (
    <Container>
      <PageTopbar
        title="Azcend"
        subTitle="Gerencie seu gateway de pagamentos"
      />
      {/* <GobackLink to="/services" className="goback-button" /> */}

      <SiriusAbout />
      <SiriusCompanies />
      <SiriusNotifications />
    </Container>
  );
};

export default SiriusPage;
