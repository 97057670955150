import React from 'react'
import iconSending from '../../../../assets/icon_sending.svg'
import { Container } from './styles'
import Dot3Loading from '../../../../components/Dot3Loading';

interface SendingDataProps {
    title?:string
}

const ModalSendingData: React.FC<SendingDataProps> = ({ title }) => {
    return (
        <Container>
            <div>
                <img src={iconSending} alt="Sending"></img>
                <h1>
                    <b>
                        {title ? title:'Enviando seus dados...'}
                    </b>
                </h1>
                <p>A confirmação normalmente tarda 1 minuto.</p>
                <br/>
                <Dot3Loading color='#C4C4C4'></Dot3Loading>
            </div>
        </Container>
    )
}

export default ModalSendingData;