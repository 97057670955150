import React, { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { Container } from "./styles";

interface ScrollContainerProps {
  height?: string | number;
  width?: string | number;
  className?: string;
  fadeEfect?: boolean;
  ref?: string;
}

const ScrollContainer: React.FC<ScrollContainerProps> = ({
  children,
  height,
  width,
  className,
  fadeEfect,
}) => {
  return (
    <Container
      className={className}
      height={height || "auto"}
      width={width || "auto"}
    >
      {fadeEfect && <div className="transparent-effect top"></div>}
      {children}
      {fadeEfect && <div className="transparent-effect botton"></div>}
    </Container>
  );
};

export default ScrollContainer;
