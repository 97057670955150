/* eslint-disable eqeqeq */
import React, { useContext, useEffect } from 'react';
import { Container } from './styles';
import PersonalForm from './PersonalForm';
import ResidenceForm from './ResidenceForm';
import PageTopbar from '../../../components/PageTopbar';
import api from '../../../services/api';

import { AccountsContext as Context } from '../../../context/AccountsContext';

const PersonalInfo: React.FC = () => {
  const { user, setUser } = useContext(Context);

  useEffect(() => {
    async function getUser() {
      try {
        const response = await api.get('/me');
        setUser(response.data.data);
      } catch (error) {}
    }

    getUser();
  }, [setUser]);

  return (
    <Container>
      <PageTopbar
        title="Informações pessoais"
        subTitle="Gerencie suas informações e documentos atrelados a sua conta Azcend"
      />
      <PersonalForm user={user} setUser={setUser} />

      {/* {user?.is_account_owner && user?.biometry_status == '3' && (
        <>
          <ResidenceForm user={user} setUser={setUser} />
        </>
      )} */}
    </Container>
  );
};

export default PersonalInfo;
