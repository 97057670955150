import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button";

import api from "../../services/api";

import { Container, Row } from "./styles";
import AnimatedPurpleContainerPage from "../../components/AnimatedPurpleContainerPage";
import TextInput from "../../components/FormInputs/TextInput";
import { validateEmail } from "../../utils/functions";

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [emailStatus, setEmailStatus] = useState<string>("");

  const handleEmailValidate = () => {
    setEmailStatus('');

    if (!validateEmail(email)) {
      setErrorMsg("Por favor, preencha com um e-mail válido.");
      setEmailStatus('')
    } else {
      setEmailStatus('success')
      setErrorMsg("");
    }
  }

  const history = useHistory();

  const sendEmail = async () => {
    if (!email || !validateEmail(email)) {
      setErrorMsg("Por favor, preencha com um e-mail válido.");
    } else {
      setErrorMsg("");
      setLoading(1);
      const formData = new FormData();
      formData.append("email", email);
      try {
        const res = await api.post("/password/email", formData);
        setLoading(0);
        toast.success(res.data.message);
        setEmail("");
        history.push('/')
      } catch (error) {
        //@ts-ignore
        setErrorMsg(error.response.data.message ?? error.response.error);
        setEmailStatus('')
        setLoading(0);
      }
    }
  };

  return (
    <AnimatedPurpleContainerPage loading={loading} intro={false}>
      <Container>
        <h1>Esqueceu a senha?</h1>
        <p>
          Para recuperar seu acesso, preencha o campo com o e-mail cadastrado e
          lhe enviaremos informações para redefinição.
        </p>

        <TextInput
          id="email"
          name="email"
          mask=""
          type="email"
          placeholder="Seu e-mail"
          value={email}
          onChange={(e) => {
            setErrorMsg("");
            setEmail(e.currentTarget.value);
          }}
          error={errorMsg}
          status={emailStatus}
          onBlur={handleEmailValidate}
        />

        <Button
          primary
          type="button"
          className="btn-signin"
          textTransform
          onClick={sendEmail}
        >
          RECUPERAR ACESSO
        </Button>
        <Row>
          <span className="has-account">
            Não tem uma contra?
            <Link to="/signup" className="link">
              Cadastre-se
            </Link>
          </span>
        </Row>
      </Container>
    </AnimatedPurpleContainerPage>
  );
};

export default ForgotPassword;
