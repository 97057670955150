import styled, { css } from 'styled-components';
// import { Controller as ReactHookFormController } from 'react-hook-form';
import * as color from '../../styles/colors';

interface InputProps {
  status?: number;
  error?: string;
}

export const Container = styled.div`
  position: relative;
  padding: 0 15px;
  margin: 0 0 16px;

  .password-button {
    width: auto;
    height: 24px;

    top: 42px;
    right: 28px;
    position: absolute;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${color.darkgrey};
  margin-left: 10px;
  line-height: 27px;
`;

// export const Controller = styled(ReactHookFormController)``;

export const Input = styled.input<InputProps>`
  height: 48px;
  width: 100%;
  border-radius: 10px;
  padding: 6px 12px;
  border: 1px solid #c3c6d1;

  ${({ status }) =>
    status === 1 &&
    css`
      border: 2px solid ${color.green};
      padding-left: 45px;
    `}

  ${({ status }) =>
    status === 0 &&
    css`
      border: 2px solid ${color.red};
      padding-left: 45px;
    `}
`;
