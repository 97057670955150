import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import * as color from '../../../styles/colors';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  opacity: 0;

  min-width: 250px;
  min-height: 420px;
  max-width: 360px;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #1B1B1B;
    margin-bottom: 24px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 115%;
    color: #464646;
  }

  b {
    font-weight: 700;
  }

  .verification-code-input {
    width: 100% !important;
    margin: 20px 0;

    > div {
      display: flex;
      justify-content: space-between;

      input {
        width: 65px !important;
        height: 65px !important;
        border: 1.08456px solid #C9C9C9;
        border-radius: 4.33824px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 26.0294px;
        line-height: 32px;
        color: #636363;
      }

      @media (max-width: 400px) {
        input {
          width: 50px !important;
          height: 50px !important;
        }
      }

    }
  }


  .has-account {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30px;
    text-align: center;

    margin: 24px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #1B1B1B;

    .link {
      font-weight: 600;
      color: ${color.orange};
      margin-left: 3px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .obs-register {
    text-align: center;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;    
    color: #8E8E8E;
  }


  @media (min-width: 576px) {
    width: 420px;
    padding: 10px;
  }

  animation: 1s ${fadeIn} ease-out forwards;
`;

export const ExpiringCodeText = styled.div`
  font-size: 12px;
  text-align: center;
  color: #595959;
  margin-top: 20px;
  margin-bottom: 17px;
`;

export const SendCodeText = styled.button`
  text-align: center;
  color: ${color.orange};
  border: 0;
  background: transparent;

  cursor: pointer;

  &:hover {
    color: ${shade(0.2, color.orange)};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const GobackButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  width: 100%;
  height: 16px;
  margin-bottom: 12px;

  background: transparent;

  border: none;

  font-weight: 600;
  font-size: 14px;
  line-height: 115%;
  color: #999999;

  .goback-icon {
    margin-right: 10px;
  }
`;

export const ErrorMessageContainer = styled.div`
  
  background: #FAFAFA;
  padding: 10px;
  margin-bottom: 10px;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 115%;
    display: flex;
    align-items: center;
    color: #D10000;
    margin-bottom: 10px;

    svg {
      margin-right: 5px;
    }
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 115%;
    display: flex;
    align-items: center;
    color: #787878;
  }
`;

