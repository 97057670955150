/* eslint-disable eqeqeq */
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ContentBox from '../../../components/ContentBox';
import * as color from '../../../styles/colors';
import plusIcon from '../../../assets/plus.svg';

interface CompaniesProps {
  isCompanyActive?: number;
}

export const Container = styled.div`
  position: relative;

  .flex-container {
    display: flex;
  }

  .companies-list-card {
    
    border-radius: 20px 20px 0 0;
    padding: 30px 30px 1px  30px;
  }

  .companies-list-card-inactive {
    
    border-radius: 0 0 20px 20px;
    padding: 0 30px 30px 30px;
  }

  .no-companies-message {
    font-size: 20px;
    text-align: center;
  }

  .tip-message {
    font-size: 12px;
    text-align: center;
    color: ${color.grey}
  }

  img {
    margin-right: 28px;
    width: 40px;
    height: 40px;
    align-self: center;
  }

  .drag-icon {
    margin-top: 2px;
    margin-right: 15px;
    width: 10px;
    height: 17px;
  }

  .min-drag-icon {    
    margin: 1px;
    width: 10px;
    height: 12px;
  }

  @media (max-width: 769px) {
    margin-bottom: 50px;
  }
`;

export const CompanyBox = styled(ContentBox)`
  display: flex;
  padding: 16px;

  .dropdown-container {
    top: 10px;
  }

  .icon-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 769px) {
    flex-direction: column;

    .icon-div {
      align-self: baseline;
    }
  }
`;

export const CompanyBoxInactive = styled(ContentBox)`
  display: flex;
  padding: 16px;
  background-color: rgb(255 245 245);
  margin-bottom: 20px;

  .dropdown-container {
    top: 10px;
  }

  .icon-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 769px) {
    flex-direction: column;

    .icon-div {
      align-self: baseline;
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;

  .header {
    font-weight: bold;
  }

  .register-date {
    color: rgba(116, 116, 116, 0.6); 
  }

  .company-status {
    font-size: 14px;
    justify-content: space-between;
    margin: 15px 0 0;

    .status-date {
      display: flex;
      align-items: center;
    }
  }

  .service-status {
    margin-top: 15px;
  }

  .company-name {
    font-size: 14px;
    padding-right: 10px;

    &:hover {
      color: ${color.orange};
    }
  }

  .company-name-disabled {
    padding-right: 10px;
  }

  @media (max-width: 576px) {
  .company-name {
    font-size: 12px;
  }

  .register-date{
    font-size: 12px;
    
  }

  @media (max-width: 350px) {
    .register-date {
      width: 100px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 576px) {
    flex-direction: column;
  }

`;

export const ServiceType = styled.div`
  position: relative;
  margin: 10px 0 0 20px;
  color: ${color.darkgrey};
  ::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: #00be9b;
    border-radius: 10px;
    left: -20px;
    top: 3px;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;

  > p {
    margin-right: 8px;
  }

  .desktop {
    display: flex;
    gap: 3px;
    align-items: center;
  }

  .mobile {
    display: none;
  }

  @media (max-width: 576px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
    }
  }
`;

export const AddButton = styled(Link)`
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background: url(${plusIcon}) no-repeat center ${color.orange};
  background-size: 24px;
  border: 1px solid ${color.orange};
  position: fixed;
  right: 55px;
  bottom: 30px;
  box-shadow: 0px 12px 25px rgba(14, 40, 93, 0.25);

  @media (max-width: 769px) {
    margin-top: 20px;
    right: 20px;
    bottom: 10px;
    width: 40px;
    height: 40px;
  }
`;

export const CompanyStatus = styled.p<CompaniesProps>`
  font-size: 14px;
  font-weight: bold;
  margin-left: 15px;
  line-height: 14px;
  color: ${({ isCompanyActive }) =>
    isCompanyActive == 0 ? `${color.red}` : `${color.green}`} !important;

  @media (max-width: 769px) {
    font-size: 12px;
  }
`;
