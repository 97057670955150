import React from 'react';
import { Container } from './styles';

interface BadgeProps {
  status?: string;
  className?: string;
  pulse?: boolean;
}

const BadgeDot: React.FC<BadgeProps> = ({ status, className, pulse }) => {
  return (
    <Container status={status} className={className} pulse={pulse||false}>
      <div className="ringring"></div>
      <div className="circle"></div>
    </Container>
  );
};

export default BadgeDot;
