import React, { ReactNode, useEffect } from "react";
import { MobileContainer, ChildrenContent, Container, Content, PageContainer } from "./styles";
import Topbar from "../Topbar";
import Loader from "../Loader";
// @ts-ignore
import TypeWriterEffect from "react-typewriter-effect";

import azcendLogo from "../../assets/logos/2022/logo_horizontal_white.png";
import blueBlurOne from "../../assets/purple-container/blurs/blur-blue-1.svg";
import purpleBlurOne from "../../assets/purple-container/blurs/blur-purple-1.svg";

import ScrollContainer from "../ScrollContainer";
import PurpleBackgroundContainer from "../PurpleBackgroundContainer";

interface ContainerProps {
    children: ReactNode;
    topbar?: boolean;
    loading?: number;
    loadingText?: string;
    intro?: boolean;
}

const AnimatedPurpleContainerPage: React.FC<ContainerProps> = ({ children, loading, loadingText, intro }) => {
    return (
        <Container>
            <Topbar />
            <PageContainer>
                <PurpleBackgroundContainer className="purple-bkg" intro={intro || false} />
                <Content intro={intro}>
                    <ScrollContainer className="scroll-container">
                        <MobileContainer>
                            <div className="blurs">
                                <img className="blur purpleBlurOne" src={purpleBlurOne} />
                                <img className="blur blueBlurOne" src={blueBlurOne} />
                            </div>

                            <div className="content-logo">
                                <img src={azcendLogo} alt="" />

                                <h1 className="title">Aqui você vende</h1>

                                <div className="typing-container">
                                    <TypeWriterEffect
                                        textStyle={{
                                            color: "#00b7ff",
                                            fontWeight: 700,
                                            fontSize: "26px",
                                        }}
                                        startDelay={3000}
                                        cursorColor="#00b7ff"
                                        multiText={["produtos físicos", "assinaturas", "qualidade", "importados"]}
                                        multiTextDelay={3000}
                                        typeSpeed={50}
                                        loop={true}
                                    />
                                </div>

                                <p className="subtitle">
                                    A solução em pagamentos online com as taxas de aprovação mais altas do Brasil!
                                </p>
                            </div>
                        </MobileContainer>

                        <ChildrenContent>
                            <Loader loading={loading} loadingText={loadingText} />
                            {children}
                        </ChildrenContent>
                    </ScrollContainer>
                </Content>
            </PageContainer>
        </Container>
    );
};

export default AnimatedPurpleContainerPage;
