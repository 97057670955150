import React, { useState, useCallback, useRef } from "react";
import { TextWrapper, Input, TextLabel, ShowPasswordButton } from "./styles";
import ErrorMessage from "../../../components/ErrorMessage";

interface InputProps {
  id?: string;
  className?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  split?: boolean;
  mask: string;
  maskChar?: string | null;
  type?: string;
  error?: string;
  onChange?: (arg0: any) => void;
  onBlur?: (arg0: any) => void;
  disabled?: boolean;
  status?: string;
}

const TextInput: React.FC<InputProps> = ({
  id,
  className,
  name,
  value,
  split,
  mask,
  maskChar,
  type,
  error,
  placeholder,
  onChange,
  onBlur,
  disabled,
  status,
}) => {
  const inputRef = useRef<any>();
  const [passwordType, setPasswordType] = useState(type);

  const handleShow = useCallback(() => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType(type);
    }
  }, [passwordType, type]);

  return (
    <TextWrapper split={split} className={className} error={error} >
      <Input
        id={id}
        name={name}
        placeholder={placeholder}
        ref={inputRef}
        error={error}
        mask={mask}
        maskChar={maskChar}
        data-lpignore="true"
        disabled={disabled}
        value={value}
        type={type === "password" ? passwordType : type}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="nope"
        status={status}
      />
      <TextLabel
        onClick={() => inputRef.current.getInputDOMNode().focus()}
        className="form__label"
        htmlFor={id}
        error={error}
        // value={value}
      >
        {placeholder}
      </TextLabel>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {type === "password" && (
        <ShowPasswordButton
          className="form__show"
          type="button"
          onClick={() => handleShow()}
          passwordType={passwordType}
        />
      )}
    </TextWrapper>
  );
};

export default TextInput;
