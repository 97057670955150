import styled, { keyframes } from "styled-components";
import * as color from "../../styles/colors";

interface ContentProps {
    intro?: boolean;
}

const fadeOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const blursAnimation = keyframes`
  25% {
    transform: translate(500px, -500px) rotate(35deg);
    opacity: 0.5;
  }
  50% {
    transform: translate(500px, 500px) rotate(0deg);
    opacity: 1;
  }
  75% {
    transform: translate(600px, -700px) rotate(-15deg);
    opacity: 0.5;
  }
  100% {
    transform: translate(600px, 100px) rotate(0deg);
    opacity: 1;
  }
`;

const float = keyframes`
  25% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const Container = styled.div`
    position: relative;
    flex: 1;
    overflow: hidden;
    width: 100%;
    height: 100%;

    animation: 0.5s ${fadeIn};
    animation-fill-mode: forwards;
`;

export const Blurs = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;

    .blueBlurOne {
        position: absolute;
        top: -500px;
        left: -500px;
        z-index: 1;

        animation: 10s ${float} Infinite;
    }

    .purpleBlurOne {
        position: absolute;
        bottom: -300px;
        right: 0px;
        z-index: 1;

        animation: 10s ${float} Infinite;
    }

    .blueBlurTwo {
        position: absolute;
        top: -100px;
        left: -100px;
        z-index: 1;

        animation: 10s ${float} Infinite;
    }

    .purpleBlurTwo {
        position: absolute;
        bottom: -100px;
        right: 200px;
        z-index: 1;

        animation: 10s ${float} Infinite;
    }

    .purpleBlurThree {
        position: absolute;
        bottom: -170px;
        left: -900px;
        z-index: 1;
        opacity: 0.5;

        animation: 10s ${float} Infinite;
    }

    animation: 11s ${blursAnimation};
    animation-delay: 2s;
    animation-fill-mode: forwards;
`;

export const Content = styled.div<ContentProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: base;
    width: 100%;
    height: 100%;
    background: #021318;
    padding: 8%;

    .text {
        z-index: 2;
        opacity: 0;

        .logo {
            width: 210px;
            margin-bottom: 24px;
            z-index: 2;
        }

        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 55px;
            line-height: 100%;
            color: ${color.white};
            z-index: 2;
        }

        .title.orange,
        .title.orange span {
            color: ${color.orange};
            z-index: 2;
        }

        .title.orange span {
            color: ${color.orange};
        }

        .subtitle {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: ${color.white};
            z-index: 2;
        }

        .typing-container {
            height: 70px;
            margin-bottom: 16px;
        }

        .type-cursor {
            background: ${color.orange};
            line-height: 60px;
            margin-left: 3px;
            animation: blink 0.8s infinite;
            width: 7px;
            height: 40px;
            display: inline-block;
        }

        @keyframes blink {
            50% {
                opacity: 0;
            }
        }

        animation: 0.5s ${fadeIn};
        animation-delay: ${(props) => (props.intro === true ? "5s" : "0s")};
        animation-fill-mode: forwards;

        @media only screen and (max-width: 1076px) {
            .title {
                font-size: 40px;
            }
            .subtitle {
                font-size: 15px;
            }
        }

        @media only screen and (max-width: 890px) {
            display: none;
        }
    }

    .react-typewriter-pointer {
        padding: 2px 3px;
    }

    .logo-center {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 128px;
        height: 128px;
        opacity: 0;

        display: ${(props) => (props.intro === true ? "flex" : "none")};
        justify-content: center;
        align-items: center;

        animation: 5s ${fadeOut};
        animation-fill-mode: foward;
    }

    @media only screen and (max-width: 890px) {
        .logo-center img {
            width: 80px;
            height: 80px;
        }
    }
`;
