import React, { createContext } from 'react';
import useBankAccount from '../hooks/useBankAccount';

interface IViewBankAccountContextData {
    viewCard: string;
    transferType:string;
    changeView:any;
    setViewCard: (e:string) => void;
    setTransferType: (e:string) => void;
    translateTypeKey: (e:string) => void;
}

const ViewBankAccountContext = createContext<IViewBankAccountContextData>({} as IViewBankAccountContextData);

const CreateBankAccountProvider: React.FC = ({children})=>
{
    const { viewCard, setViewCard,transferType,setTransferType,changeView,translateTypeKey} = useBankAccount();
    
    return (
        <ViewBankAccountContext.Provider 
        value={{
                viewCard,
                transferType,
                changeView,
                setViewCard,
                setTransferType,
                translateTypeKey                
            }}>
            {children}
        </ViewBankAccountContext.Provider>
    );
};

export {ViewBankAccountContext, CreateBankAccountProvider}