import React from "react";
import PhoneInput from "react-phone-input-2";
import { ConfirmButton, Container, TextLabel } from "./styles";
import ErrorMessage from "../../../components/ErrorMessage";
import * as color from "../../../styles/colors";

interface InputProps {
  error?: string;
  value?: string;
  onChange?: (arg0: any) => void;
  label?: string;
  className?: string;
  confirmButton?: boolean;
  onConfirm?: (arg0: any) => void;
  onBlur?: (arg0: any) => void;
}

const PhoneInputComponent: React.FC<InputProps> = ({
  error,
  value,
  onChange,
  className,
  label,
  confirmButton,
  onConfirm,
  onBlur,
}) => {
  const inputStyles = {
    width: "100%",
    height: "49px",
    border: `1px solid ${error ? color.red : "#C9C9C9"}`,
    borderRadius: "4px",
    outline: "0",
    fontSize: "13px",
    transition: "border-color 0.2s",
    color: "#2e2e2e",
  };

  const buttonStyles = {
    backgroundColor: "transparent",
    border: "0",
    top: "2px",
    left: "2px",
    height: "90%",
  };

  return (
    <Container className={className}>
      <PhoneInput
        country="br"
        masks={{ br: "(..) .....-...." }}
        inputClass="phone-input"
        inputStyle={inputStyles}
        buttonStyle={buttonStyles}
        placeholder={label || "Celular"}
        value={value}
        onChange={onChange}
        inputProps={{ name: "phone" }}
        enableSearch={true}
        onBlur={onBlur}
      />

      {confirmButton && (
        <ConfirmButton onClick={onConfirm}>Confirmar</ConfirmButton>
      )}

      <TextLabel className="form__label" error={error}>
        {label || "Celular"}
      </TextLabel>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default PhoneInputComponent;
