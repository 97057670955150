import styled from "styled-components";
import Card from "../../../../components/Card";
import * as color from "../../../../styles/colors";

export const Container = styled(Card)`
    margin-top: 30px;
`;

interface TextProps {
    primary?: boolean;
    secondary?: boolean;
}

interface ValidatePixContainerProps {
    has_pix_key?: boolean;
}

export const Title = styled.p<TextProps>`
    font-size: "30px !important";
    margin-left: 20px !important;
    margin-right: 20px !important;
    padding-top: 20px;
    margin-top: 20px !important;
    border-top: 1px solid #ccc;
    width: 100%;
    color: ${({ primary, secondary }) => (primary ? color.orange : secondary ? color.darkgrey : "#2E2E2E")};
    margin: 0;
`;

export const Subtile = styled.p`
    font-size: 16px !important;

    margin-bottom: 20px;
    strong {
        font-weight: 700;
    }
`;

export const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;

    .switch-input {
        margin-right: 16px;
    }

    .switch-label {
        margin-top: 10px;
        margin-right: 10px;
    }
`;

export const PixInfoBanner = styled.div`
    width: 100%;

    .orange {
        color: ${color.orange} !important;
        background: #f0fffb;
        border: 1px solid #0fbf7f;
    }

    .green {
        color: ${color.brightgreen} !important;
        background: #f2fff7;
        border: 1px solid #24ee75;
    }

    .red {
        color: ${color.red} !important;
        color: #fff0f0;
        background: #fff0f0;
        border: 1px solid #ff0000;
    }

    div {
        margin: 0 20px 10px 20px;
        padding: 15px;
        box-sizing: border-box;
        border-radius: 12px;
    }

    p {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */
    }
`;

export const LastVerification = styled.div`
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    /* identical to box height, or 16px */
    color: #8e8e8e;
    margin-left: 20px;
`;

export const ValidatePixContainer = styled.div<ValidatePixContainerProps>`
    display: flex;
    flex-direction: "row";
    margin-top: 24px;
    flex-wrap: wrap;
    justify-content: space-between;
    p {
        margin: 2px 0 6px 0px;
        align-items: center;
        display: flex;
        gap: 5px;
    }

    .label {
        color: ${({ has_pix_key }) => (has_pix_key ? color.brightgreen : color.darkgrey)};
    }

    @media (max-width: 576px) {
        flex-direction: column;

        button {
            width: 100%;
            padding: 15px 0px;
        }

        .status-pix {
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 25px;
        }
    }
`;

export const LinkButton = styled.div`
    display: flex;
    flex-direction: "row";
    text-align: "center";
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    span {
        color: #0fbf7f;
        margin-left: 12px;
    }
    cursor: pointer;
`;
