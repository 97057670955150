import styled from "styled-components";
import * as color from "../../../styles/colors";

interface InputProps {
    error?: string;
}

export const Container = styled.div`
    position: relative;
`;

export const TextLabel = styled.label<InputProps>`
    top: -10px;
    left: 10px;
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    background: #fff;
    padding: 0 2px;
    color: ${({ error }) => (error ? color.red : "#999999")};
    font-family: "Inter", sans-serif;
    position: absolute;
    opacity: 1;
`;

export const ConfirmButton = styled.button`
    display: flex;
    align-items: center;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 115%;
    color: #0fbf7f;

    position: absolute;
    right: 10px;
    top: 18px;

    border: 0;
    outline: 0;

    @media all and (max-width: 400px) {
        font-size: 12px;
        top: 0;
        right: 5px;
        padding: 18px 0;
    }
`;
