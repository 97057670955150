import { useCallback, useEffect, useState } from 'react';

export default function useRegister() {
  const [step, setStep] = useState<number>(1);
  const [fullName, setFullName] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [inviteCode, setInviteCode] = useState<string | null>('');
  const [useTerms, setUseTerms] = useState<boolean>(false);
  const [registerFinished, setRegisterFinished] = useState<boolean>(false);
  const [emailValidated, setEmailValidated] = useState<boolean>(false);
  const [errorFinish, setErrorFinish] = useState<string | null>('');

  // Verification Codes
  const [emailCode, setEmailCode] = useState<string | null>('');
  const [phoneCode, setPhoneCode] = useState<string | null>('');

  // UTMS
  const [src, setSrc] = useState<string | null>('');
  const [utmSource, setUtmSource] = useState<string | null>('');
  const [utmMedium, setUtmMedium] = useState<string | null>('');
  const [utmCampaign, setUtmCampaign] = useState<string | null>('');
  const [utmTerm, setUtmTerm] = useState<string | null>('');
  const [utmContent, setUtmContent] = useState<string | null>('');
  const [utmId, setUtmId] = useState<string | null>('');
  const [Fbclid, setFbclid] = useState<string | null>('');
  const [Gclid, setGclid] = useState<string | null>('');
  const [urlData, setUrlData] = useState<string | null>('');

  const [hasPromotionalTax, setHasPromotionalTax] = useState<string | null>('');

  const [redirectLink, setRedirectLink] = useState<string>(
    'https://azcend.com/'
  );

  const nextStep = useCallback(
    param => {
      let nextStepVal = 0;

      if (typeof param === 'number') {
        nextStepVal = param;
      } else {
        nextStepVal = step !== null ? step + 1 : 1;
      }
      localStorage.setItem('step', nextStepVal.toString());
      setStep(nextStepVal);
    },
    [step]
  );

  const previousStep = useCallback(
    (param: any) => {
      let previousStepVal = 0;
      if (typeof param === 'number') {
        previousStepVal = param;
      } else {
        previousStepVal = step > 1 ? step - 1 : 1;
      }

      localStorage.setItem('step', previousStepVal.toString());
      setStep(previousStepVal);
    },
    [step]
  );

  useEffect(() => {
    const stepString = localStorage.getItem('step') as string;
    const fullNameString = localStorage.getItem('fullName') as string;
    const emailString = localStorage.getItem('email') as string;
    const phoneString = localStorage.getItem('phone') as string;
    const passwordString = localStorage.getItem('password') as string;
    const confirmPasswordString = localStorage.getItem(
      'confirmPassword'
    ) as string;
    const cpfString = localStorage.getItem('cpf') as string;
    const inviteCodeString = localStorage.getItem('inviteCode') as string;
    const errorFinishString = localStorage.getItem('errorFinish') as string;

    const emailCodeString = localStorage.getItem('emailCode') as string;
    const phoneCodeString = localStorage.getItem('phoneCode') as string;

    const srcString = localStorage.getItem('src') as string;
    const utmSourcehString = localStorage.getItem('utmSourceh') as string;
    const utmMediumString = localStorage.getItem('utmMedium') as string;
    const utmCampaignString = localStorage.getItem('utmCampaign') as string;
    const utmTermString = localStorage.getItem('utmTerm') as string;
    const utmContentString = localStorage.getItem('utmContent') as string;
    const utmIdString = localStorage.getItem('utmId') as string;
    const FbclidString = localStorage.getItem('Fbclid') as string;
    const GclidString = localStorage.getItem('Gclid') as string;
    const urlDataString = localStorage.getItem('urlData') as string;

    if (stepString) setStep(parseInt(stepString));
    if (fullNameString) setFullName(fullNameString);
    if (emailString) setEmail(emailString);
    if (phoneString) setPhone(phoneString);
    if (passwordString) setPassword(passwordString);
    if (confirmPasswordString) setConfirmPassword(confirmPasswordString);
    if (cpfString) setCpf(cpfString);
    if (inviteCodeString) setInviteCode(inviteCodeString);
    if (errorFinishString) setInviteCode(errorFinishString);

    if (emailCodeString) setInviteCode(emailCodeString);
    if (phoneCodeString) setInviteCode(phoneCodeString);

    if (srcString) setInviteCode(srcString);
    if (utmSourcehString) setInviteCode(utmSourcehString);
    if (utmMediumString) setInviteCode(utmMediumString);
    if (utmCampaignString) setInviteCode(utmCampaignString);
    if (utmTermString) setInviteCode(utmTermString);
    if (utmContentString) setInviteCode(utmContentString);
    if (utmIdString) setInviteCode(utmIdString);
    if (FbclidString) setInviteCode(FbclidString);
    if (GclidString) setInviteCode(GclidString);
    if (urlDataString) setInviteCode(urlDataString);

    const useTermsString = localStorage.getItem('useTerms') as string;
    useTermsString === 'true' ? setUseTerms(true) : setUseTerms(false);

    const emailValidatedString = localStorage.getItem(
      'emailValidated'
    ) as string;
    emailValidatedString === 'true'
      ? setEmailValidated(true)
      : setEmailValidated(false);
  }, []);

  useEffect(() => {
    if (fullName) localStorage.setItem('fullName', fullName.toString());
    if (cpf) localStorage.setItem('cpf', cpf.toString());
    if (email) localStorage.setItem('email', email.toString());
    if (phone) localStorage.setItem('phone', phone.toString());
    if (password) localStorage.setItem('password', password.toString());
    if (confirmPassword)
      localStorage.setItem('confirmPassword', confirmPassword.toString());
    if (step) localStorage.setItem('step', step.toString());
    if (inviteCode) localStorage.setItem('inviteCode', inviteCode.toString());
    if (errorFinish)
      localStorage.setItem('errorFinish', errorFinish.toString());
    localStorage.setItem('useTerms', useTerms.toString());
    localStorage.setItem('emailValidated', emailValidated.toString());

    if (emailCode) localStorage.setItem('emailCode', emailCode.toString());
    if (phoneCode) localStorage.setItem('phoneCode', phoneCode.toString());

    if (src) localStorage.setItem('src', src.toString());
    if (utmSource) localStorage.setItem('utmSource', utmSource.toString());
    if (utmMedium) localStorage.setItem('utmMedium', utmMedium.toString());
    if (utmCampaign)
      localStorage.setItem('utmCampaign', utmCampaign.toString());
    if (utmTerm) localStorage.setItem('utmTerm', utmTerm.toString());
    if (utmContent) localStorage.setItem('utmContent', utmContent.toString());
    if (utmId) localStorage.setItem('utmId', utmId.toString());
    if (Fbclid) localStorage.setItem('Fbclid', Fbclid.toString());
    if (Gclid) localStorage.setItem('Gclid', Gclid.toString());
    if (urlData) localStorage.setItem('urlData', urlData.toString());
  }, [
    fullName,
    cpf,
    email,
    phone,
    password,
    confirmPassword,
    step,
    inviteCode,
    useTerms,
    emailValidated,
    errorFinish,
    src,
    setSrc,
    utmSource,
    setUtmSource,
    utmMedium,
    setUtmMedium,
    utmCampaign,
    setUtmCampaign,
    utmTerm,
    setUtmTerm,
    utmContent,
    setUtmContent,
    utmId,
    setUtmId,
    Fbclid,
    setFbclid,
    Gclid,
    setGclid,
    urlData,
    setUrlData,
    emailCode,
    setEmailCode,
    phoneCode,
    setPhoneCode,
  ]);

  return {
    fullName,
    setFullName,
    cpf,
    setCpf,
    email,
    setEmail,
    phone,
    setPhone,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    step,
    setStep,
    nextStep,
    previousStep,
    hasPromotionalTax,
    setHasPromotionalTax,
    inviteCode,
    setInviteCode,
    useTerms,
    setUseTerms,
    registerFinished,
    setRegisterFinished,
    redirectLink,
    setRedirectLink,
    emailValidated,
    setEmailValidated,
    errorFinish,
    setErrorFinish,
    src,
    setSrc,
    utmSource,
    setUtmSource,
    utmMedium,
    setUtmMedium,
    utmCampaign,
    setUtmCampaign,
    utmTerm,
    setUtmTerm,
    utmContent,
    setUtmContent,
    utmId,
    setUtmId,
    Fbclid,
    setFbclid,
    Gclid,
    setGclid,
    urlData,
    setUrlData,
    emailCode,
    setEmailCode,
    phoneCode,
    setPhoneCode,
  };
}
