import React, { useCallback } from "react";
import useToggle from "../../../../hooks/useToggle";
import { UserBox, Content, Row, Status } from "./styles";
import { Link } from "react-router-dom";
import Badge from "../../../../components/Badge";
import { IUser } from "../../../../entities/User";

interface UserProps {
    id: string;
    user?: IUser;
}
const CardViewUser: React.FC<UserProps> = ({ id, user }) => {
    const [isModalOpemOptions, setIsModalOpemOptions] = useToggle(false);

    const EditUser = useCallback(
        (id) => {
            return [
                {
                    id: 1,
                    name: (
                        // <p onClick={handleEdit}>Editar</p>
                        <Link to={`/people-and-access/edit/${id}`} style={{ textDecoration: "none", color: "inherit" }}>
                            Editar
                        </Link>
                    ),
                    method: () => {},
                },
            ];
        },
        [setIsModalOpemOptions]
    );

    return (
        <UserBox menuList={EditUser(id)} key={1}>
            <Content>
                <Row>
                    <h2 className="header col-sm-4 col-xs-12">Dados do Colaborador</h2>
                </Row>
                <div className="company-status row">
                    <div className="col-sm-4 col-xs-12">
                        <p className="register-date">
                            Nome
                            <br />
                            {user?.name}
                        </p>
                    </div>
                    <div className="col-sm-5 col-xs-12">
                        <p className="register-date">
                            E-mail
                            <br />
                            {user?.email}
                        </p>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                        <p className="register-date">
                            Serviço
                            <br />
                            Admin
                        </p>
                    </div>
                </div>
                <div className="company-status row">
                    <div className="col-sm-4 col-xs-12">
                        <p className="register-date">
                            Telefone
                            <br />
                            {user?.cellphone}
                        </p>
                    </div>
                    <div className="col-sm-5 col-xs-12">
                        <p className="register-date">
                            Documento
                            <br />
                            {user?.document}
                        </p>
                    </div>

                    <Status className="col-sm-3 col-xs-12">
                        {user?.roles == "admin" && <Badge status="Administrativo" />}
                        {user?.roles == "attendance" && <Badge status="Atendimento" />}
                        {user?.roles == "finantial" && <Badge status="Financeiro" />}
                        {user?.roles == "custom" && <Badge status="Personalizado" />}
                    </Status>
                </div>
            </Content>
        </UserBox>
    );
};
export default CardViewUser;
