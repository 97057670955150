import React, { useRef } from 'react';
import CodeInput from 'react-verification-code-input';
import { Container, Text, Title } from './styles';
import ErrorMessage from '../ErrorMessage';
import Loader from '../Loader';

interface VerificationCodeInputProps {
  onChange?: () => void;
  onComplete?: any;
  className?: string;
  errorMsg?: string;
  text?: string;
  loading?: number;
  icon?: string;
  title?: string;
}

const VerificationCodeInput: React.FC<VerificationCodeInputProps> = ({
  onChange,
  onComplete,
  className,
  errorMsg,
  text,
  loading,
  icon,
  title
}) => {
  const inputRef = useRef<any>(null);

  return (
    <Container className={className}>
      <Loader loading={loading} />
      {icon && <img src={icon} className="icon" alt="" />}
      {title && <Title>{title}</Title>}
      {text && <Text>{text}</Text>}
      <CodeInput
        fields={4}
        className="verification-code-input"
        fieldWidth={50}
        fieldHeight={60}
        onChange={onChange}
        onComplete={onComplete}
        ref={inputRef}
      />
      {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
    </Container>
  );
};

export default VerificationCodeInput;
