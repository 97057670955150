/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  UserProfile,
  Avatar,
  UserNameEmail,
  Users,
  Row,
  RoundButton,
  AddButton,
} from './styles';
import api from '../../../services/api';
import { AccountsContext as Context } from '../../../context/AccountsContext';
import { IUser } from '../../../entities/User';
import Card from '../../../components/Card';
import Modal from '../../../components/ModalAdmin';
import PageTopbar from '../../../components/PageTopbar';
import Badge from '../../../components/Badge';
import useToggle from '../../../hooks/useToggle';
import CollaboratorDeleteContent from './CollaboratorDeleteModalContent';

const PeopleAndAccess: React.FC = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useToggle(false);
  const [loading, setLoading] = useState<number>(0);
  const [collaborators, setCollaborators] = useState<[]>();
  const [isDeleteCollaboratorModalOpen, setIsDeleteCollaboratorModalOpen] =
    useToggle(false);
  const [forceToReload, setForceToReload] = useState<number>(0);
  const [selectedCollaboratorId, setSelectedCollaboratorId] = useState<any>();
  const [userEditSelect, setUserEditSelect] = useState<IUser | undefined>();
  const [noCompaniesMessage, setNoCompaniesMessage] = useState<string>('');
  const { user } = useContext(Context);

  const getColaboratorsApi = async () => {
    setLoading(1);
    try {
      const response = await api.get('/collaborators');
      const collaboratorsList = response.data.data;
      setCollaborators(collaboratorsList);

      setLoading(0);
      if (collaboratorsList.length === 0) {
        setNoCompaniesMessage('Nenhum colaborador adicionado');
      } else {
        setNoCompaniesMessage('');
      }
    } catch (error) {
      setLoading(0);
    }
  };

  useEffect(() => {
    getColaboratorsApi();
  }, [isModalOpen, isDeleteCollaboratorModalOpen]);

  const handleModal = (type: string, collaborator?: IUser) => {
    if (type == 'create') {
      setUserEditSelect(undefined);
      history.push('/people-and-access/create');
    }

    if (type == 'edit') {
      setUserEditSelect(collaborator);
      history.push(`/people-and-access/view/${collaborator?.id_code}`);
    }

    if (type == 'delete') {
      setIsDeleteCollaboratorModalOpen();
      setSelectedCollaboratorId(collaborator?.id_code);
    }
  };

  const closeModalFromDeleteCollaborator = useCallback(
    e => {
      if (e === 'true') {
        setIsDeleteCollaboratorModalOpen();
        setForceToReload(forceToReload + 1);
      }
    },
    [forceToReload, setIsDeleteCollaboratorModalOpen]
  );

  const collaboratorDelModal = (
    <Modal
      isOpen={isDeleteCollaboratorModalOpen}
      onRequestClose={setIsDeleteCollaboratorModalOpen}
      closeModal={setIsDeleteCollaboratorModalOpen}
      smallWidth>
      <CollaboratorDeleteContent
        id={selectedCollaboratorId}
        closeModal={closeModalFromDeleteCollaborator}
      />
    </Modal>
  );

  return (
    <Container>
      {collaboratorDelModal}
      <PageTopbar
        title="Pessoas e acessos"
        subTitle="Gerencie os usuários que possuem acesso aos produtos Azcend"
      />
      <Card cardTitle="Usuários" loading={loading}>
        {collaborators &&
          collaborators?.map((collaborator: IUser) => (
            <Users key={collaborator.id_code}>
              <Row>
                <UserProfile className="col-sm-7 col-xs-12">
                  <Avatar avatarImg={collaborator.photo} />
                  <UserNameEmail>
                    <p className="user-name">{collaborator.name} </p>
                    <p>{collaborator.email}</p>
                    {/* <p>{collaborator.created_at}</p> */}
                  </UserNameEmail>
                </UserProfile>
                <div
                  className="col-sm-3 col-xs-12"
                  style={{ alignSelf: 'center' }}>
                  {collaborator.roles == 'admin' && (
                    <Badge status="Administrativo" />
                  )}
                  {collaborator.roles == 'attendance' && (
                    <Badge status="Atendimento" />
                  )}
                  {collaborator.roles == 'finantial' && (
                    <Badge status="Financeiro" />
                  )}
                  {collaborator.roles == 'custom' && (
                    <Badge status="Personalizado" />
                  )}
                </div>
                <div className="buttons-container col-sm-2 col-xs-12">
                  <RoundButton
                    edit
                    onClick={() => handleModal('edit', collaborator)}
                    className="edit-button"
                  />
                  <RoundButton
                    onClick={() => handleModal('delete', collaborator)}
                  />
                </div>
              </Row>
            </Users>
          ))}
        <p className="no-collaborators-message">{noCompaniesMessage}</p>
      </Card>

      {user?.biometry_status == '3' && (
        <AddButton onClick={() => handleModal('create')} />
      )}
    </Container>
  );
};

export default PeopleAndAccess;
