import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";
import CodeInput from "react-verification-code-input";
import {
  Container,
  ErrorMessageContainer,
  GobackButton,
  PhoneInputContainer,
  SaveWithoutPhone,
  ValidateInputContainer,
} from "./styles";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { RegisterContext as Context } from "../../../context/RegisterContext";
import api from "../../../services/api";
import { Button } from "../../../components/Button/styles";
import Loader from "../../../components/Loader";
import PhoneInputComponent from "../../../components/FormInputs/PhoneInput";

import ReactInputMask from "react-input-mask";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const PhoneTokenView: React.FC = () => {
  const {
    previousStep,
    email,
    fullName,
    phone,
    cpf,
    password,
    useTerms,
    setRegisterFinished,
    setRedirectLink,
    setPhone,
    setErrorFinish,
    nextStep,
    src,
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    utmId,
    Fbclid,
    Gclid,
    urlData,
    phoneCode,
    setPhoneCode,
    emailCode
  } = useContext(Context) || {};

  const [phoneErrorMsg, setPhoneErrorMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string | null>("");
  const [loading, setLoading] = useState<number | undefined>(0);
  const inputRef = useRef<any>(null);
  const [editPhone, setEditPhone] = useState<boolean>(false);

  const invalidPhoneNumbers = [
    "5511111111111",
    "5522222222222",
    "5533333333333",
    "5544444444444",
    "5555555555555",
    "5566666666666",
    "5577777777777",
    "5588888888888",
    "5599999999999",
    "5500000000000",
  ];

  useEffect(() => {
    sendPhoneCode();
  }, []);

  const sendPhoneCode = () => {
    setErrorMsg("");

    if (!phone) {
      setErrorMsg("Por favor, preencha o campo para o número de celular.");
      return;
    }

    if (invalidPhoneNumbers.includes(phone)) {
      setErrorMsg("Por favor, digite um número de celular válido.");
      return;
    }
    setLoading(1);

    api
      .get("/register/send-cellphone-code", {
        params: {
          cellphone: phone,
        },
      })
      .then((res) => {
        setLoading(0);
        toast.success("Seu código foi enviado com sucesso!");
      })
      .catch((error) => {
        setLoading(0);
        setErrorMsg(error.response.data.message);
      });
  };

  const tokenMatch = useCallback(
    (e) => {
      if (!phoneCode) {
        setErrorMsg(
          "Por favor, preencha com o código envia por SMS no celular informado."
        );
        return;
      }
      setLoading(1);
      api
        .post("/register/match-cellphone-verify-code", {
          cellphone: phone,
          token: phoneCode,
        })
        .then(() => {
          handleRegisterData(true);
        })
        .catch((error) => {
          const clearValues =
            inputRef?.current?.__clearvalues__ || (() => null);
          clearValues();
          setLoading(0);
          if (error.response) {
            setErrorMsg(error.response.data.message);
          }
        });
    },
    [phone, phoneCode]
  );

  const handleConfirmPhone = () => {
    if (phone.substring(0, 2) == "55") {
      if (phone.length < 13) {
        setPhoneErrorMsg("Insira um celular válido.1");
      } else {
        sendPhoneCode();
        setEditPhone(!editPhone);
      }
    } else if (phone.length < 10) {
      setPhoneErrorMsg("Insira um celular válido.1");
    } else {
      sendPhoneCode();
      setEditPhone(!editPhone);
    }
  };

  const renderEditPhone = () => {
    if (editPhone === true) {
      return (
        <PhoneInputContainer>
          <PhoneInputComponent
            value={phone}
            label="Seu celular"
            className="phone-input"
            onChange={(e) => {
              setPhone(e);
              setPhoneErrorMsg("");
            }}
            error={phoneErrorMsg}
            confirmButton
            onConfirm={handleConfirmPhone}
          />
        </PhoneInputContainer>
      );
    } else {
      return (
        <ValidateInputContainer errorMsg={errorMsg}>
          <CodeInput
            fields={4}
            className="verification-code-input"
            fieldWidth={68}
            fieldHeight={91}
            onChange={(e) => {
              setPhoneCode(e);
              setErrorMsg("");
            }}
            ref={inputRef}
          />

          {errorMsg && (
            <ErrorMessageContainer>
              <h3 className="title">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM5.80943 5.11372L5.87868 5.17157L8 7.29289L10.1213 5.17157C10.2949 4.99801 10.5643 4.97872 10.7592 5.11372L10.8284 5.17157C11.002 5.34514 11.0213 5.61456 10.8863 5.80943L10.8284 5.87868L8.70711 8L10.8284 10.1213C11.002 10.2949 11.0213 10.5643 10.8863 10.7592L10.8284 10.8284C10.6549 11.002 10.3854 11.0213 10.1906 10.8863L10.1213 10.8284L8 8.70711L5.87868 10.8284C5.70511 11.002 5.43569 11.0213 5.24082 10.8863L5.17157 10.8284C4.99801 10.6549 4.97872 10.3854 5.11372 10.1906L5.17157 10.1213L7.29289 8L5.17157 5.87868C4.99801 5.70511 4.97872 5.43569 5.11372 5.24082L5.17157 5.17157C5.34514 4.99801 5.61456 4.97872 5.80943 5.11372Z"
                    fill="#D10000"
                  />
                </svg>
                Código inválido
              </h3>
              <p className="description">{errorMsg}</p>
            </ErrorMessageContainer>
          )}

          <Button primary onClick={tokenMatch}>
            VALIDAR CÓDIGO
          </Button>
        </ValidateInputContainer>
      );
    }
  };

  const history = useHistory();

  // CADASTRO --------------------------------------------------------------
  const handleRegisterData = useCallback(
    async (hasPhone?: boolean) => {
      const formData = new FormData();
      formData.append("document", cpf);
      formData.append("name", fullName);
      formData.append("email", email);

      phoneCode && formData.append("codeCellphone", phoneCode);
      emailCode && formData.append("codeEmail", emailCode);

      src && formData.append("src", src);
      utmSource && formData.append("utm_source", utmSource);
      utmMedium && formData.append("utm_medium", utmMedium);
      utmCampaign && formData.append("utm_campaign", utmCampaign);
      utmTerm && formData.append("utm_term", utmTerm);
      utmContent && formData.append("utm_content", utmContent);
      utmId && formData.append("utm_id", utmId);
      Fbclid && formData.append("fbclid", Fbclid);
      Gclid && formData.append("gclid", Gclid);
      urlData && formData.append("urldata", urlData);

      if (hasPhone && phone) {
        formData.append("cellphone", phone);
      }
      formData.append("password", password);
      formData.append(
        "use_terms",
        useTerms.toString() === "true" ? "true" : "false"
      );

      try {
        setLoading(1);

        const response = await api.post("/register", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setLoading(0);
        //set
        setRegisterFinished(true);
        setRedirectLink(response.data.redirect);
        history.push("/finished");
      } catch (error) {
        setLoading(0);

        //@ts-ignore
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
        setErrorFinish(errorMessage);
        nextStep();
      }
    },
    [cpf, fullName, email, phone, password, phoneCode, emailCode]
  );

  return (
    <Container>
      <Loader loading={loading} />
      <GobackButton onClick={() => previousStep(1)}>
        <AiOutlineArrowLeft className="goback-icon" /> Voltar
      </GobackButton>
      <h1>Agora, vamos confirmar seu celular</h1>
      <p>
        <b>Enviamos um código para:</b>
      </p>
      <div className="edit-phone">
        {editPhone === false && (
          <div>
            <ReactInputMask
              mask="+55 (99) 99999-9999"
              value={phone}
              disabled={true}
              className="phone-edit"
            />
            <a
              onClick={() => {
                setEditPhone(!editPhone);
              }}
              className="link"
            >
              Editar
            </a>
          </div>
        )}
      </div>

      {renderEditPhone()}

      {editPhone === false && (
        <span className="has-account">
          Não recebeu o código?
          <a onClick={() => sendPhoneCode()} className="link">
            Reenviar
          </a>
        </span>
      )}

      <SaveWithoutPhone
        onClick={() => {
          handleRegisterData(true);
        }}
      >
        Deixar para depois
      </SaveWithoutPhone>

      <p className="obs-register">
        Utilizaremos os dados para realizar seu cadastro e te auxiliaremos a
        inserir informações para ativar a sua loja.
      </p>
    </Container>
  );
};

export default PhoneTokenView;
