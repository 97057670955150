import styled from "styled-components";
import avatar from "../../assets/img/avatar.jpg";

export const Container = styled.div`
    background: #fff;
    height: 55px;
    box-shadow: 0 10px 20px rgba(14, 40, 93, 0.09);
    z-index: 102;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    .navbar-brand {
        padding-top: 0;
        padding-bottom: 0;
        width: 80px;
        margin-right: auto;

        img {
            width: 40px;
        }
    }

    .dropdown {
        .dropdown-toggle {
            /* background-color: #0FBF7F;
      border-color: #0FBF7F; */
            ::after {
                display: none;
            }

            :focus {
                box-shadow: none;
            }
        }
        .dropdown-menu {
            border-radius: 20px;
            padding: 0;
            border: 0;
        }
    }

    .dropdown-trigger {
        position: relative;
        width: 6px;
        height: 6px;
        background-color: #0fbf7f;
        border-radius: 10px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            right: 0;
            background-color: inherit;
            border-radius: inherit;
            border-radius: 10px;
        }

        &:before {
            right: 10px;
        }

        &:after {
            right: 20px;
        }
    }

    @media (min-width: 992px) {
        background: #0fbf7f;
        display: none;
        /* height: 164px; */
        box-shadow: none;

        .dropdown-trigger {
            background-color: #fff;
        }

        .config {
            .dropdown-menu {
                top: 12px !important;
                left: -1px !important;
            }
        }

        .dropdown {
            .dropdown-toggle {
                background-color: #0fbf7f;
                border-color: #0fbf7f;
            }
        }
    }
`;

export const TopMenu = styled.div`
    /* position: absolute;

  top: 20px;
  right: 0; */
    margin-right: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0;
    height: inherit;

    .logo-button-group {
        display: none;
    }

    .notifications {
        display: flex;
        margin-right: 16px;
        .dropdown-menu {
            top: 50px !important;
            right: -13px !important;
        }
    }

    .configurations {
        display: flex;
        align-items: center;
        .dropdown-menu {
            top: 50px !important;
            right: -24px !important;
        }

        .dropdown-button {
            width: 40px;
            justify-content: flex-end;
            margin-left: 15px;
        }
    }

    @media (max-width: 992px) {
        display: flex;
        justify-content: space-between;
        padding-top: 8px;
        z-index: 102;
        margin-right: 25px;
        margin-left: 25px;

        .logo-button-group {
            display: flex;
            align-items: center;

            .logo {
                height: 26px;
            }
        }
    }
`;

export const User = styled.div`
    display: flex;
    align-items: center;

    p {
        color: #fff;
        font-weight: 600;
        font-size: 16px;
    }

    @media (max-width: 992px) {
        p {
            color: #56607e;
        }
    }

    @media (max-width: 576px) {
        p {
            display: none;
        }
    }
`;

export const Avatar = styled.div`
    margin-right: 0.5rem !important;
    background-image: url(${avatar});
    width: 40px;
    height: 40px;
    background-size: cover;
    border-radius: 20px;
`;

export const Trigger = styled.div`
    position: relative;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 10px;

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        right: 0;
        background-color: inherit;
        border-radius: inherit;
        border-radius: 10px;
    }

    &:before {
        right: 10px;
    }

    &:after {
        right: 20px;
    }

    @media (max-width: 992px) {
        background-color: #0fbf7f;
    }
`;

export const ContentText = styled.div`
    /* display: flex;
  justify-content: center; */
    text-align: center;
    padding: 25px 20px 20px;
    margin-left: 270px;
    display: block;

    @media (max-width: 992px) {
        margin-left: 0;
        display: none;
    }
`;

export const Title = styled.h3`
    font-size: 24px;
    color: #fff !important;
    line-height: 30px;
    margin-bottom: 12px;
`;

export const SubTitle = styled.p`
    font-size: 14px;
    color: #fff !important;
    line-height: 17px;
`;
