import styled from "styled-components";

interface SidebarProps {
    open?: boolean;
}

// SidebarContainer scope all the sidebar
export const Container = styled.div<SidebarProps>`
    position: relative;
    .sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 131; /* Behind the navbar */
        width: 100%;

        /* Glass Effect */
        background: #191919;
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10.7px);
        -webkit-backdrop-filter: blur(10.7px);
        border: 1px solid rgba(255, 255, 255, 0.3);

        overflow: hidden;

        /* display: none; */
        transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
        transition: transform 0.3s ease-in-out;

        .mobile-sidebar-head {
            display: none;

            padding: 12px 16px 40px 16px;
        }

        .navbar-brand {
            position: fixed;
            display: none;
            justify-content: center;
            top: 0;
            padding-top: 0;
            padding-bottom: 0;
            background-color: #191919;
            width: 80px;
            margin-right: auto;
        }

        .navbar-brand img {
            width: 40px;
        }

        .close-menu {
            display: block;
        }

        .mobile-sidebar-head {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 100%;

            .logo-button-group {
                display: flex;
                align-items: center;

                .logo {
                    height: 26px;
                }
            }
        }

        .nav-logout {
            position: absolute;
            bottom: 20px;
            width: 100%;
            border-top: 1px solid #00b7ff;

            .nav-link {
                margin: 20px 0 20px 0;
            }
        }
    }

    @media (min-width: 992px) {
        .sidebar {
            /* display: block; */
            transition: transform 0.3s ease-in-out;
            transform: translateX(0);
            padding: 110px 0 0; /* Height of navbar */
            width: 270px;
            .navbar-brand {
                padding: 26px;
                width: 270px;
                display: flex;
            }

            .navbar-brand.desktop-brand img {
                width: 170px;
            }

            .close-menu {
                display: none;
            }

            .mobile-sidebar-head {
                display: none;
            }

            .nav-logout {
                border-top: none;
                // height: 80px;
            }
        }
    }
`;

export const SideBarSticky = styled.div`
    position: relative;
    top: 0;
    height: calc(100vh - 80px);
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ul {
        .nav-item.active {
            color: #00b7ff;
            position: relative;

            ::before {
                opacity: 1;
            }

            ::before {
                left: 0;
            }

            .nav-link img {
                filter: brightness(0) invert(1);
            }
        }
        .nav-item {
            position: relative;

            :hover .nav-link,
            .nav-link[aria-expanded="true"] {
                color: #00b7ff;
                position: relative;
            }

            ::before {
                content: "";
                width: 60px;
                background-color: #00b7ff;
                opacity: 0.2;
                height: 48px;
                position: absolute;
                border-radius: 0 30px 30px 0;
                left: -60px;
                transition: all 0.2s ease;
            }

            :hover::before,
            [aria-expanded="true"]:hover::before {
                left: 0;
            }

            .nav-link {
                font-weight: 500;
                font-size: 20px;
                display: flex;
                align-items: center;
                padding: 12px 16px;
                text-decoration: none;
                color: #a3afb7e6;
                height: 48px;
            }

            .nav-link img {
                margin-right: 28px;
                width: 24px;
                height: 24px;
            }

            .accordion {
                .submenu li a {
                    color: #56607e;
                    text-decoration: none;

                    :hover {
                        color: #00b7ff;
                    }
                }

                .closeToggle.nav-item::after {
                    content: "";
                    width: 6px;
                    height: 10px;

                    position: absolute;
                    right: 44px;
                    top: 19px;
                    transition: all 0.3s ease;
                    transform: rotate(0deg);
                }
                .openToggle.nav-item::after {
                    content: "";
                    width: 6px;
                    height: 10px;

                    position: absolute;
                    right: 44px;
                    top: 19px;
                    transition: all 0.3s ease;
                    transform: rotate(90deg);
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        height: calc(100vh - 110px);
    }

    @supports ((position: -webkit-sticky) or (position: sticky)) {
        position: -webkit-sticky;
        position: sticky;
    }

    @media (min-width: 992px) {
        ul {
            .nav-item {
                .nav-link {
                    font-size: 14px;
                }
            }
        }
    }
`;

export const NavProjects = styled.div`
    background-color: #f8f9fa;
    padding: 20px 26px;
    border-top: solid 1px #eeeff3;
    position: fixed;
    bottom: 0;
    width: 270px;
    height: 120px;
`;
