import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";



if ((process.env.REACT_APP_ENV === 'production') && (process.env.REACT_APP_SENTRY_IS_ACTIVE === 'true' )) {

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
