import React, { useState } from "react";
import { IconContext } from "react-icons";
import { FiCheck, FiChevronDown } from "react-icons/fi";
import { ListSection, ListContainer, TitleItem, Col10, Col25, Col40 } from "./styles";
import SwitchInput from "../SwitchInput";
import CheckboxInput from "../CheckboxInput";

interface PermissionItem {
    value: string;
    checked: boolean;
    show: boolean;
}
interface Permission {
    toView: PermissionItem;
    manage: PermissionItem;
}
interface AccordionPermissionsProps {
    id?: string;
    label?: string;
    permissions?: Permission;
    onChange?: (e: any) => void;
    role?: string;
    isModeView?: boolean;
}

const AccordionPermissionChild: React.FC<AccordionPermissionsProps> = ({
    id,
    label,
    permissions,
    onChange,
    role,
    isModeView,
}) => {
    const [permissionsTemp, setPermissionsTemp] = useState(permissions);
    return (
        <ListSection key={id}>
            <ListContainer>
                <Col40 isView={isModeView}>
                    <TitleItem>{label}</TitleItem>
                </Col40>
                {isModeView ? (
                    <Col25 style={{ textAlign: "center" }}>
                        {permissionsTemp && permissionsTemp.toView.show ? (
                            permissionsTemp.toView.checked ? (
                                <FiCheck color="#0FBF7F" size="14"></FiCheck>
                            ) : null
                        ) : null}
                    </Col25>
                ) : (
                    <Col25 style={{ textAlign: "center" }}>
                        {permissionsTemp?.toView.show && (
                            <CheckboxInput
                                id={permissionsTemp.toView.value}
                                checked={permissionsTemp.toView.checked}
                                onChange={onChange}
                                disable={role != "custom" || isModeView}
                            ></CheckboxInput>
                        )}
                    </Col25>
                )}
                {isModeView ? (
                    <Col25 style={{ textAlign: "center" }}>
                        {permissionsTemp && permissionsTemp.manage.show ? (
                            permissionsTemp.manage.checked ? (
                                <FiCheck color="#0FBF7F" size="14"></FiCheck>
                            ) : null
                        ) : null}
                    </Col25>
                ) : (
                    <Col25>
                        {permissionsTemp?.manage.show && (
                            <CheckboxInput
                                id={permissionsTemp.manage.value}
                                checked={permissionsTemp.manage.checked}
                                onChange={onChange}
                                disable={
                                    isModeView ||
                                    !permissionsTemp.toView.checked ||
                                    role == "admin" ||
                                    ((role == "attendance" || role == "finantial") &&
                                        permissionsTemp.manage.value != "sales_manage")
                                }
                            ></CheckboxInput>
                        )}
                    </Col25>
                )}
                <Col10 style={{ textAlign: "right" }}>
                    <span>
                        <FiChevronDown style={{ color: "#FFFFFF" }} />
                    </span>
                </Col10>
            </ListContainer>
        </ListSection>
    );
};

export default AccordionPermissionChild;
