import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Container, BlockItem } from './styles';
import FormWrapper from '../../../../../components/Form';
import { ICompany } from '../../../../../entities/Company';
import { padding } from 'polished';

interface CompanyProfileProps {
  company: ICompany | undefined;
}

type FormData = {
  fantasy_name: string;
  document: string;
};

const CompanyProfileform: React.FC<CompanyProfileProps> = ({ company }) => {
  const { reset } = useForm<FormData>({
    defaultValues: {
      ...company,
    },
  });

  useEffect(() => {
    reset({
      ...company,
    });
  }, [company, reset]);

  return (
    <Container cardTitle="Empresa" loading={company ? 0 : 1}>
      {company && (
        <FormWrapper>
          <div className="row" style={{ marginLeft: '0', marginRight: '0' }}>
            <div className="col-xs-12 col-sm-6">
              <p>Razão social</p>
              <p className="company-name">{company?.fantasy_name}</p>
            </div>
            <BlockItem className="col-xs-12 col-sm-6">
              <p>{company?.type === 1 ? 'CPF' : 'CNPJ'}</p>
              <p className="company-document">{company?.document}</p>
            </BlockItem>
          </div>
        </FormWrapper>
      )}
    </Container>
  );
};

export default CompanyProfileform;
