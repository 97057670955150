import React, { useContext, useCallback, useState, useEffect, MutableRefObject, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Container, ErrorContainer } from "./styles";
import { RegisterContext as Context } from "../../../context/RegisterContext";
import api from "../../../services/api";
import InputCheckbox from "../../../components/FormInputs/Checkbox";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { Collapse } from "react-collapse";
import { Button } from "../../../components/Button/styles";
import { hasCapitalLowerLetters, hasEightChar, hasSpecialChar, validateEmail } from "../../../utils/functions";
import TextInput from "../../../components/FormInputs/TextInput";
import PhoneInputComponent from "../../../components/FormInputs/PhoneInput";
import PasswordRequirements from "../../../components/PasswordRequirements";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const RegisterForm: React.FC = () => {
  const {
    fullName,
    setFullName,
    cpf,
    setCpf,
    email,
    setEmail,
    phone,
    setPhone,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    nextStep,
    inviteCode,
    setInviteCode,
    useTerms,
    setUseTerms,
    setHasPromotionalTax,
    emailValidated,
    setEmailValidated,
    setSrc,
    setUtmSource,
    setUtmMedium,
    setUtmCampaign,
    setUtmTerm,
    setUtmContent,
    setUtmId,
    setFbclid,
    setGclid,
    setUrlData,
    previousStep,
    setRegisterFinished,
    setRedirectLink,
    setErrorFinish,
    src,
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    utmId,
    Fbclid,
    Gclid,
    urlData,
    phoneCode,
    setPhoneCode,
    emailCode,
  } = useContext(Context);

    const toScrollRef = useRef() as MutableRefObject<HTMLDivElement>;

    const [fullNameErrorMsg, setFullNameErrorMsg] = useState<string>("");
    const [cpfErrorMsg, setCpfErrorMsg] = useState<string>("");
    const [emailErrorMsg, setEmailErrorMsg] = useState<string>("");
    const [phoneErrorMsg, setPhoneErrorMsg] = useState<string>("");
    const [isRecaptchaApproved, setIsRecaptchaApproved] = useState<boolean>(false);
    const [recaptchaErrorMsg, setRecaptchaErrorMsg] = useState<string>("");
    const [loading, setLoading] = useState<number>(0);
    const [errorUseTermsMsg, setErrorUseTermsMsg] = useState<string>("");
    const [passwordRulesOpened, setPasswordRulesOpened] = useState(false);

    const [statusName, setStatusName] = useState<string>("");
    const [statusCPF, setStatusCPF] = useState<string>("");
    const [statusEmail, setStatusEmail] = useState<string>("");
    const [statusPassword, setStatusPassword] = useState<string>("");
    const [statusConfirmPassword, setStatusConfirmPassword] = useState<string>("");

    useEffect(() => {
        return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        const { search } = window.location;
        const params = new URLSearchParams(search);

    setSrc(params.get('src'));
    setUtmSource(params.get('utm_source'));
    setUtmMedium(params.get('utm_medium'));
    setUtmCampaign(params.get('utm_campaign'));
    setUtmTerm(params.get('utm_term'));
    setUtmContent(params.get('utm_content'));
    setUtmId(params.get('utm_id'));
    setFbclid(params.get('fbclid'));
    setGclid(params.get('gclid')); 
    setUrlData(params.get('urldata'));
  }, []);

    // useEffect(() => {
    //     const { search } = window.location;
    //     const params = new URLSearchParams(search);
    //     const codeFromURL = params.get("i");
    //     codeFromURL ? setInviteCode(codeFromURL) : setInviteCode("");
    // }, [inviteCode, setInviteCode]);

    const handleValidateEmail = useCallback(() => {
        if (email && !validateEmail(email)) {
            setStatusEmail("");
            setEmailErrorMsg("Por favor, preencha com um e-mail válido.");
        } else {
            api.get("/register/verify-email", {
                params: {
                    email,
                },
            })
                .then(() => {
                    setEmailErrorMsg("");
                    setEmailValidated(false);
                    setStatusEmail("success");
                })
                .catch((error) => {
                    setLoading(0);
                    setEmailErrorMsg("");
                    setStatusEmail("");
                    if (error.response.data.errors) {
                        setEmailErrorMsg(
                            error.response.data.errors.email ||
                                "Ops! Não conseguimos verificar seu email. Tente novamente mais tarde."
                        );
                    } else {
                        setEmailErrorMsg(error.response.data.message);
                    }
                });
        }
    }, [email]);

    const verifyCPF = useCallback(() => {
        if (cpf.length < 11) {
            setStatusCPF("");
            setCpfErrorMsg("Por favor, preencha com um CPF válido.");
            return;
        }

        api.get("/register/verify-cpf", {
            params: {
                document: cpf,
            },
        })
            .then(() => {
                setStatusCPF("success");
            })
            .catch((error) => {
                if (error.response.data.errors.document) {
                    var documentError = error.response.data.errors.document[0];
                    setStatusCPF("");
                    setCpfErrorMsg(documentError);
                } else if (error.response.data.message) {
                    setStatusCPF("");
                    setCpfErrorMsg(error.response.data.message);
                }
            });
    }, [cpf, nextStep]);

    // PASSWORD -----------------------------------------
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");

    const [eightChar, setEightChar] = useState(false);
    const [capitalLowerLetters, setCapitalLowerLetters] = useState(false);
    const [specialChar, setSpecialChar] = useState(false);

    function testPassword(password: string) {
        setEightChar(hasEightChar.test(password));
        setCapitalLowerLetters(hasCapitalLowerLetters.test(password));
        setSpecialChar(hasSpecialChar.test(password));
    }

    useEffect(() => {
        testPassword(password);
    }, []);

    const passwordValidationCheck = useCallback(
        (e) => {
            const value = e.target.value;
            setPassword(value);
            setConfirmPassword("");
            setStatusConfirmPassword("");
            setPasswordError("");
            testPassword(value);
        },
        [hasEightChar, hasCapitalLowerLetters, hasSpecialChar, password, setPassword]
    );

    const passwordValidationError = () => {
        var passwordValidate = true;

        if (!hasEightChar.test(password)) {
            setStatusPassword("");
            setPasswordError("Sua senha deve ter ao menos oito caracteres.");
            setEightChar(false);
            passwordValidate = false;
        } else {
            setEightChar(true);
        }

        if (!hasCapitalLowerLetters.test(password)) {
            setStatusPassword("");
            setPasswordError("Sua senha deve ter letras minusculas e maiusculas.");
            setCapitalLowerLetters(false);
            passwordValidate = false;
        } else {
            setCapitalLowerLetters(true);
        }

        if (!hasSpecialChar.test(password)) {
            setStatusPassword("");
            setPasswordError("Sua senha deve ter números e caracteres especiais.");
            setSpecialChar(false);
            passwordValidate = false;
        } else {
            setSpecialChar(true);
        }

        if (passwordValidate) {
            setStatusPassword("success");
        } else {
            setPasswordRulesOpened(true);
        }

        return passwordValidate;
    };

    const confirmPasswordCheck = () => {
        if (confirmPassword === password && password != "" && confirmPassword != "") {
            setStatusConfirmPassword("success");
            setConfirmPasswordError("");
            return true;
        } else {
            setStatusConfirmPassword("");
            setConfirmPasswordError("A confirmação de senha não confere.");
            return false;
        }
    };

    const validateInputs = () => {
        var validate = true;

        if (!fullName) {
            setFullNameErrorMsg("Por favor, preencha o campo de nome.");
            validate = false;
        }

        if (!email) {
            setEmailErrorMsg("Por favor, preencha o campo de e-mail.");
            validate = false;
        }

        if (!cpf) {
            setCpfErrorMsg("Por favor, preencha o campo de CPF.");
            validate = false;
        }

        if (!phone) {
            setPhoneErrorMsg("Por favor, preencha o campo de celular.");
            validate = false;
        }

        if (!password) {
            setPasswordError("Por favor, preencha o campo de senha.");
            validate = false;
        }

        if (phone.substring(0, 2) == "55") {
            if (phone.length < 13) {
                setPhoneErrorMsg("Por favor, preencha com um celular válido.");
                validate = false;
            }
        }

        if (!confirmPassword) {
            setConfirmPasswordError("Por favor, confirme sua senha.");
            validate = false;
        }

        if (confirmPassword !== password) {
            setConfirmPasswordError("Senha e confirmação não conferem.");
            validate = false;
        }

        if (useTerms == false) {
            setErrorUseTermsMsg("Por favor, aceite os termos para prosseguir.");
            validate = false;
        }

        if (cpf.length <= 10) {
            setCpfErrorMsg("Por favor, preencha o campo com um CPF válido.");
            validate = false;
        }

        if (validate === false) {
            setTimeout(() => {
                scrollToElement();
            }, 500);
        }

        return validate;
    };

    const formatDocument = (value: string) => {
        // removes non-alphanumeric character
        const rawValue = value.replace(/[\W_]/g, "");
        return rawValue;
    };

    const handleCheckFullName = () => {
        setStatusName("");
        if (!fullName) {
            setFullNameErrorMsg("");
            setFullNameErrorMsg("Por favor, preencher com um nome.");
        } else {
            setFullNameErrorMsg("");
            setStatusName("success");
        }
    };

    const handleChangeCPF = useCallback(
        (e) => {
            const { value } = e.currentTarget;
            setCpfErrorMsg("");
            const cpfFormatted = formatDocument(value);
            setCpf(cpfFormatted);
        },
        [setCpf, formatDocument]
    );

    const handleChangeRecaptcha = useCallback((e) => {
        e && setIsRecaptchaApproved(true);
        setRecaptchaErrorMsg("");
    }, []);

    const handleExpiredRecaptcha = () => {
        setIsRecaptchaApproved(false);
    };

    const scrollToElement = useCallback(() => {
        toScrollRef.current && toScrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, [toScrollRef]);

    const handleUsePrivacy = useCallback(() => {
        setUseTerms(!useTerms);
        setErrorUseTermsMsg("");
    }, [useTerms]);

    // Promotional Tax
    useEffect(() => {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const promotionalTax = params.get("promotional-tax");
        if (promotionalTax) {
            setHasPromotionalTax(promotionalTax);
        }
    });

    const handleValidatePhone = () => {
        if (phone.substring(0, 2) == "55") {
            if (phone.length < 13) {
                setPhoneErrorMsg("Por favor, preencha com um celular válido.");
            }
        }

        api.get("/register/verify-cellphone", {
            params: {
                cellphone: phone,
            },
        })
            .then(() => {
                setPhoneErrorMsg("");
            })
            .catch((error) => {
                setPhoneErrorMsg(error.response.data.errors.cellphone || "Celular informado já esta sendo utilizado.");
            });
    };

    const handleSave = (e: any) => {
        e.preventDefault();

        setStatusName("");
        setStatusCPF("");
        setStatusEmail("");
        setStatusPassword("");
        setStatusConfirmPassword("");

        if (validateInputs() && confirmPasswordCheck() && passwordValidationError()) {
            setLoading(1);
            api.get("/register/verify-email", {
                params: {
                    email,
                },
            })
                .then(() => {
                    api.get("/register/verify-cpf", {
                        params: {
                            document: cpf,
                        },
                    })
                        .then(() => {
                            api.get("/register/verify-cellphone", {
                                params: {
                                    cellphone: phone,
                                },
                            })
                                .then(() => {
                                    setLoading(0);

                                    handleRegisterData(true);

                                    // if (emailValidated) {
                                    //   nextStep(3);
                                    // } else {
                                    //   nextStep();
                                    // }
                                })
                                .catch((error) => {
                                    setLoading(0);
                                    if (error.response.data.errors) {
                                        setPhoneErrorMsg(
                                            error.response.data.errors.cellphone ||
                                                "Celular informado já esta sendo utilizado."
                                        );
                                    } else {
                                        setPhoneErrorMsg(
                                            error.response.data.message || "Celular informado já esta sendo utilizado."
                                        );
                                    }
                                });
                        })
                        .catch((error) => {
                            setLoading(0);
                            if (error.response.data.errors) {
                                setCpfErrorMsg(error.response.data.errors.document);
                            } else {
                                setCpfErrorMsg(error.response.data.message);
                            }
                        });
                })
                .catch((error) => {
                    setLoading(0);
                    if (error.response.data.errors.email) {
                        setEmailErrorMsg(
                            error.response.data.errors.email ||
                                "Ops! Não conseguimos verificar seu email. Tente novamente mais tarde."
                        );
                    } else {
                        setEmailErrorMsg("Ops! Algo seu errado.");
                    }
                });
        }
    };

    const history = useHistory();

    // CADASTRO --------------------------------------------------------------
    const handleRegisterData = useCallback(
        async (hasPhone?: boolean) => {
            const formData = new FormData();
            formData.append("document", cpf);
            formData.append("name", fullName);
            formData.append("email", email);
            inviteCode && formData.append("parameter", inviteCode);
            console.log(inviteCode);

            src && formData.append('src', src);
            utmSource && formData.append('utm_source', utmSource);
            utmMedium && formData.append('utm_medium', utmMedium);
            utmCampaign && formData.append('utm_campaign', utmCampaign);
            utmTerm && formData.append('utm_term', utmTerm);
            utmContent && formData.append('utm_content', utmContent);
            utmId && formData.append('utm_id', utmId);
            Fbclid && formData.append('fbclid', Fbclid);
            Gclid && formData.append('gclid', Gclid);
            urlData && formData.append('urldata', urlData);

            if (hasPhone && phone) {
                formData.append("cellphone", phone);
            }
            formData.append("password", password);
            formData.append("use_terms", useTerms.toString() === "true" ? "true" : "false");

            try {
                setLoading(1);

                const response = await api.post("/register", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                setLoading(0);
                //set
                setRegisterFinished(true);
                setRedirectLink(response.data.redirect);
                history.push("/finished");
            } catch (error) {
                setLoading(0);

                //@ts-ignore
                const errorMessage = error.response.data.message;

                toast.error(errorMessage);
                setErrorFinish(errorMessage);
                nextStep(4);
            }
        },
        [cpf, fullName, email, phone, password, phoneCode, emailCode, inviteCode]
    );

    return (
        <Container ref={toScrollRef}>
            <Loader loading={loading} />

            <div className="title-container">
                <h1 className="main-title">Criar conta Empresa grátis</h1>

                <span className="subtitle">
                    <p>Você é um comprador?</p>
                    <a href="https://ajuda.azcend.com/" className="link">
                        Ir para o atendimento
                    </a>
                </span>
            </div>

            <form autoComplete="off">
                <h2>Informe os dados pessoais</h2>

                <TextInput
                    id="fullname"
                    className="fullname"
                    name="fullname"
                    mask=""
                    type="text"
                    placeholder="Seu nome completo"
                    value={fullName}
                    error={fullNameErrorMsg}
                    status={statusName}
                    onChange={(e) => {
                        setFullName(e.currentTarget.value.replace(/(^\w|\s\w)/g, (m: any) => m.toUpperCase()));
                        setFullNameErrorMsg("");
                    }}
                    onBlur={handleCheckFullName}
                />

                <TextInput
                    id="cpf"
                    name="cpf"
                    mask="999.999.999-99"
                    type="text"
                    placeholder="Seu CPF"
                    value={cpf}
                    onChange={handleChangeCPF}
                    onBlur={verifyCPF}
                    error={cpfErrorMsg}
                    status={statusCPF}
                />

                <TextInput
                    id="email"
                    name="email"
                    mask=""
                    placeholder="Seu e-mail"
                    type="email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailErrorMsg("");
                    }}
                    onBlur={handleValidateEmail}
                    error={emailErrorMsg}
                    status={statusEmail}
                />

                <PhoneInputComponent
                    value={phone}
                    className="phone-input"
                    label="Seu celular"
                    onChange={(e) => {
                        setPhone(e);
                        setPhoneErrorMsg("");
                    }}
                    onBlur={handleValidatePhone}
                    error={phoneErrorMsg}
                />

                <h2>Crie sua senha</h2>

                <TextInput
                    id="password"
                    name="password"
                    mask=""
                    type="password"
                    placeholder="Digite sua senha"
                    value={password}
                    onChange={passwordValidationCheck}
                    onBlur={passwordValidationError}
                    error={passwordError}
                    status={statusPassword}
                />

                <TextInput
                    id="confirm-password"
                    name="confirm-password"
                    mask=""
                    type="password"
                    placeholder="Confirme sua senha"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onBlur={confirmPasswordCheck}
                    error={confirmPasswordError}
                    status={statusConfirmPassword}
                />

                <Collapse className="collapse" isOpened={passwordRulesOpened}>
                    <PasswordRequirements
                        hasEightCharacters={eightChar}
                        hasCapitalLowerLetters={capitalLowerLetters}
                        hasSpecialCharacters={specialChar}
                    />
                </Collapse>

                <InputCheckbox
                    className="terms-check"
                    checked={useTerms}
                    onChange={handleUsePrivacy}
                    error={errorUseTermsMsg}
                >
                    <p>
                        Li e concordo com os{" "}
                        <a
                            className="link"
                            href={"https://azcend.com.br/index.php/politica-de-privacidade/"}
                            target="_blank"
                            rel="noreferrer"
                        >
                            termos de uso.
                        </a>
                    </p>
                </InputCheckbox>

                {/* {process.env.REACT_APP_ENV === "production" && (
                    <Collapse className="collapse" isOpened={useTerms}>
                        <div className="recaptcha" style={{ transform: "scale(0.85)", transformOrigin: "0 0" }}></div>

                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? ""}
                            onChange={handleChangeRecaptcha}
                            onExpired={handleExpiredRecaptcha}
                        />

                        <ErrorContainer>{recaptchaErrorMsg}</ErrorContainer>
                    </Collapse>
                )} */}

                <Button
                    primary
                    onClick={(e) => {
                        handleSave(e);
                    }}
                >
                    FINALIZAR CADASTRO
                </Button>
            </form>
            <span className="has-account">
                Já possui uma conta?
                <Link to="/signin" className="link">
                    Entrar
                </Link>
            </span>

            <p className="obs-register">
                Utilizaremos os dados para realizar seu cadastro e te auxiliaremos a inserir informações para ativar a
                sua loja.
            </p>
        </Container>
    );
};

export default RegisterForm;
