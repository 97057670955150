/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiX } from "react-icons/fi";
import { Container, SideBarSticky } from "./styles";
import cloudfoxLogoIcon from "../../assets/logos/2022/azcend.png";

import cloudfoxLogo from "../../assets/logos/2022/azcend.png";
import { menuList } from "../../data/menuList";
import logoutIcon from "../../assets/logout.svg";

import Button from "../Button";
import { AccountsContext as Context } from "../../context/AccountsContext";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { logout } from "../../services/auth.js";
import api from "../../services/api";

const Sidebar: React.FC = () => {
    const [isActive, setIsActive] = useState<number | null | undefined>(null);
    const { user, mobileMenuToggle, setMobileMenuToggle } = useContext(Context);
    const location = useLocation();

    const menuActive = useCallback((id) => {
        setIsActive(id);
    }, []);

    const menuClick = useCallback((id, mobileMenuToggle) => {
        setMobileMenuToggle(mobileMenuToggle);
        menuActive(id);
    }, []);

    useEffect(() => {
        const path = location.pathname.replace(/(.*?\/.*?)\/.*/g, "$1");
        const number = menuList.find((x) => x.to === path);
        setIsActive(number?.id);
    }, [isActive, location]);

    const history = useHistory();

    const logoutHandle = useCallback(async () => {
        try {
            await api.get("/logout");
            logout();
            history.push("/");
        } catch (error) {}
    }, [history]);

    return (
        <Container open={mobileMenuToggle}>
            <nav className="sidebar">
                <Link className="navbar-brand desktop-brand " to="/home">
                    <img src={cloudfoxLogo} alt="" width={300} />
                </Link>

                <div className="mobile-sidebar-head">
                    <div className="logo-button-group">
                        <img src={cloudfoxLogoIcon} alt="" className="logo" />
                    </div>

                    <Button transparent className="close-menu" onClick={() => setMobileMenuToggle(!mobileMenuToggle)}>
                        <FiX size={24} />
                    </Button>
                </div>

                <SideBarSticky className="sidebar-sticky">
                    <ul className="nav flex-column d-block">
                        {menuList.map(
                            (menu) =>
                                (menu.role == "all" || (user?.is_account_owner && menu.role == "account-owner")) && (
                                    <div
                                        role="menuitem"
                                        aria-hidden="true"
                                        // tabIndex={0}
                                        key={menu.id}
                                        className={`nav-item ${isActive === menu.id ? "active" : ""}`}
                                        onClick={() => menuClick(menu.id, !mobileMenuToggle)}
                                    >
                                        <Link to={menu.to} aria-expanded="false" className="nav-link">
                                            <img src={menu.icon} alt="" />
                                            {menu.name}
                                            {/* <span className="sr-only">(atual)</span> */}
                                        </Link>
                                    </div>
                                )
                        )}
                    </ul>

                    <ul className="nav nav-logout flex-column d-block">
                        <div
                            role="menuitem"
                            aria-hidden="true"
                            // tabIndex={0}
                            key={99}
                            className={`nav-item "active"`}
                            onClick={() => menuActive(99)}
                            style={{ alignSelf: "flex-end" }}
                        >
                            <Link to="" onClick={logoutHandle} aria-expanded="false" className="nav-link">
                                <img src={logoutIcon} alt="" />
                                Logout
                            </Link>
                        </div>
                    </ul>
                </SideBarSticky>
            </nav>
        </Container>
    );
};

export default Sidebar;
