import styled from "styled-components";
import * as color from "../../styles/colors";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;

    margin-top: 30px;

    min-width: 250px;
    max-width: 360px;

    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #1b1b1b;
        margin-bottom: 24px;
    }

    p {
        margin-bottom: 20px;
    }

    .btn-signin {
        margin: 30px 0 33px;
    }

    .btn-forgot-password {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #00b7ff;
        text-decoration: none;
        :hover {
            opacity: 0.7;
        }
    }

    .signup-link {
        text-decoration: none;
        color: ${color.orange};
        margin-top: 18px;
    }

    .has-account {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 30px;
        text-align: center;

        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #191919;

        .link {
            font-weight: 600;
            color: ${color.orange};
            margin-left: 3px;
            cursor: pointer;
            text-decoration: none;
        }
    }

    @media (min-width: 576px) {
        width: 80%;
        padding: 10px;
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: center;
`;
