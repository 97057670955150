import styled, { keyframes } from "styled-components";
import * as color from '../../styles/colors';

interface FadeProps {
  show?: boolean;
  seconds?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 30px !important;

  h2 {
    text-align: center;
  }

  .title-document {
    margin-top: 20px;
  }

  .title-upload {
    margin-bottom: 10px;
  }

  .reason {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    color: ${color.orange};
    padding: 3px;

    border-radius: 5px;
    border: 1px solid ${color.orange};
  }

  .upload-div {
    margin-bottom: 20px;
  }

  .botton {
    bottom: 0;
    height: 40px;
    background: linear-gradient(180deg, transparent 0%, rgba(255,255,255,1) 100%);
  }


  .no-pending-documents {
    text-align: center;

    
    .illustration {
        max-width: 200px;
      }

    .title {
      margin: 10px 0 10px 0;
      font-weight: bold;
      color: ${color.orange};
    }

    .text-wait {

    }

    .social-media-icon {
      transition: all 0.3s ease;
    }

    .social-media-icon:hover {
      opacity: 0.8;
    }

    .social-media-container {
      margin-top: 70px;

      .social-media-imgs {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;

        img {
          margin: 7px;
        }
      }
    }

    @media (max-width: 768px) {
      .social-media-container {
        margin-top: 40px;
      }

    }

  }
`;

export const TextTitle = styled.div`
  text-align: center;
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 17px;
  }
`;

const fadeIn = keyframes`
  0% {
    display: block;
    opacity: 0;
  }
  99% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
    display: none;
  }
`;

export const FadeContainer = styled.div<FadeProps>`

  ${({ show }) => show === true ? (
    'display: block;'
  ) : (
    'display: none;'
  ) }

  animation: ${({seconds}) => ( seconds? seconds : 0.5)}s ${({ show }) => (show === true ? fadeIn : fadeOut )};
  animation-fill-mode: forwards; 
`;

export const ContainerFinish = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-items: space-around;
  text-align: center;

  color: ${color.grey};

  .logo {
    flex: 1;
    max-width: 200px;
    margin: 20px 0 20px 0;
  }

  .illustration-container {
    height: 400px;
    margin-bottom: 20px;
  }

  .illustration {
    flex: 2;
    max-height: 400px;
  }

  .text {
    flex: 1;
    line-height: 1.4;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    letter-spacing: 0.015em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-weight: bold;
      font-size: 30px;
      color: ${color.orange};
      margin-bottom: 10px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      color: ${color.lightgrey};
      max-width: 500px;
    }
  }
  

  .social-media-container {
    margin-top: 20px;
    flex: 1;

    >p {
      margin-bottom: 5px;
    }

    .social-media-imgs {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;

      img {
        margin: 7px;
      }
    }
  }

  @media (max-width: 768px) {
    .logo {
      max-width: 150px;
      margin: 10px 0 10px 0;
    }

    .illustration-container {
      height: 200px;
      margin-bottom: 15px;
    }

    .illustration {
      flex: 2;
      max-height: 200px;
    }

    .text {
      h1 {font-size: 18px;}

      p {font-size: 11px;}
    }

    .social-media-container {
      margin-top: 15px;
      .social-media-imgs {
        height: 30px;

        img {
          margin: 4px;
        }
      }
    }

  }

  .social-media-icon {
    transition: all 0.3s ease;
  }

  .social-media-icon:hover {
    opacity: 0.8;
  }

`;

