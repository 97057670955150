import React, { useCallback, useState } from 'react';
import { FiXCircle, FiCheckCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Container } from './styles';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import api from '../../../../services/api';

interface BankAccountDeleteProps {
    closeModal?: any;
    bankAccount?: any;    
}

const ModalBankAccountDelete: React.FC<BankAccountDeleteProps> = ({
    closeModal,
    bankAccount
}) => {
    const [loading, setLoading] = useState(0);

    const handleConfirmDelete = useCallback(async () => {
        try {
            setLoading(1);

            await api.delete(`/bank-account/${bankAccount.id}`)
            .then((response)=>{
                setLoading(0);
                toast.success('Registro deletado com sucesso!');
            })
            .catch((error) => {    
                setLoading(0);
                toast.error('Ops! Para continuar você precisa ter outra conta aprovada.');
            });

            closeModal('delete');
            
        } catch (error) {
            setLoading(0);
        }
    }, [closeModal, bankAccount.id]);

    return (
        <Container>
            <Loader loading={loading} />
                <FiXCircle size={50} color="#ff4c52" />
            
            <p className="question">Você tem certeza?</p>
            <p className="phrase">
                {bankAccount.transfer_type=='PIX' ? `Deseja deletar sua chave pix?` : `Deseja deletar sua conta de recebimento?`}
            </p>
            <div className="buttons-container">
                <Button className="cancel-button" onClick={() => closeModal('delete')}>
                    Cancelar
                </Button>
                <Button className="delete-button" onClick={handleConfirmDelete}>
                    Confirmar
                </Button>
            </div>
        </Container>
    );
};

export default ModalBankAccountDelete;
