import Pusher from 'pusher-js';
let options = {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    forceTLS: true,
    encrypted: true
};

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY??'', options);

export default pusher;
