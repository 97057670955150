import styled from 'styled-components';
import * as color from '../../styles/colors';

export const ContainerFluid = styled.div`
  overflow: hidden;
  background-color: #f6f7fa;
  padding: 0;
`;

export const Container = styled.main`
  position: relative;
  width: 100%;
  z-index: 101;
  min-height: 100vh;
  margin-left: auto;
  padding: 75px 15px 15px;
  font-family: 'Inter', sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color.darkgrey};
  }

  p,
  li {
    color: ${color.darkgrey};
    font-size: 14px;
  }

  button {
    font-family: 'Inter', sans-serif;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  @media screen and (min-width: 992px) {
    padding: 160px 20px 20px;
    width: calc(100% - 270px);
  }

  @media screen and (min-width: 768px) {
    /* padding-top: 88px; */

    ::before {
      height: 290px;
    }
  }

  @media (max-width: 576px) {
    padding: 75px 15px 15px;
  }

  @media (min-width: 176px) {
    .col-xs-12 {
      width: 100%;
    }

    .col-xs-11 {
      width: 91.65%;
    }

    .col-xs-10 {
      width: 83.33%;
    }

    .col-xs-9 {
      width: 75%;
    }

    .col-xs-8 {
      width: 66.66%;
    }

    .col-xs-7 {
      width: 58.33%;
    }

    .col-xs-6 {
      width: 50%;
    }

    .col-xs-5 {
      width: 41.67%;
    }

    .col-xs-4 {
      width: 33.33%;
    }

    .col-xs-3 {
      width: 25%;
    }

    .col-xs-2 {
      width: 16.66%;
    }

    .col-xs-1 {
      width: 8.33%;
    }
  }

  @media (min-width: 576px) {
    .col-sm-12 {
      width: 100%;
    }

    .col-sm-11 {
      width: 91.65%;
    }

    .col-sm-10 {
      width: 83.33%;
    }

    .col-sm-9 {
      width: 75%;
    }

    .col-sm-8 {
      width: 66.66%;
    }

    .col-sm-7 {
      width: 58.33%;
    }

    .col-sm-6 {
      width: 50%;
    }

    .col-sm-5 {
      width: 41.67%;
    }

    .col-sm-4 {
      width: 33.33%;
    }

    .col-sm-3 {
      width: 25%;
    }

    .col-sm-2 {
      width: 16.66%;
    }

    .col-sm-1 {
      width: 8.33%;
    }
  }

  @media (min-width: 768px) {
    .col-md-12 {
      width: 100%;
    }

    .col-md-11 {
      width: 91.65%;
    }

    .col-md-10 {
      width: 83.33%;
    }

    .col-md-9 {
      width: 75%;
    }

    .col-md-8 {
      width: 66.66%;
    }

    .col-md-7 {
      width: 58.33%;
    }

    .col-md-6 {
      width: 50%;
    }

    .col-md-5 {
      width: 41.67%;
    }

    .col-md-4 {
      width: 33.33%;
    }

    .col-md-3 {
      width: 25%;
    }

    .col-md-2 {
      width: 16.66%;
    }

    .col-md-1 {
      width: 8.33%;
    }
  }

  @media (min-width: 992px) {
    .col-lg-12 {
      width: 100%;
    }

    .col-lg-11 {
      width: 91.65%;
    }

    .col-lg-10 {
      width: 83.33%;
    }

    .col-lg-9 {
      width: 75%;
    }

    .col-lg-8 {
      width: 66.66%;
    }

    .col-lg-7 {
      width: 58.33%;
    }

    .col-lg-6 {
      width: 50%;
    }

    .col-lg-5 {
      width: 41.67%;
    }

    .col-lg-4 {
      width: 33.33%;
    }

    .col-lg-3 {
      width: 25%;
    }

    .col-lg-2 {
      width: 16.66%;
    }

    .col-lg-1 {
      width: 8.33%;
    }
  }

  @media (min-width: 1200px) {
    .col-xl-12 {
      width: 100%;
    }

    .col-xl-11 {
      width: 91.65%;
    }

    .col-xl-10 {
      width: 83.33%;
    }

    .col-xl-9 {
      width: 75%;
    }

    .col-xl-8 {
      width: 66.66%;
    }

    .col-xl-7 {
      width: 58.33%;
    }

    .col-xl-6 {
      width: 50%;
    }

    .col-xl-5 {
      width: 41.67%;
    }

    .col-xl-4 {
      width: 33.33%;
    }

    .col-xl-3 {
      width: 25%;
    }

    .col-xl-2 {
      width: 16.66%;
    }

    .col-xl-1 {
      width: 8.33%;
    }
  }
`;
