import React, { useState, useEffect, useContext } from "react";
import { Container, Title, Subtile, TransferContent, TransferOption, TitleSecondary } from "./styles";

import { ViewBankAccountContext } from "../../../../context/CreateBankAccountContext";

const CardTransferType: React.FC<any> = ({ company }) => {
    const { setViewCard } = useContext(ViewBankAccountContext);

    const [loading, setLoading] = useState<number>(0);

    const handleClick = (option: string) => {
        setViewCard(option);
    };

    return (
        <Container cardTitle="Recebimentos" loading={loading}>
            <Subtile>
                {" "}
                Os saques serão liquidados na chave pix: {company?.document}, caso não der certo serão liquidados via
                TED.
            </Subtile>
            <TransferContent>
                <TransferOption onClick={() => handleClick("PIX")} value="PIX">
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M49.8487 48.9013C48.6177 48.904 47.3985 48.6611 46.2614 48.1869C45.1243 47.7126 44.0918 47.0163 43.2236 46.1382L33.654 36.5081C33.3154 36.182 32.8647 36 32.396 36C31.9273 36 31.4766 36.182 31.138 36.5081L21.5286 46.1782C20.6602 47.0559 19.6277 47.7519 18.4906 48.2259C17.3535 48.6999 16.1344 48.9425 14.9036 48.9399H13.0014L25.1268 61.142C26.9454 62.9719 29.4119 64 31.9838 64C34.5556 64 37.0221 62.9719 38.8407 61.142L51.0014 48.9043L49.8487 48.9013Z"
                            fill="#56607E"
                        />
                        <path
                            d="M14.9036 15.0472C16.1345 15.0446 17.3537 15.2873 18.4908 15.7614C19.628 16.2354 20.6605 16.9314 21.5287 17.8091L31.138 27.4762C31.4717 27.8116 31.9242 28 32.396 28C32.8678 28 33.3203 27.8116 33.654 27.4762L43.2236 17.8505C44.0918 16.9729 45.1243 16.277 46.2614 15.8029C47.3986 15.3289 48.6178 15.0862 49.8487 15.0887H51.0014L38.8407 2.85671C37.0221 1.02758 34.5556 0 31.9838 0C29.4119 0 26.9454 1.02758 25.1268 2.85671L13.0014 15.0532L14.9036 15.0472Z"
                            fill="#56607E"
                        />
                        <path
                            d="M61.1509 25.1203L53.7818 17.7512C53.616 17.8197 53.4386 17.8558 53.2592 17.8575H49.9098C48.1661 17.862 46.4946 18.5543 45.2583 19.7839L35.6631 29.3791C35.2356 29.8072 34.7277 30.1466 34.1686 30.3779C33.6095 30.6092 33.0103 30.7278 32.4052 30.7268C31.8002 30.7276 31.201 30.609 30.6419 30.3777C30.0828 30.1464 29.5749 29.8071 29.1473 29.3791L19.5122 19.744C18.2759 18.5144 16.6045 17.8222 14.8608 17.8176H10.7408C10.5712 17.8144 10.4036 17.7804 10.2463 17.7172L2.8491 25.1144C1.02576 26.9379 0.00143433 29.411 0.00143433 31.9897C0.00143433 34.5684 1.02576 37.0415 2.8491 38.865L10.2463 46.2622C10.4036 46.199 10.5712 46.165 10.7408 46.1618H14.8608C16.5993 46.1561 18.2658 45.4668 19.5004 44.2427L29.1355 34.6077C30.0139 33.7707 31.1808 33.3037 32.3941 33.3037C33.6075 33.3037 34.7744 33.7707 35.6528 34.6077L45.248 44.2029C46.486 45.4368 48.1619 46.1309 49.9098 46.1337H53.2592C53.439 46.1348 53.6169 46.1704 53.7833 46.2385L61.1509 38.8709C62.9742 37.0474 63.9986 34.5743 63.9986 31.9956C63.9986 29.4169 62.9742 26.9438 61.1509 25.1203Z"
                            fill="#56607E"
                        />
                    </svg>
                    <Title style={{ marginTop: 16, marginBottom: 16 }}>Quero receber via PIX</Title>
                    <TitleSecondary>
                        Saques são remetidos em qualquer dia e em qualquer hora. 24/7 de verdade :)
                    </TitleSecondary>
                </TransferOption>
                <TransferOption onClick={() => handleClick("TED")} value="TED">
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.61578 2.02254L59.0532 27.3685C59.883 27.7774 60.2243 28.7816 59.8154 29.6114C59.6522 29.9425 59.3843 30.2104 59.0532 30.3735L56.8547 31.4569C54.0205 29.6784 50.6679 28.65 47.075 28.65C37.4932 28.65 29.6197 35.9642 28.7331 45.3139L7.61684 55.719C6.78704 56.1279 5.78288 55.7867 5.37399 54.9569C5.19519 54.594 5.15374 54.1787 5.2573 53.7877L10.3535 34.543C10.522 33.9066 11.0478 33.4279 11.6972 33.3196L34.7484 29.4761C35.0308 29.4291 35.2643 29.2426 35.3765 28.9892L35.4368 28.7877C35.502 28.3967 35.2848 28.0238 34.9342 27.8773L34.7484 27.8239L11.5671 23.9604C10.9174 23.8521 10.3915 23.3731 10.2231 22.7364L5.25614 3.95346C5.01953 3.05916 5.55269 2.14237 6.447 1.90576C6.83792 1.80233 7.25306 1.84381 7.61578 2.02254ZM62.15 47.075C62.15 55.4007 55.4007 62.15 47.075 62.15C38.7493 62.15 32 55.4007 32 47.075C32 38.7493 38.7493 32 47.075 32C55.4007 32 62.15 38.7493 62.15 47.075ZM47.075 38.7C46.1499 38.7 45.4 39.45 45.4 40.375V47.075C45.4 48.0001 46.1499 48.75 47.075 48.75H52.1C53.0251 48.75 53.775 48.0001 53.775 47.075C53.775 46.15 53.0251 45.4 52.1 45.4H48.75V40.375C48.75 39.45 48.0001 38.7 47.075 38.7Z"
                            fill="#56607E"
                        />
                    </svg>
                    <Title style={{ marginTop: 16, marginBottom: 16 }}>Quero receber via TED</Title>
                    <TitleSecondary>
                        O método tradicional. Saques só serão enviados de Seg. a Sex. das 09h as 17h.
                    </TitleSecondary>
                </TransferOption>
            </TransferContent>
        </Container>
    );
};

export default CardTransferType;
