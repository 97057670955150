import styled, { css } from "styled-components";
import * as color from "../../../styles/colors";
interface PasswordProps {
    goodPassword?: boolean;
}

export const Container = styled.div`
    width: 100%;
    max-width: 400px;

    h1,
    h2 {
        color: #1b1b1b;
        line-height: 28px;
    }

    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 16px;
    }

    h1 {
        font-weight: 700;
        font-size: 26px;
    }

    a {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #00b7ff;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        color: #1b1b1b;
    }

    .obs-register {
        text-align: center;
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #8e8e8e;
    }

    .terms-check p {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

        .link {
            font-weight: 600;
            color: ${color.orange};
            cursor: pointer;
        }
    }

    .ReactCollapse--collapse {
        transition: height 500ms;
    }

    .phone-input {
        margin-bottom: 24px;
    }

    .has-account {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 30px;
        text-align: center;

        margin: 24px 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #1b1b1b;

        .link {
            font-weight: 600;
            color: ${color.orange};
            margin-left: 3px;
            cursor: pointer;
            text-decoration: none;
        }
    }

    .title-container {
        margin-bottom: 42px;
    }

    .main-title {
        margin: 70px 0 12px 0;
    }

    .subtitle {
        display: flex;

        p {
            margin-right: 10px;
        }
    }

    @media (max-width: 890px) {
        padding: 16px;
        border-radius: 4px;

        .main-title {
            margin: 0 0 12px 0;
        }

        .subtitle {
            flex-direction: column;
            font-size: 14px;
        }
    }
`;

export const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 0 10px 0;
    height: 10px;
    font-size: 11px;
    line-height: 13px;
    color: ${color.red};
    transition: all 1s;

    img {
        height: 10px;
        margin-right: 3px;
    }
`;

export const TermsTextBox = styled.div`
    height: 275px;
    font-size: 12px;
    font-family: "Inter", sans-serif;
    line-height: 14px;
    margin: 20px 0 34px;
    padding-right: 10px;
    overflow: auto;

    p {
        margin-bottom: 10px;
    }

    li {
        margin-bottom: 6px;
    }

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${color.orange};
        border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #e5e5e5;
        background-color: #f5f5f5;
        border-radius: 4px;
    }
`;
