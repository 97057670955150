import styled from 'styled-components';
import ContentBox from '../../../../components/ContentBox';
import * as color from '../../../../styles/colors';

export const UserBox = styled(ContentBox)`
    background:#ffffff;
    display: flex;
    padding: 20px 30px;
    margin-bottom: 20px;
    img {
        margin-right: 28px;
        width: 40px;
        height: 40px;
    }

    .dropdown-container {
        top: 10px;
    }
`;

export const Content = styled.div`
  display: block;
  width: 100%;

  .header {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .company-status {
    margin: 15px 0 0;

    .status-date {
      display: flex;
      align-items: center;
    }
  }

  .service-status {
    margin-top: 15px;
  }

  .company-name {
    padding-right: 10px;

    &:hover {
      color: ${color.orange};
    }
  }

  .company-name-disabled {
    padding-right: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;

  > p {
    margin-right: 8px;
  }
`;