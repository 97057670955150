import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";
import { toast } from "react-toastify";
import CodeInput from "react-verification-code-input";
import { Container, ErrorMessageContainer, GobackButton } from "./styles";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { RegisterContext as Context } from "../../../context/RegisterContext";
import api from "../../../services/api";
import { Button } from "../../../components/Button/styles";
import Loader from "../../../components/Loader";

const EmailTokenView: React.FC = () => {
  const {
    previousStep,
    nextStep,
    email,
    fullName,
    setEmailValidated,
    emailCode,
    setEmailCode,
  } = useContext(Context) || {};

  const [errorMsg, setErrorMsg] = useState<string | null>("");
  const [loading, setLoading] = useState<number | undefined>(0);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    sendEmailCode();
  }, [email]);

  const sendEmailCode = useCallback(async () => {
    setLoading(1);
    try {
      await api.get("/register/send-email-code", {
        params: {
          email,
          firstname: fullName,
        },
      });
      setLoading(0);
      toast.success("Seu código foi enviado com sucesso!");
    } catch (error) {
      setLoading(0);
      toast.error("Algo não saiu como esperado. Tente novamente.");
    }
  }, [email, fullName]);

  const tokenValidate = () => {
    if (!emailCode) {
      setErrorMsg(
        "Por favor, insira o código de verificação enviado por e-mail."
      );
      return;
    }

    setLoading(1);
    api
      .post("/register/match-email-verify-code", {
        email,
        code: emailCode,
      })
      .then((ess) => {
        setLoading(0);
        setEmailValidated(true);
        nextStep();
      })
      .catch((error) => {
        const clearValues = inputRef?.current?.__clearvalues__ || (() => null);
        clearValues();
        setLoading(0);
        if (error.response) {
          setErrorMsg(error.response.data.message);
        }
      });
  };

  return (
    <Container>
      <Loader loading={loading} />
      <GobackButton onClick={() => previousStep()}>
        <AiOutlineArrowLeft className="goback-icon" /> Voltar
      </GobackButton>
      <h1>Confirme seu e-mail</h1>
      <p>
        <b>Enviamos um código para:</b>
      </p>
      <p className="email">{email || "teste@teste.com"}</p>
      <CodeInput
        fields={4}
        className="verification-code-input"
        fieldWidth={68}
        fieldHeight={91}
        onChange={(e) => {
          setEmailCode(e);
          setErrorMsg("");
        }}
        ref={inputRef}
      />
      {errorMsg && (
        <ErrorMessageContainer>
          <h3 className="title">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM5.80943 5.11372L5.87868 5.17157L8 7.29289L10.1213 5.17157C10.2949 4.99801 10.5643 4.97872 10.7592 5.11372L10.8284 5.17157C11.002 5.34514 11.0213 5.61456 10.8863 5.80943L10.8284 5.87868L8.70711 8L10.8284 10.1213C11.002 10.2949 11.0213 10.5643 10.8863 10.7592L10.8284 10.8284C10.6549 11.002 10.3854 11.0213 10.1906 10.8863L10.1213 10.8284L8 8.70711L5.87868 10.8284C5.70511 11.002 5.43569 11.0213 5.24082 10.8863L5.17157 10.8284C4.99801 10.6549 4.97872 10.3854 5.11372 10.1906L5.17157 10.1213L7.29289 8L5.17157 5.87868C4.99801 5.70511 4.97872 5.43569 5.11372 5.24082L5.17157 5.17157C5.34514 4.99801 5.61456 4.97872 5.80943 5.11372Z"
                fill="#D10000"
              />
            </svg>
            Código inválido
          </h3>
          <p className="description">{errorMsg}</p>
        </ErrorMessageContainer>
      )}

      <Button primary onClick={tokenValidate}>
        VALIDAR CÓDIGO
      </Button>

      <span className="has-account">
        Não recebeu o código?
        <a onClick={() => sendEmailCode()} className="link">
          Reenviar
        </a>
      </span>

      <p className="obs-register">
        Utilizaremos os dados para realizar seu cadastro e te auxiliaremos a
        inserir informações para ativar a sua loja.
      </p>
    </Container>
  );
};

export default EmailTokenView;
